import React, { useState, useEffect, useCallback } from 'react';
import { Middleware } from 'one-ring';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';

import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import APIEndPoints from '../../../services/api';

import { setChemicalDetails } from '../../../actions/chemicalMaintenanceActions';
import { DrcButton, DrcSelect, DrcDialog, DrcPageWarning } from 'driscolls-react-components';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import IconButton from '@material-ui/core/IconButton';
import chemicalMaintenanceUtilities from '../../../data/chemicalMaintenanceUtilities';

const CHEMICAL_TYPE = 'Chemical Type';
const PAGE_TYPE = 'chemicalTypes';

const styles = (theme) => ({
    row: {
        marginTop: '20px'
    },
    Icon: {
        position: 'absolute',
        marginLeft: '10px'
    },
    li: {
        display: 'inline-block'
    }
});

const ChemicalType = (props) => {
    const { classes } = props;
    const [onEdit, setOnEdit] = useState(false);
    const [multiValue, setMultiValue] = useState([]);
    const [defaultValue, setDefaultValue] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState(false);

    const getData = useCallback(() => {
        props.auth.getAccessToken().then((token) => {
            //check if the user is admin
            if (props.isAdmin && (props.chemicalTypes || []).length) {
                setDefaultValue(props.chemicalTypes);
                setIsDisabled(true);
            }
        });
    }, [props.auth, props.chemicalTypes, props.isAdmin]);

    useEffect(() => {
        getData();
    }, [getData]);

    const setDefaultValues = (chemicalTypeData) => {
        //set default values to the multi select drop down
        let data = props.chemicalTypes.length ? props.chemicalTypes : chemicalTypeData;
        let defaultValues = ((data || []).filter((item) => item.IsActive) || []).map((item) => {
            return { label: item.ChemicalType, value: item.ChemicalTypeId };
        });
        setDefaultValue(defaultValues);
    };

    const openEditDialog = () => {
        setOnEdit(true);
        setIsDisabled(true);
        setDefaultValues(multiValue);
    };

    const closeEditDialog = () => {
        setOnEdit(false);
    };

    const refreshGrid = () => {
        props.auth.getAccessToken().then((token) => {
            chemicalMaintenanceUtilities.setChemicalDetails(token, APIEndPoints.GET_CHEMICAL_DETAILS(props.chemical.value), props.setChemicalDetails);
        });
    };

    const showErrorMessage = (message) => {
        setErrorMessage(message);
        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const getModifiedTypes = (multiValue, defaultValue) => {
        //take out common values because they are unchanged
        let allValues = [...defaultValue, ...multiValue] || [];
        let uniqueValues = [...new Set(allValues.map((item) => allValues.find((e) => e.value === item.value)))] || [];

        // get chemical types which got deleted
        let removedTypes = (uniqueValues || []).filter((e) => !(multiValue || []).find((a) => e.value === a.value));
        // get chemical types which got added
        let addedTypes = (uniqueValues || []).filter((e) => !(defaultValue || []).find((a) => e.value === a.value));

        return (removedTypes || [])
            .map((item) => {
                return { ChemicalTypeId: item.value, ChemicalType: item.label, IsActive: false };
            })
            .concat(
                (addedTypes || []).map((item) => {
                    return { ChemicalTypeId: item.value, ChemicalType: item.label, IsActive: true };
                })
            );
    };

    const onSave = () => {
        let values = getModifiedTypes(multiValue, defaultValue);

        let payload = {};
        props.auth.getAccessToken().then((token) => {
            payload.ChemicalId = props.chemical.value;
            payload.ChemicalTypes = values || [];
            payload.IsActive = true;
            payload.TransactionInfo = {
                Username: DuAuthenticationUtilities.GetUserId(token) || 'Bad Token',
                LastChangedDateTime: new Date().toISOString()
            };
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.EDIT_CHEMICAL_TYPE, 'POST', payload, {
                overrideRequestMapping: false,
                overrideResponseMapping: true
            })
                .then((data) => {
                    if (data && data.status) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        showErrorMessage('Edited Chemical Type Successfully!');
                        setOnError(false);
                        refreshGrid();
                    }
                    setOnEdit(false);
                })
                .catch((err) => {
                    console.log(err);
                    closeEditDialog();
                    showErrorMessage('Failed to edit Chemical Type');
                    setOnError(true);
                });
        });
    };

    const handleChange = (event) => {
        setMultiValue(event ? event : '');
        //disable SAVE button if there is no change in selection of chemical types
        let tempEvent = event,
            tempDefaultValue = defaultValue;
        // if both are not empty
        if (event && defaultValue) {
            if (event.length < defaultValue.length) {
                tempEvent = defaultValue;
                tempDefaultValue = event;
            }
            let disable = tempEvent.map((item) => {
                return tempDefaultValue.findIndex((e) => e.value === item.value) < 0 ? false : true;
            });
            disable.includes(false) ? setIsDisabled(false) : setIsDisabled(true);
        }
        // if both values are empty i.e there is no change
        else if (!event && !defaultValue) setIsDisabled(true);
        // if either of them are empty then set isDisabled to false
        else setIsDisabled(false);
    };

    return (
        <div>
            {((props.chemicalTypes || []).filter((type) => type.IsActive) || []).length ? (
                <div>
                    <h3>
                        {(props.chemicalTypes || [])
                            .filter((type) => type.IsActive)
                            .map((item, i, arr) => {
                                return arr.length - 1 === i ? (
                                    <li className={classes.li} key={item.ChemicalTypeId}>
                                        {item.ChemicalType}
                                    </li>
                                ) : (
                                    <li className={classes.li} key={item.ChemicalTypeId}>
                                        {item.ChemicalType}&#9474;
                                    </li>
                                );
                            })}
                        {props.isAdmin && (props.chemicalTypes || []).filter((type) => type.IsActive).length > 0 ? (
                            <IconButton color="primary" component="span" onClick={() => openEditDialog()}>
                                <EditRoundedIcon style={{ fontSize: '20px' }} className={classes.Icon} />
                            </IconButton>
                        ) : null}
                    </h3>
                </div>
            ) : (
                <div>
                    {props.isAdmin && !((props.chemicalTypes || []).filter((type) => type.IsActive).length > 0) ? (
                        <IconButton color="primary" component="span" onClick={() => openEditDialog()}>
                            <AddCircleOutlineIcon style={{ fontSize: '30px' }}></AddCircleOutlineIcon>
                            <h2>Add Chemical Type</h2>
                        </IconButton>
                    ) : null}
                </div>
            )}
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}
            {onEdit ? (
                <DrcDialog
                    open={onEdit}
                    title={'Edit Chemical Type'}
                    buttons={
                        <div className="row">
                            <DrcButton isPrimary floatRight disabled={isDisabled} onClick={() => onSave()}>
                                Save
                            </DrcButton>
                            <DrcButton isSecondary floatRight onClick={() => closeEditDialog()}>
                                Cancel
                            </DrcButton>
                        </div>
                    }
                >
                    <DrcSelect
                        className="basic-multi-select"
                        label={CHEMICAL_TYPE}
                        options={props.chemicalTypeOptions}
                        placeholder={'Please select'}
                        onChange={(option) => {
                            handleChange(option);
                        }}
                        multiValue={multiValue}
                        isMulti
                        defaultValue={defaultValue}
                        maxMenuHeight={240}
                    />
                </DrcDialog>
            ) : null}
            {
                //     onError ? (
                //     <DrcDialog
                //         open={onError}
                //         title={errorMessageDialog}
                //         buttons={
                //             <div className="row">
                //                 <DrcButton isPrimary onClick={() => closeErrorDialog()}>
                //                     OK
                //                 </DrcButton>
                //             </div>
                //         }
                //     ></DrcDialog>
                // ) : null
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        chemicalCommodity: state.chemicalMaintenance.chemicalCommodity,
        chemicalTypes: state.chemicalMaintenance.chemicalTypes,
        chemicalTypeOptions: state.masterReducer.chemicalTypeOptions,
        holdPeriod: state.chemicalMaintenance.holdPeriod,
        chemicalMaintenance: state.chemicalMaintenance,
        chemical: state.chemicalMaintenance.chemical,
        isAdmin: state.masterReducer.isAdmin
    };
};

const mapDispatchToProps = (dispatch) => ({
    setChemicalDetails: (data) => dispatch(setChemicalDetails(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(ChemicalType)));
