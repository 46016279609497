import { Middleware } from 'one-ring';
import { DuDateUtilities } from 'driscolls-react-utilities';

const activeIngredientDetailsMapping = [
    Middleware.CreateMapping('activeIngredientId', 'ActiveIngredientId'),
    Middleware.CreateMapping('activeIngredientName', 'ActiveIngredientName'),
    Middleware.CreateMapping('driscollsName', 'DriscollsName'),
    Middleware.CreateMapping('PPISCode', 'PPISCode'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('chemicalId', 'ChemicalId'),
    Middleware.CreateMapping('chemicalName', 'ChemicalName'),
    Middleware.CreateMapping('createdBy', 'CreatedBy'),
    Middleware.CreateMapping('createdDateTime', 'CreatedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('lastChangedBy', 'ModifiedBy'),
    Middleware.CreateMapping('lastChangedDateTime', 'ModifiedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('activeIngredientDetails', 'GET')) {
        Middleware.RegisterMapping('activeIngredientDetails', 'GET', activeIngredientDetailsMapping);
        Middleware.RegisterMapping('activeIngredientDetails', 'POST', activeIngredientDetailsMapping);
    }
}

const activeIngredientDetailsGroup = {
    ConfigureMiddleWare
};

export default activeIngredientDetailsGroup;
