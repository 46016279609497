import { Middleware } from 'one-ring';
import { DuDateUtilities } from 'driscolls-react-utilities';

const chemicalTypesMapping = [
    Middleware.CreateMapping('ChemicalTypes', 'ChemicalTypes'),
    Middleware.CreateMapping('ChemicalId', 'ChemicalId'),
    Middleware.CreateMapping('TransactionInfo', 'TransactionInfo'),
    Middleware.CreateMapping('Username', 'Username'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('LastChangedDateTime', 'LastChangedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('ChemicalTypeId', 'ChemicalTypeId'),
    Middleware.CreateMapping('ChemicalType', 'ChemicalType'),
    Middleware.CreateMapping('IsActive', 'IsActive'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('chemicalTypes', 'GET')) {
        Middleware.RegisterMapping('chemicalTypes', 'GET', chemicalTypesMapping);
        Middleware.RegisterMapping('chemicalTypes', 'POST', chemicalTypesMapping);
    }
}

const chemicalTypesGroup = {
    ConfigureMiddleWare
};

export default chemicalTypesGroup;
