import {
    CHEMICAL_LIST,
    CHEMICAL_DETAILS,
    CLEAR_CHEMICAL_DETAILS,
    ACTIVE_INGREDIENT_LIST,
    EDIT_ACTIVE_INGREDIENT,
    CHEMICAL
} from '../constants/constant';
import DrcSortUtilities from './../helpers/DrcSortUtilities';

const initialState = {
    chemicalList: [],
    chemicalTypes: [],
    chemicalCommodity: [],
    chemicalHolds: [],
    activeIngredients: [],
    activeIngredientData: [],
    chemical: [],
    chemicalNameMapping: {},
    inactivityHistory: []
};

const chemicalMaintenanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHEMICAL_LIST:
            var chemicalNameMapping = {};
            var list = action.payload.map((chemical) => {
                chemicalNameMapping[chemical.chemicalId] = chemical.chemicalName;
                return {
                    label: chemical.chemicalName,
                    value: chemical.chemicalId,
                    preHarvestIndex: chemical.preHarvestIndex,
                    isActive: chemical.isActive
                };
            });
            return {
                ...state,
                chemicalList: DrcSortUtilities.SortArrayInAscendingOrder(list, 'label'),
                chemicalNameMapping
            };
        case CHEMICAL:
            return { ...state, chemical: action.payload };
        case CLEAR_CHEMICAL_DETAILS:
            return Object.assign({}, state, {
                chemicalTypes: [],
                chemicalCommodity: [],
                chemicalHolds: [],
                inactivityHistory: []
            });
        case CHEMICAL_DETAILS:
            if (action.payload === null) {
                return Object.assign({}, state, {
                    chemicalTypes: [],
                    chemicalCommodity: [],
                    chemicalHolds: [],
                    inactivityHistory: []
                });
            }

            let chemicalTypes = action.payload.chemicalTypes;
            let chemicalCommodity = action.payload.commodities;
            let chemicalHolds = action.payload.chemicalHolds;
            let inactivityHistory = action.payload.commoditiesAudit;

            return Object.assign({}, state, {
                chemicalTypes: chemicalTypes,
                chemicalCommodity: chemicalCommodity,
                chemicalHolds: chemicalHolds,
                inactivityHistory: inactivityHistory
            });
        case ACTIVE_INGREDIENT_LIST:
            // Add func to look up chemical Names
            let activeIngredients = action.payload;

            return Object.assign({}, state, {
                activeIngredients: activeIngredients.sort((a, b) => {
                    if (!a.activeIngredientName && b.activeIngredientName) {
                        return -1;
                    } else if (a.activeIngredientName && !b.activeIngredientName) {
                        return 1;
                    }

                    return b.activeIngredientName.trim().toLocaleLowerCase() > a.activeIngredientName.trim().toLocaleLowerCase()
                        ? -1
                        : b.activeIngredientName.trim().toLocaleLowerCase() < a.activeIngredientName.trim().toLocaleLowerCase()
                        ? 1
                        : 0;
                })
            });
        case EDIT_ACTIVE_INGREDIENT:
            var newActiveIngredientList = state.activeIngredients;
            newActiveIngredientList[action.payload.index] = action.payload.newVal;

            return Object.assign({}, state, {
                activeIngredients: newActiveIngredientList.sort((a, b) => {
                    return b.activeIngredientName.trim().toLocaleLowerCase() > a.activeIngredientName.trim().toLocaleLowerCase()
                        ? -1
                        : b.activeIngredientName.trim().toLocaleLowerCase() < a.activeIngredientName.trim().toLocaleLowerCase()
                        ? 1
                        : 0;
                })
            });
        default:
            return state;
    }
};

export default chemicalMaintenanceReducer;
