import { Middleware } from 'one-ring';
import APIEndPoints from './../services/api';
import DrcSortUtilities from './../helpers/DrcSortUtilities';
import { DuDateUtilities, DuAuthenticationUtilities } from 'driscolls-react-utilities';

const HOLDS = 'getHolds';
const COUNTRY_RESTRICTIONS = 'countryRestriction';
const BLOCKS = 'getBlockList';
const ADD_HOLDS = 'holdDetails';
const CHEMICAL_ACTIVITY = 'getChemicalApplications';

export const setApplicationsDetails = (token, ranchNumber, applicationsDetailsAction) => {
    applicationsDetailsAction({ application: [], noApplication: [], restrictions: [], noFormFiled: false });
    Middleware.SendRaw(token, APIEndPoints.APPLICATIONS(ranchNumber))
        .then((data) => {
            if (data.status && data.status !== 200) {
                console.log('Error fetching blocks', data);
            } else {
                const chemicalActivityData = {
                    application: DrcSortUtilities.SortArrayInDescendingOrder(data.Application, 'WeekEndDate'),
                    noApplication: DrcSortUtilities.SortArrayInDescendingOrder(data.NoApplication, 'WeekEndDate'),
                    restrictions: DrcSortUtilities.SortArrayInDescendingOrder(data.Restrictions.Data, 'WeekEndDate'),
                    noFormFiled: data.Restrictions.NoFormFiled
                };
                applicationsDetailsAction(chemicalActivityData);
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

export const setHoldDetails = async (token, ranchNumber, holdsAction) => {
    let foodSafetyHold = [];
    let inspectionHold = [];
    let quarantineHold = [];
    let transferHold = [];

    Middleware.Send(HOLDS, token, APIEndPoints.HOLDS(ranchNumber), 'GET')
        .then((data) => {
            if (data.status && data.status !== 200) {
                console.log('Error fetching holds details', data);
                const holdsData = {
                    foodSafetyHold,
                    inspectionHold,
                    quarantineHold,
                    transferHold
                };
                holdsAction(holdsData);
            } else {
                foodSafetyHold = data.foodSafetyHolds || [];
                inspectionHold = data.inspectionHolds || [];
                quarantineHold = data.quarantineHolds || [];
                transferHold = data.transferHolds || [];

                const holdsData = {
                    foodSafetyHold: foodSafetyHold ? DrcSortUtilities.SortArrayInDescendingOrder(foodSafetyHold, 'startDate') : [],
                    inspectionHold: inspectionHold ? DrcSortUtilities.SortArrayInDescendingOrder(inspectionHold, 'startDate') : [],
                    quarantineHold: quarantineHold ? DrcSortUtilities.SortArrayInDescendingOrder(quarantineHold, 'startDate') : [],
                    transferHold: transferHold ? DrcSortUtilities.SortArrayInDescendingOrder(transferHold, 'startDate') : []
                };
                holdsAction(holdsData);
            }
        })
        .catch((error) => {
            console.log(error);
            const holdsData = {
                foodSafetyHold,
                inspectionHold,
                quarantineHold,
                transferHold
            };
            holdsAction(holdsData);
        });
};

export const setRestrictions = (token, ranchNumber, restrictionsAction) => {
    Middleware.Send(COUNTRY_RESTRICTIONS, token, APIEndPoints.COUNTRY_RESTRICTIONS(ranchNumber), 'GET').then((data) => {
        let countryRestrictions = (data.Results || []).filter(function (data) {
            return data.isActive;
        });
        restrictionsAction(countryRestrictions);
    });
};

export const setPurRestrictions = (token, ranchNumber, restrictionsAction) => {
    //restrictionsAction([]);
    Middleware.SendRaw(token, APIEndPoints.PUR_RESTRICTIONS(ranchNumber)).then((data) => {
        restrictionsAction(data);
    });
};

export const refreshRanchData = (
    token,
    ranch,
    setBlocksAction,
    setHoldsAction,
    setApplicationAction,
    ranchLevelHoldDetailsAction,
    statusUpdate,
    clearAction
) => {
    clearAction();
    setRanchData(token, ranch, setBlocksAction, setHoldsAction, setApplicationAction, ranchLevelHoldDetailsAction, (data) => {
        statusUpdate(data);
    });
};

export const setBlocks = (token, ranchNumber, setBlocksAction) => {
    Middleware.Send(BLOCKS, token, APIEndPoints.BLOCK_LIST(ranchNumber), 'GET')
        .then((data) => {
            if (data.status && data.status !== 200) {
                console.log('Error fetching blocks', data);
            } else {
                setBlocksAction(data.Results);
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

const ranchLevelHolds = (hold) => {
    let currentDate = new Date(DuDateUtilities.FormatDateFromIso(new Date().toISOString())).getTime();
    let ranchLevelHold = hold.filter((record) => record.mrlBlock === 'ALL' && (!record.endDate || new Date(record.endDate).getTime() >= currentDate));

    return ranchLevelHold || [];
};

const getRanchLevelHolds = (holdsData) => {
    //get ranch level holds
    let currentDate = new Date();

    let holds = ranchLevelHolds(holdsData);
    //sort ranch level holds on end date - asc order
    let sortedHolds = DrcSortUtilities.SortArrayInAscendingOrder(holds, 'startDate');

    let record = (sortedHolds || []).find(
        (hold) => currentDate >= new Date(hold.startDate) && (!hold.endDate || currentDate <= new Date(hold.endDate))
    );

    return record || {};
};

export const ranchLevelHoldApplied = (holdsData) => {
    return {
        foodSafetyHold: getRanchLevelHolds(holdsData.foodSafetyHold),
        inspectionHold: getRanchLevelHolds(holdsData.inspectionHold),
        quarantineHold: getRanchLevelHolds(holdsData.quarantineHold),
        transferHold: getRanchLevelHolds(holdsData.transferHold),
        endOfSeasonHold: getRanchLevelHolds(holdsData.endOfSeasonHold)
    };
};

const getBlockLevelHoldHeaderInfo = (holdsData) => {
    let currentDate = new Date();

    let holds = (holdsData || []).filter(
        (hold) => hold.mrlBlock !== 'ALL' && currentDate >= new Date(hold.startDate) && currentDate <= new Date(hold.endDate)
    );

    //Determine if all holds share the same date created
    if (!holds || holds.length <= 0) {
        return {};
    }

    //If all holds are the same date then we can include the date
    if (holds.every((hold) => hold.createdDateTime === holds[0].createdDateTime)) {
        return {
            blocks: (holds.map((hold) => hold.mrlBlock) || []).sort(),
            date: holds[0].createdDateTime
        };
    }

    //otherwise we show what blocks have active holds in place
    return {
        blocks: (holds.map((hold) => hold.mrlBlock) || []).sort(),
        date: null
    };
};

export const blockLevelHoldApplied = (holdsData) => {
    return {
        foodSafetyHold: getBlockLevelHoldHeaderInfo(holdsData.foodSafetyHold) || {},
        inspectionHold: getBlockLevelHoldHeaderInfo(holdsData.inspectionHold) || {},
        quarantineHold: getBlockLevelHoldHeaderInfo(holdsData.quarantineHold) || {},
        transferHold: getBlockLevelHoldHeaderInfo(holdsData.transferHold) || {}
    };
};

export const setRanchData = (token, ranchNumber, setBlocksAction, holdsAction, applicationAction, ranchLevelHoldDetailsAction, statusUpdate) => {
    const ranchData = [
        {
            name: HOLDS,
            endpoint: APIEndPoints.HOLDS(ranchNumber),
            weight: 500,
            returnFunction: (data, props) => {
                const holdsData = {
                    foodSafetyHold: data.foodSafetyHolds.length ? DrcSortUtilities.SortArrayInDescendingOrder(data.foodSafetyHolds, 'startDate') : [],
                    inspectionHold: data.inspectionHolds.length ? DrcSortUtilities.SortArrayInDescendingOrder(data.inspectionHolds, 'startDate') : [],
                    quarantineHold: data.quarantineHolds.length ? DrcSortUtilities.SortArrayInDescendingOrder(data.quarantineHolds, 'startDate') : [],
                    transferHold: data.transferHolds.length ? DrcSortUtilities.SortArrayInDescendingOrder(data.transferHolds, 'startDate') : [],
                    endOfSeasonHold: data.endOfSeasonHolds.length
                        ? DrcSortUtilities.SortArrayInDescendingOrder(data.endOfSeasonHolds, 'startDate')
                        : []
                };
                let ranchHolds = ranchLevelHoldApplied(holdsData);
                let blockHolds = blockLevelHoldApplied(holdsData);

                ranchLevelHoldDetailsAction({ ranchHolds: ranchHolds, blockHolds: blockHolds });
                holdsAction(holdsData);
            }
        },
        {
            name: BLOCKS,
            endpoint: APIEndPoints.BLOCK_LIST(ranchNumber),
            weight: 100,
            returnFunction: (data, props) => {
                setBlocksAction(data.Results);
            }
        },
        {
            name: CHEMICAL_ACTIVITY,
            endpoint: APIEndPoints.APPLICATIONS(ranchNumber),
            weight: 200,
            returnFunction: (data, props) => {
                const chemicalActivityData = {
                    application: DrcSortUtilities.SortArrayInDescendingOrder(data.Application, 'WeekEndDate'),
                    noApplication: DrcSortUtilities.SortArrayInDescendingOrder(data.NoApplication, 'WeekEndDate'),
                    restrictions: DrcSortUtilities.SortArrayInDescendingOrder(data.Restrictions.Data, 'WeekEndDate'),
                    noFormFiled: data.Restrictions.NoFormFiled
                };
                applicationAction(chemicalActivityData);
            }
        }
    ];

    var apiCalls = [];

    ranchData.forEach((call) => {
        var middlewareCall = Middleware.CreateSendCall(call.name, token, call.endpoint);

        apiCalls.push(
            Middleware.AttachReturnFunction(middlewareCall, call.weight, (data) => {
                call.returnFunction(data);
            })
        );
    });

    Middleware.SendMultiple('blocksAndHolds', apiCalls, statusUpdate)
        .then((message) => {
            statusUpdate(message);
        })
        .catch((error) => {
            statusUpdate(error);
        });
};

const getTime = (date) => {
    return new Date(date).getTime();
};

const isRecordExists = (mrlBlock, startDate, endDate, dataArr, areaCode = '', chemicalId = '') => {
    // //1. let us say you have hold from 14th to 20th and want to place a hold from 15th to 19th
    // //2. let us say you have hold from 15th to 19th and want to place a hold from 14th to 20th
    // //3. let us say you have hold from 15th to 19th and want to place a hold from 14th to 17th
    // //4. let us say you have hold from 15th to 19th and want to place a hold from 17th to 20th

    let startTime = getTime(DuDateUtilities.FormatDateTimeFromIso(startDate.toISOString()));
    let endTime = getTime(DuDateUtilities.FormatDateTimeFromIso(endDate.toISOString()));

    let recordExists = (dataArr || []).some((record) => {
        // add country restrictions area code check
        return (
            (areaCode ? record.areaCode === areaCode : true) &&
            (chemicalId ? record.chemicalId === chemicalId : true) &&
            record.mrlBlock === mrlBlock &&
            //    within the range
            ((startTime >= getTime(record.startDate) && endTime <= getTime(record.endDate)) ||
                //    outside the range
                (startTime <= getTime(record.startDate) && endTime >= getTime(record.endDate)) ||
                //    overlapping range
                (startTime <= getTime(record.startDate) && endTime >= getTime(record.startDate) && endTime <= getTime(record.endDate)) ||
                (startTime >= getTime(record.startDate) && startTime <= getTime(record.endDate) && endTime >= getTime(record.endDate)))
        );
    });

    return recordExists;
};

export const checkIfCountryRestrictionExists = (mrlBlock, startDate, endDate, restrictions, areaCode) => {
    return isRecordExists(mrlBlock, startDate, endDate, restrictions, areaCode);
};

export const checkIfHoldsExists = (mrlBlock, startDate, endDate, holds) => {
    return isRecordExists(mrlBlock, startDate, endDate, holds);
};

const checkIfPURExists = (mrlBlock, chemicalId, eventStartDate, eventEndDate, data) => {
    let mappedData = (data || []).map((el) => {
        return {
            mrlBlock: el.MrlBlock,
            chemicalId: el.ChemicalId,
            startDate: DuDateUtilities.FormatDateTimeFromIso(el.EventStartDate),
            endDate: DuDateUtilities.FormatDateTimeFromIso(el.EventEndDate)
        };
    });

    return isRecordExists(mrlBlock, eventStartDate, eventEndDate, mappedData, '', chemicalId);
};

const mapRanchesToOptions = (data) => {
    return (data || []).map((item) => {
        return {
            label: item.ranchNumber,
            value: item.ranchNumber,
            isActive: item.overallStatus
        };
    });
};

const addHoldOnRanch = (token, ranchNumber, holdObj, onSuccess, onFailure) => {
    let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
    let date = new Date().toISOString();
    let payload = {};
    let ranchBlocks = [
        {
            ranchNbr: ranchNumber,
            blockDesignator: 'ALL',
            blockType: 'FOODSAFETY'
        }
    ];
    payload.ranchBlocks = ranchBlocks;
    payload.comments = holdObj.comment;
    payload.holdFlagType = holdObj.holdFlagType;
    payload.isoStartDateTime = new Date(holdObj.startDate).toISOString();
    if (holdObj.endDate) {
        payload.isoEndDateTime = new Date(holdObj.endDate).toISOString();
    }
    payload.transInfo = {
        TransactionDateTime: date,
        SourceSystem: 'FACTS',
        SourceUser: loggedInUser
    };
    payload.companyId = 210;
    Middleware.Send(ADD_HOLDS, token, APIEndPoints.ADD_HOLD, 'POST', payload, {
        overrideResponseMapping: true
    })
        .then((data) => {
            if (data && data.status) {
                onFailure(data);
            }
            onSuccess(data);
        })
        .catch((error) => {
            console.log(error);
            onFailure(error);
        });
};

const addEndOfSeasonHoldOnRanch = (token, ranchNumber, holdObj, onSuccess, onFailure) => {
    let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
    let date = new Date().toISOString();
    let payload = {};
    payload.ranchNbr = ranchNumber;
    payload.blockType = 'FOODSAFETY';
    payload.comments = holdObj.comment;
    payload.holdFlagType = holdObj.holdFlagType;
    payload.sourceDocumentId = holdObj.sourceDocumentId;
    payload.eventSource = 'FACTS';
    payload.isoStartDateTime = new Date(holdObj.startDate).toISOString();
    if (holdObj.endDate) {
        payload.isoEndDateTime = new Date(holdObj.endDate).toISOString();
    }
    payload.transInfo = {
        TransactionDateTime: date,
        SourceSystem: 'FACTS',
        SourceUser: loggedInUser
    };
    payload.companyId = 210;
    Middleware.Send(ADD_HOLDS, token, APIEndPoints.ADD_END_OF_SEASON_HOLD, 'POST', payload, {
        overrideResponseMapping: true
    })
        .then((data) => {
            if (data && data.status) {
                onFailure(data);
            }
            onSuccess(data);
        })
        .catch((error) => {
            console.log(error);
            onFailure(error);
        });
};

const removeEndOfSeasonHold = (token, ranchNumber, holdObj, onSuccess, onFailure) => {
    let delObj = {
        ranchNbr: ranchNumber,
        producingArea: holdObj.producingArea,
        blockType: 'FOODSAFETY',
        holdFlagType: holdObj.holdFlagType,
        sourceDocumentId: holdObj.sourceDocumentId,
        isoStartDateTime: new Date(holdObj.startDate).toISOString(),
        eventSource: 'FACTS',
        comments: 'End of Season Hold Removed',
        transInfo: {
            TransactionDateTime: new Date().toISOString(),
            SourceSystem: 'FACTS',
            SourceUser: DuAuthenticationUtilities.GetUserId(token) || 'Bad Token'
        },
        companyId: 210
    };
    Middleware.Send('holdDetails', token, APIEndPoints.REMOVE_END_OF_SEASON_HOLD, 'DELETE', delObj, { overrideResponseMapping: true })
        .then((data) => {
            if (data && data.status) {
                onFailure(data);
            }
            onSuccess(data);
        })
        .catch((error) => {
            console.log(error);
            onFailure(error);
        });
};

const RanchUtilities = {
    SetApplicationsDetails: setApplicationsDetails,
    SetRestrictions: setRestrictions,
    SetPurRestrictions: setPurRestrictions,
    SetBlockList: setBlocks,
    SetHoldDetails: setHoldDetails,
    SetRanchData: setRanchData,
    CheckIfHoldsExists: checkIfHoldsExists,
    CheckIfPURExists: checkIfPURExists,
    CheckIfCountryRestrictionExists: checkIfCountryRestrictionExists,
    RanchLevelHoldApplied: ranchLevelHoldApplied,
    RefreshRanchData: refreshRanchData,
    MapRanchesToOptions: mapRanchesToOptions,
    AddHoldOnRanch: addHoldOnRanch,
    AddEndOfSeasonHoldOnRanch: addEndOfSeasonHoldOnRanch,
    RemoveEndOfSeasonHold: removeEndOfSeasonHold
};

export default RanchUtilities;
