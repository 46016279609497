import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DrcTooltip, DrcPageWarning, DrcDialog, DrcButton, DrcInput, DrcSelect } from 'driscolls-react-components';
import { Middleware } from 'one-ring';
import { withAuth } from '@okta/okta-react';
import { DuAuthenticationUtilities, DuDateUtilities } from 'driscolls-react-utilities';
import APIEndPoints from '../../../services/api';
import { setActiveIngredients, setChemicalList } from '../../../actions/chemicalMaintenanceActions';
import { withStyles } from '@material-ui/core/styles';
import DrcPageDataMaintenance from '../../../components/drc/DrcPageDataMaintenance';

const PAGE_TYPE = 'activeIngredientDetails';
const PAGE_TITLE = 'AI MAPPING';
const CHEMICAL_LIST = 'chemicalList';

var styles = (theme) => ({
    grid: {
        padding: 0,
        margin: 0,
        '& div[class^="DrcPanel-"]': {
            border: `none`
        },
        '& .p-paginator button, & .p-paginator button span:before': {
            fontSize: '1.25rem'
        }
    },
    disabledRow: {
        pointerEvents: `none`,
        opacity: 0.5
    },
    removeArrowBtns: {
        '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            display: 'none'
        }
    },
    incompleteBtn: {
        float: 'right',
        textTransform: 'none'
    }
});

const ActiveIngredientDetail = (props) => {
    const { classes } = props;

    const preventInvalidNumericInput = (event) => {
        const invalidKeys = ['e', '+', '-', '.'];
        if (invalidKeys.includes(event.key.toLowerCase())) {
            event.preventDefault();
        }
    };

    const dateTimeFormatter = (value) => {
        return (
            <DrcTooltip tipText={value ? DuDateUtilities.ToPrettyDateTime(value) : ''}>
                <span>{value ? DuDateUtilities.ToPrettyDateTime(value) : ''}</span>
            </DrcTooltip>
        );
    };

    const cellFormatter = (value) => {
        return (
            <DrcTooltip tipText={value || ''}>
                <span>{value || ''}</span>
            </DrcTooltip>
        );
    };

    const columns = [
        {
            key: 'activeIngredientName',
            name: (
                <DrcTooltip tipText="Active Ingredient">
                    <span>Active Ingredient</span>
                </DrcTooltip>
            ),
            isRequired: true,
            validationType: 'required',
            maxLength: 255,
            width: 220,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.activeIngredientName)
        },
        {
            key: 'chemicalId',
            inputType: 'Select',
            selectOptions: props.chemicalList,
            name: (
                <DrcTooltip tipText="Driscoll's Name">
                    <span>Driscoll's Name</span>
                </DrcTooltip>
            ),
            isRequired: true,
            validationType: 'required',
            maxLength: 255,
            width: 0,
            formatter: (data) => {
                data.row.chemicalName = props.chemicalNameMapping ? props.chemicalNameMapping[data.value] : data.value;
                return data.value;
            },
            maxMenuHeight: 240,
            isOptionDisabled: (item) => !item.isActive
        },
        {
            key: 'chemicalName',
            name: (
                <DrcTooltip tipText="Driscoll's Name">
                    <span>Driscoll's Name</span>
                </DrcTooltip>
            ),
            isRequired: false,
            maxLength: 255,
            width: 220,
            isDisabled: true,
            editDisabled: true,
            isHidden: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.chemicalName)
        },
        {
            key: 'PPISCode',
            name: (
                <DrcTooltip tipText="PPIS Code">
                    <span>PPIS Code</span>
                </DrcTooltip>
            ),
            inputType: 'number',
            width: 100,
            maxLength: 10,
            isRequired: false,
            className: classes.removeArrowBtns,
            filter: true,
            onKeyPress: preventInvalidNumericInput
            //columnTemplate: (row) => cellFormatter(row.PPISCode)
        },
        {
            key: 'createdBy',
            name: (
                <DrcTooltip tipText="Created By">
                    <span>Created By</span>
                </DrcTooltip>
            ),
            isDisabled: true,
            width: 180,
            filter: true
            //columnTemplate: (row) => cellFormatter(row.createdBy)
        },
        {
            key: 'createdDateTime',
            name: (
                <DrcTooltip tipText="Created Date Time">
                    <span>Created Date Time</span>
                </DrcTooltip>
            ),
            isDisabled: true,
            width: 180,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.createdDateTime)
        },
        {
            key: 'lastChangedBy',
            name: (
                <DrcTooltip tipText="Modified By">
                    <span>Modified By</span>
                </DrcTooltip>
            ),
            isDisabled: true,
            width: 180,
            filter: true
            //columnTemplate: (row) => cellFormatter(row.lastChangedBy)
        },
        {
            key: 'lastChangedDateTime',
            name: (
                <DrcTooltip tipText="Modified Date Time">
                    <span>Modified Date Time</span>
                </DrcTooltip>
            ),
            isDisabled: true,
            width: 180,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.lastChangedDateTime)
        }
    ];

    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState(false);
    const [addDialog, setAddDialog] = useState(false);

    const [activeIngredientName, setActiveIngredientName] = useState('');
    const [ppisCode, setPpisCode] = useState('');

    const [chemicalId, setChemicalId] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [incompleteRecords, setIncompleteRecords] = useState([]);
    const [showIncompleteRecords, setShowIncompleteRecords] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState('');

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    useEffect(() => {
        //set Incomplete records
        checkAndListIncompleteRecords(props.activeIngredients);
    }, [props.activeIngredients]);

    const fetchChemicals = () => {
        if (!props.chemicalList ? props.chemicalList.length : []) {
            getChemicals();
        }
    };
    useEffect(fetchChemicals, []);

    const getChemicals = () => {
        props.auth.getAccessToken().then((token) => {
            setLoggedInUser(DuAuthenticationUtilities.GetUserId(token) || 'Bad Token');
            Middleware.Send(CHEMICAL_LIST, token, APIEndPoints.CHEMICAL_LIST, 'GET')
                .then((data) => {
                    // let chemicalList = data.Results?.filter((item) => item.isActive);
                    props.setChemicalList(data.Results);
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    };

    const handleKeyPress = (event) => {
        const invalidKeys = ['e', '+', '-', '.'];
        if (invalidKeys.includes(event.key.toLowerCase())) {
            event.preventDefault();
        }
    };

    const handlePaste = (event) => {
        const invalidKeys = ['e', '+', '-', '.'];
        let paste = (event.clipboardData || window.clipboardData).getData('text');
        if (invalidKeys.filter((item) => paste.toLowerCase().indexOf(item) > -1).length > 0) {
            event.preventDefault();
        }
    };

    const onAdd = (addObj) => {
        //check if AI is already present
        let index = props.activeIngredients.length
            ? props.activeIngredients.findIndex((record) =>
                  record.activeIngredientName ? record.activeIngredientName.toLocaleLowerCase() === activeIngredientName.toLocaleLowerCase() : false
              )
            : -1;
        if (index > -1) {
            showErrorMessage('Active Ingredient Name already exists! Please try with another name');
            setOnError(true);
        } else {
            props.auth.getAccessToken().then((token) => {
                let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
                let date = new Date().toISOString();

                addObj.activeIngredientName = activeIngredientName;
                addObj.chemicalId = chemicalId.value;
                addObj.PPISCode = ppisCode;
                addObj.isActive = true;
                addObj.createdBy = loggedInUser;
                addObj.createdDateTime = date;
                //https://driscollsit.atlassian.net/browse/FE-175
                //Added additional header to invalidate cache
                Middleware.Send(PAGE_TYPE, token, APIEndPoints.ACTIVE_INGREDIENTS, 'POST', addObj, { overrideResponseMapping: true }, null, 0, {
                    invalidate: 'invalidate'
                })
                    .then((data) => {
                        if (data.status && data.status !== 201) {
                            showErrorMessage(data.message);
                            setOnError(true);
                        } else {
                            setAddDialog(false);
                            showErrorMessage('Added Active Ingredient Successfully!');
                            setOnError(false);
                            props.getData();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        showErrorMessage('Failed to Add Active Ingredient');
                        setOnError(true);
                    });

                addObj.activeIngredientId = Math.random();
                setActiveIngredients(addObj);
            });
        }
    };

    const onEdit = (oldValue, newValue) => {
        //check if AI is already present
        let index = props.activeIngredients.length
            ? props.activeIngredients.findIndex((record) => (record.activeIngredientName || '').toLocaleLowerCase() === newValue.activeIngredientName)
            : -1;
        if (index >= 0 && oldValue.activeIngredientName.toLocaleLowerCase() !== newValue.activeIngredientName.toLocaleLowerCase()) {
            showErrorMessage('Active Ingredient Name already exists! Please try with another name');
            setOnError(true);
        } else {
            props.auth.getAccessToken().then((token) => {
                newValue.lastChangedBy = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
                newValue.lastChangedDateTime = new Date().toISOString();

                //override payload to support empty fields
                const payload = {
                    ActiveIngredientId: newValue.activeIngredientId,
                    ActiveIngredientName: newValue.activeIngredientName,
                    PPISCode: newValue.PPISCode,
                    ChemicalId: newValue.chemicalId,
                    ChemicalName: newValue.chemicalName,
                    CreatedBy: newValue.createdBy,
                    CreatedDateTime: new Date(newValue.createdDateTime).toISOString(),
                    IsActive: newValue.isActive,
                    ModifiedBy: newValue.lastChangedBy,
                    ModifiedDateTime: newValue.lastChangedDateTime
                };
                //https://driscollsit.atlassian.net/browse/FE-175
                //Added additional header to invalidate cache
                Middleware.Send(
                    PAGE_TYPE,
                    token,
                    APIEndPoints.ACTIVE_INGREDIENTS,
                    'POST',
                    payload,
                    {
                        overrideRequestMapping: true,
                        overrideResponseMapping: true
                    },
                    null,
                    0,
                    {
                        invalidate: 'invalidate'
                    }
                )
                    .then((data) => {
                        if (data.status && data.status !== 201) {
                            showErrorMessage(data.message);
                            setOnError(true);
                        } else {
                            showErrorMessage('Edited Active Ingredient Successfully!');
                            setOnError(false);
                            props.getData();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setOnError(true);
                        showErrorMessage('Failed to Edit Active Ingredient');
                    });
            });
        }
    };

    const handleDelete = (delObj) => {
        let records = Object.values(delObj);
        props.auth.getAccessToken().then(async (token) => {
            let payload = {};
            payload.activeIngrediens = [];
            let deletingItems = records.map((record) => {
                let item = props.activeIngredients.length
                    ? props.activeIngredients.find((itm) => itm.activeIngredientId === record.activeIngredientId)
                    : null;
                return {
                    ActiveIngredientId: record.activeIngredientId,
                    ActiveIngredientName: record.activeIngredientName,
                    isActive: false,
                    PPISCode: item.PPISCode,
                    ChemicalId: item.chemicalId,
                    ChemicalName: item.chemicalName,
                    CreatedBy: item.createdBy,
                    CreatedDateTime: item.createdDateTime,
                    ModifiedBy: DuAuthenticationUtilities.GetUserId(token) || 'Bad Token',
                    ModifiedDateTime: new Date().toISOString()
                };
            });
            //https://driscollsit.atlassian.net/browse/FE-175
            //Added additional header to invalidate cache
            Middleware.Send(
                PAGE_TYPE,
                token,
                APIEndPoints.ACTIVE_INGREDIENTS,
                'DELETE',
                deletingItems,
                {
                    overrideRequestMapping: true,
                    overrideResponseMapping: true
                },
                null,
                0,
                { invalidate: 'invalidate' }
            )
                .then((data) => {
                    if (data.status && data.status !== 201) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        showErrorMessage('Deleted Active Ingredient(s) Successfully!');
                        setOnError(false);
                        props.getData();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setOnError(true);
                    showErrorMessage('Failed to Delete Active Ingredient(s)');
                });
        });
    };

    const showAddDialog = () => {
        //set empty values to add new active ingredient
        setActiveIngredientName('');
        setPpisCode('');
        setChemicalId('');
        setIsValid(false);
        setAddDialog(true);
    };

    const onAddDialogClose = () => {
        setAddDialog(false);
    };

    const handleOnChangeOfDialogFields = (e, field) => {
        if (field === 'chemicalId') {
            setChemicalId(e);
            setIsValid(e.label && activeIngredientName);
        } else if (field === 'ppisCode') {
            setPpisCode(e.target.value);
        } else if (field === 'activeIngredientName') {
            setActiveIngredientName(e.target.value);
            setIsValid(chemicalId && e.target.value);
        }
    };

    const showRecords = () => {
        setShowIncompleteRecords(!showIncompleteRecords);
    };

    const checkAndListIncompleteRecords = (activeIngredients) => {
        let modifiedRecords = activeIngredients
            ? activeIngredients.filter((record) => !record.activeIngredientName || !record.chemicalId)
            : []
            ? activeIngredients
                  .map((item) => {
                      return {
                          ...item,
                          count: item.activeIngredientName && item.chemicalId ? 1 : 0
                      };
                  })
                  .sort((a, b) => {
                      return a['count'] > b['count'] ? -1 : 1;
                  })
            : [];
        if (!modifiedRecords) {
            modifiedRecords = [];
        }
        setIncompleteRecords(modifiedRecords);
    };

    /*const mapActiveIngredientsToColumns = (data) => {
        return data;
    };

    const rowRenderer = ({ renderBaseRow, ...props }) => {
        return <div className={!props.row.isActive ? `${classes.disabledRow}` : ''}>{renderBaseRow(props)}</div>;
    };*/

    const gridDataFormatter = (data) => {
        if (data.length === 0) {
            return [];
        }
        let date = new Date().toISOString();
        let modifiedData = [
            {
                activeIngredientName: data[0].activeIngredientName,
                chemicalId: data[0].chemicalId,
                chemicalName: props.chemicalNameMapping[data[0].chemicalId] || 'N/A',
                PPISCode: data[0].PPISCode,
                createdBy: data[0].createdBy,
                createdDateTime: data[0].createdDateTime,
                lastChangedBy: loggedInUser,
                lastChangedDateTime: DuDateUtilities.FormatDateTimeFromIso(date)
            }
        ];
        return modifiedData;
    };

    return (
        <div>
            <DrcPageDataMaintenance
                hideCount={true}
                className={`${classes.grid}`}
                columns={columns}
                fullWidth={true}
                data={!showIncompleteRecords ? props.activeIngredients : incompleteRecords}
                type={PAGE_TITLE}
                customButtons={
                    <DrcButton isSecondary onClick={showRecords} noStyle className={classes.incompleteBtn}>
                        {showIncompleteRecords ? 'Show All' : 'Show Incomplete'}
                    </DrcButton>
                }
                textOptions={{
                    PageTitle: '',
                    AddBtn: 'Add New',
                    SelectAllBtn: 'Select All',
                    UnselectAllBtn: 'Unselect All',
                    deleteTypeConfirmTitle: 'Are you sure you want to delete the selected items?'
                }}
                settings={{
                    EnableExport: false,
                    EnableAdd: props.isAdmin,
                    EnableEdit: props.isAdmin,
                    EnableDelete: false,
                    SelectAll: props.isAdmin && showIncompleteRecords,
                    EnableCheckBoxDelete: {
                        access: props.isAdmin && showIncompleteRecords,
                        key: 'ChemicalHoldId'
                    },
                    OverrideAdd: showAddDialog
                }}
                onAdd={onAdd}
                onEdit={onEdit}
                onCheckboxDelete={handleDelete}
                gridDataFormatter={gridDataFormatter}
                resultCount={((!showIncompleteRecords ? props.activeIngredients : incompleteRecords) || []).length}
                pageSize={10}
                loading={false}
                paginator
                columnKey={'activeIngredientId'}
            />
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}
            <DrcDialog
                open={addDialog}
                title={'Add New Active Ingredient'}
                buttons={
                    <div>
                        <DrcButton isSecondary onClick={onAddDialogClose} floatRight>
                            Cancel
                        </DrcButton>
                        <DrcButton isPrimary onClick={onAdd} floatRight disabled={!isValid}>
                            Save
                        </DrcButton>
                    </div>
                }
            >
                <div className="row" style={{ margin: '1rem', padding: ' 0 .5rem' }}>
                    <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                        <DrcInput
                            // floatRight
                            label={'Active Ingredient Name'}
                            required
                            value={activeIngredientName}
                            onChange={(e) => handleOnChangeOfDialogFields(e, 'activeIngredientName')}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 255 }}
                        />
                    </div>
                    <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                        <DrcSelect
                            floatRight
                            label={"Driscoll's Name"}
                            required
                            value={chemicalId}
                            options={props.chemicalList}
                            isOptionDisabled={(item) => !item.isActive}
                            onChange={(e) => handleOnChangeOfDialogFields(e, 'chemicalId')}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                        <DrcInput
                            floatRight
                            type={'number'}
                            label={'PPIS Code'}
                            value={ppisCode}
                            onPaste={(e) => handlePaste(e)}
                            onKeyPress={(e) => handleKeyPress(e)}
                            onChange={(e) => handleOnChangeOfDialogFields(e, 'ppisCode')}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 10, min: 0 }}
                        />
                    </div>
                </div>
            </DrcDialog>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        activeIngredients: state.chemicalMaintenance.activeIngredients,
        chemicalList: state.chemicalMaintenance.chemicalList,
        chemicalNameMapping: state.chemicalMaintenance.chemicalNameMapping,
        isAdmin: state.masterReducer.isAdmin
    };
};

const mapDispatchToProps = (dispatch) => ({
    setActiveIngredients: (data) => dispatch(setActiveIngredients(data)),
    setChemicalList: (list) => dispatch(setChemicalList(list))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(ActiveIngredientDetail)));
