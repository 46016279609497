export const isValidRanchNumber = (ranchNumber) => {
    return ranchNumber === '0008760' ? true : false;
};

export const isValidNumber = (number) => {
    return true;
};

const defaultColumnProperties = {
    resizable: true,
    filterable: true
};

export const getColumns = (columns, data) => {
    var returnValue = [];
    if (!columns) {
        Object.keys(data[0] || []).forEach(function(c) {
            returnValue.push({
                key: c,
                name: c,
                ...defaultColumnProperties
            });
        });
    } else {
        columns.forEach(function(c) {
            returnValue.push(c);
        });
    }
    return returnValue;
};

export const getMRLStringAsArray = (mrlString, delimiter) => {
    let values = mrlString.split(delimiter);
    let designators = values
        .map((value) => {
            if (value && value.match(/^[a-zA-Z0-9]*$/) && value.length === 2) {
                return value;
            } else {
                return null;
            }
        })
        .filter((item) => item);
    //If any designators are invalid, return null
    designators = values.length === designators.length ? designators : null;
    return [...new Set(designators)];
};

export const getValidMRLBlockDesignator = (designator) => {
    if (designator && designator.indexOf('-') > -1 && designator.indexOf(',') === -1) {
        return designator;
    } else if (designator && designator.indexOf(',') > -1 && designator.indexOf('-') === -1 && designator.indexOf(';') === -1) {
        return getMRLStringAsArray(designator, ',');
    } else if (designator && designator.indexOf(';') > -1 && designator.indexOf('-') === -1 && designator.indexOf(',') === -1) {
        return getMRLStringAsArray(designator, ';');
    } else if (designator && designator.match(/^[a-zA-Z0-9]*$/) && designator.length === 2) {
        return [designator];
    }
    return null;
};

export const getMRLCombinations = (value) => {
    let returnValue = getValidMRLBlockDesignator(value);
    if (returnValue && returnValue.indexOf('-') > -1) {
        let startValue = value.split('-')[0];
        let endValue = value.split('-')[1];
        if (!isNaN(startValue) && !isNaN(endValue)) {
            returnValue = [];
            for (let i = startValue; i <= endValue; i++) {
                let valueToPush = parseInt(i) < 10 && parseInt(i) !== 0 ? '0' + parseInt(i) : i;
                if (valueToPush.toString().match(/^[a-zA-Z0-9]*$/) && valueToPush.toString().length === 2) {
                    returnValue.push(valueToPush);
                } else {
                    return null;
                }
            }
            return returnValue;
        } else {
            let firstAlphabet = startValue.substring(startValue.length - 1);
            let lastAlphabet = endValue.substring(endValue.length - 1);
            let firstPart = startValue.substring(0, startValue.length - 1);
            let secondPart = endValue.substring(0, endValue.length - 1);
            let firstNumber = firstPart.indexOf('.') > -1 ? '-' : isNaN(firstPart) ? firstPart : parseInt(firstPart);
            let lastNumber = secondPart.indexOf('.') > -1 ? '-' : isNaN(secondPart) ? secondPart : parseInt(secondPart);
            let firstCharCode = firstAlphabet.charCodeAt(0);
            let lastCharCode = lastAlphabet.charCodeAt(0);
            if (
                firstAlphabet.toLowerCase() !== lastAlphabet.toLowerCase() &&
                isNaN(firstAlphabet) &&
                isNaN(lastAlphabet) &&
                firstNumber !== lastNumber
            ) {
                return null;
            }
            let combos = [];
            let intDifference = lastNumber - firstNumber;
            if (isNaN(intDifference)) {
                intDifference = 0;
            }
            let charDifference = lastCharCode - firstCharCode;
            if (intDifference < 0) {
                return null;
            }
            for (let i = 0, j = 0; i <= Math.max(intDifference, charDifference); j++, i++) {
                let num = firstNumber;
                if (num < lastNumber) {
                    num = firstNumber + j;
                }
                let char = firstAlphabet;
                if (i <= charDifference) {
                    char = String.fromCharCode(firstCharCode + i);
                }
                num = num + char;
                if (num.match(/^[a-zA-Z0-9]*$/) && num.length === 2) {
                    combos.push(num);
                } else {
                    return null;
                }
            }
            return combos?.length === 1 && returnValue?.indexOf('-') > -1 ? null : combos;
        }
    } else if (returnValue && Array.isArray(returnValue) && returnValue.length > 0) {
        return returnValue;
    } else {
        return null;
    }
};

export default { isValidRanchNumber, getMRLCombinations };
