import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../../actions/actions';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { DrcMain, DrcPageWarning, DrcTabs } from 'driscolls-react-components';
import RanchOverview from '../RanchOverview/RanchOverview';
import Maintenance from '../Maintenance/Maintenance';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import TuneIcon from '@material-ui/icons/Tune';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import Chemical from '../Maintenance/Chemical';
import { DuThemeUtilities } from 'driscolls-react-utilities';
import ErrorIcon from '@material-ui/icons/Error';
import ErrorsListing from '../ErrorDashboard/ErrorsListing';

const pageTitle = 'Chemical Application';

let styles = (theme) => ({
    main: {
        paddingLeft: 0,
        paddingRight: 0
    },
    subTabItems: {
        border: '1px solid black',
        backgroundColor: ''
    },
    panel: {
        border: `none`,
        marginTop: `2rem`,
        borderRadius: 2,
        boxShadow: `0 2px 4px 0 #C3C3C3`
    },
    tabPanel: {
        border: `none`,
        paddingTop: 0,
        borderRadius: 2,
        boxShadow: `0 2px 4px 0 #C3C3C3`
    },
    tabItem: {
        opacity: 0.7,
        fontSize: `12px`,
        letterSpacing: `0.31px`,
        lineHeight: `24px`,
        marginTop: theme.spacing(0.5),
        [theme.breakpoints.down('xs')]: {
            width: 90
        },
        background: DuThemeUtilities.DefaultColors.text.white.light,
        borderRadius: 10,
        padding: '3px 8px 6px 7px',
        margin: '0 12px 0 0',
        '@media (prefers-color-scheme: dark)': {
            background: DuThemeUtilities.DefaultColors.text.black.light,
            color: DuThemeUtilities.DefaultColors.text.white.light
        }
    },
    activeTabItem: {
        opacity: 1
    },
    indicator: {
        display: 'none'
    },
    tabIcon: {
        fontSize: '1.7rem',
        marginRight: '0.5rem',
        transform: 'translateY(0.5rem)',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            margin: '0 auto 10px'
        }
    },
    tabText: {
        '@media (prefers-color-scheme: dark)': {
            color: DuThemeUtilities.DefaultColors.text.white.light
        },
        [theme.breakpoints.down('xs')]: {
            lineHeight: '20px'
        }
    }
});

const allGroups = (window.config.OKTA_ADMIN_GROUPS || [])
    .concat(window.config.OKTA_REGULAR_GROUPS || [], window.config.OKTA_READ_ONLY_GROUPS || [])
    .concat(window.config.OKTA_DC_USER_GROUPS || []);

const adminGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || []);
const chemMainGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(
    window.config.OKTA_REGULAR_GROUPS || [],
    window.config.OKTA_READ_ONLY_GROUPS || []
);

class ChemicalApplication extends Component {
    tabs = [
        {
            tabName: (
                <span className={`${this.props.classes.tabText}`}>
                    <CropRotateIcon className={`${this.props.classes.tabIcon}`}></CropRotateIcon>
                    <span>RANCH OVERVIEW</span>
                </span>
            ),
            tabIndex: 0,
            component: RanchOverview,
            route: '/RanchOverview',
            isDefault: true,
            groupsAllowed: allGroups
        },
        {
            tabName: (
                <span className={`${this.props.classes.tabText}`}>
                    <i className="material-icons" style={{ fontSize: '2rem', marginRight: '0.5rem', transform: 'translateY(0.5rem)' }}>
                        science
                    </i>
                    <span>CHEMICAL MAINTENANCE</span>
                </span>
            ),
            tabIndex: 1,
            component: Chemical,
            route: `/ChemicalMaintenance`,
            groupsAllowed: chemMainGroups
        },
        {
            tabName: (
                <span className={`${this.props.classes.tabText}`}>
                    <TuneIcon className={`${this.props.classes.tabIcon}`}></TuneIcon>
                    <span>MAINTENANCE</span>
                </span>
            ),
            tabIndex: 2,
            component: Maintenance,
            route: `/Maintenance`,
            groupsAllowed: adminGroups
        },
        {
            tabName: (
                <span className={`${this.props.classes.tabText}`}>
                    <ErrorIcon className={`${this.props.classes.tabIcon}`}></ErrorIcon>
                    <span>ERRORS</span>
                </span>
            ),
            tabIndex: 3,
            component: ErrorsListing,
            route: `/ErrorListing`,
            groupsAllowed: adminGroups
        }
    ];

    state = {
        selectedTabIndex: 0,
        menuItems: []
    };

    componentWillMount() {
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        this.filterTabsByUserGroup();
    }

    componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }

    renderPageWarning = (errorMessage) => {
        return errorMessage ? <DrcPageWarning anchorVertical="top" message={[errorMessage]}></DrcPageWarning> : null;
    };

    filterTabsByUserGroup = async () => {
        this.props.auth.getAccessToken().then((token) => {
            let menuItems = this.tabs.filter((item) => DuAuthenticationUtilities.IsInGroup(token, item.groupsAllowed || []));
            this.setState({ menuItems });
        });
    };

    render() {
        const { errorMessage, selectedTabIndex } = this.state;
        const { classes, isMasterDataInitialized } = this.props;

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <div>
                <DrcMain transparent className={classes.main}>
                    {this.state.menuItems.length > 0 && (
                        <DrcTabs
                            classes={{ tabItem: classes.tabItem, activeTabItem: classes.activeTabItem, indicator: classes.indicator }}
                            selectedMenuIndex={selectedTabIndex}
                            menuItems={this.state.menuItems}
                            centered
                            registerRoutes
                        ></DrcTabs>
                    )}
                    {this.renderPageWarning(errorMessage)}
                </DrcMain>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pageTitle: state.home.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(ChemicalApplication)));
