import { combineReducers } from 'redux';
import { homeReducer } from './Home.reducer';
import chemicalMaintenanceReducer from './ChemicalMaintenance.reducer';
import { holdPeriodReducer } from './HoldPeriod.reducer';
import { safetyHoldsReducer } from './safetyHolds.reducer';
import masterReducer from './masterReducer';
import ranchOverviewReducer from './RanchOverview.reducer';
import errorListReducer from './ErrorList.reducer';
import { localizeReducer } from 'react-localize-redux';
import {
    SERVICE_WORKER_UPDATED,
    SERVICE_WORKER_UPDATE
} from '../actions/actions';

const initialState = {
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SERVICE_WORKER_UPDATE:
            return {
                ...state,
                serviceWorkerUpdated: true,
                serviceWorkerRegistration: action.payload
            };
        case SERVICE_WORKER_UPDATED:
            return {
                ...state,
                serviceWorkerUpdated: false
            };
        default:
            break;
    }

    return state;
}

export default combineReducers({
    root: rootReducer,
    home: homeReducer,
    localize: localizeReducer,
    chemicalMaintenance: chemicalMaintenanceReducer,
    holdData: holdPeriodReducer,
    safetyHold: safetyHoldsReducer,
    masterReducer: masterReducer,
    ranchOverview: ranchOverviewReducer,
    errorListReducer: errorListReducer
});

