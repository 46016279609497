import { SET_ERROR_LIST } from '../constants/constant';
import DrcSortUtilities from '../helpers/DrcSortUtilities';

const initialState = {
    errorList: []
};

const errorListReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ERROR_LIST:
            var list = action.payload.map((item) => {
                return {
                    ...item
                };
            });
            return {
                ...state,
                errorList: DrcSortUtilities.SortArrayInDescendingOrder(list, 'ErrorDate')
            };

        default:
            return state;
    }
};

export default errorListReducer;
