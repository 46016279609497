import { FOOD_SAFETY_HOLD } from '../constants/constant';

export const safetyHoldsReducer = (safetyHold = [], action) => {
    switch (action.type) {
        case FOOD_SAFETY_HOLD:
            return action.payload;
        default:
            return safetyHold;
    }
};
