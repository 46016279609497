import React from 'react';
import { DrcButton } from 'driscolls-react-components';
import { withStyles } from '@material-ui/core/styles';

let styles = (theme) => ({
    holdBtn: {
        margin: '0 0 8px !important',
        padding: '6px 16px',
        [theme.darkTheme]: {
            border: '1px solid ' + theme.dark.accent.primary,
            backgroundColor: theme.dark.primary,
            '&:hover': {
                color: theme.dark.text.secondary,
                backgroundColor: theme.dark.secondary,
                border: '1px solid ' + theme.dark.accent.primary
            }
        }
    }
});

const RanchHoldButton = (props) => {
    const { classes, onClick } = props;
    return (
        <DrcButton isSecondary floatRight className={classes.holdBtn} onClick={onClick}>
            Apply Ranch Hold
        </DrcButton>
    );
};

export default withStyles(styles)(RanchHoldButton);
