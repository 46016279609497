import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { setIsAdmin } from '../actions/masterActions';
import { connect } from 'react-redux';

class LogOut extends Component {
    constructor(props) {
        super(props);
        this.props.auth.logout('/');
        this.props.setIsAdmin(false);
    }

    render() {
        return (
            <main>
                <h3>Please Wait... Logging you out shortly...</h3>
            </main>
        );
    }
}

export default withAuth(connect(null, { setIsAdmin })(LogOut));
