import {
    SET_MASTER_DATA_INITIALIZED,
    COMMODITY_TYPES,
    CHEMICAL_TYPES,
    SET_MASTER_DATA_REDIRECT_URL,
    SET_IS_ADMIN,
    SET_RANCHES,
    SET_COUNTRY_LIST,
    SET_PRODUCING_AREAS,
    SET_CLOSE_PRODUCING_AREA,
    SET_OPEN_PRODUCING_AREA
} from '../constants/constant';

export const setMasterDataInitialized = (isInitialized) => ({
    type: SET_MASTER_DATA_INITIALIZED,
    payload: isInitialized
});

export const setInitializeRedirectUrl = (redirectUrl) => ({
    type: SET_MASTER_DATA_REDIRECT_URL,
    payload: redirectUrl
});

export const setChemicalTypes = (data) => ({
    type: CHEMICAL_TYPES,
    payload: data
});

export const setCommodityTypes = () => ({
    type: COMMODITY_TYPES
});

export const setIsAdmin = (data) => ({
    type: SET_IS_ADMIN,
    payload: data
});

export const setRanches = (data) => ({
    type: SET_RANCHES,
    payload: data
});

export const setCountryList = (data) => ({
    type: SET_COUNTRY_LIST,
    payload: data
});
export const setProducingAreas = (data) => ({
    type: SET_PRODUCING_AREAS,
    payload: data
});

export const setOpenProducingArea = (data) => ({
    type: SET_OPEN_PRODUCING_AREA,
    payload: data
});

export const setCloseProducingArea = (data) => ({
    type: SET_CLOSE_PRODUCING_AREA,
    payload: data
});
