import { Middleware } from 'one-ring';

const chemicalDetailsMapping = [
    Middleware.CreateMapping('chemicalId', 'ChemicalId'),
    Middleware.CreateMapping('chemicalName', 'ChemicalName'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('chemicalTypes', 'ChemicalTypes'),
    Middleware.CreateMapping('commodities', 'Commodities'),
    Middleware.CreateMapping('chemicalHolds', 'ChemicalHolds'),
    Middleware.CreateMapping('chemicalCommodity', 'ChemicalCommodity'),
    Middleware.CreateMapping('commodityId', 'CommodityId'),
    Middleware.CreateMapping('inactiveSince', 'InactiveSince'),
    Middleware.CreateMapping('lastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('lastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateArrayMapping('Results'),
    Middleware.CreateArrayMapping('commoditiesAudit', 'CommoditiesAudit')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('chemicalDetails', 'GET')) {
        Middleware.RegisterMapping('chemicalDetails', 'GET', chemicalDetailsMapping);
        Middleware.RegisterMapping('chemicalDetails', 'POST', chemicalDetailsMapping);
    }
}

const chemicalDetailsGroup = {
    ConfigureMiddleWare
};

export default chemicalDetailsGroup;
