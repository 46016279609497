import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';

import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { DuDateUtilities } from 'driscolls-react-utilities';
import { DrcGrid, DrcTooltip } from 'driscolls-react-components';

let styles = (theme) => ({
    panel: {
        border: `0.5px solid ${theme.palette.grey}`,
        borderRadius: 2,
        paddingTop: 0
    },
    tabItem: {
        color: 'gray !important',
        marginTop: theme.spacing(1)
    },
    activeTabItem: {
        color: `#6F5091 !important`,
        borderBottom: `2px solid #6F5091`
    },
    selectionGrid: {
        paddingBottom: `${theme.spacing(1)} !important`
    },
    tabIcon: {
        paddingTop: theme.spacing(1)
    }
});

const InactivityHistory = (props) => {
    const { classes, isMasterDataInitialized, setPageTitle } = props;

    const cellFormatter = ({ value, row }) => {
        return (
            <DrcTooltip tipText={MasterDataUtilities.MapCommodity(value) || ''}>
                <span>{MasterDataUtilities.MapCommodity(value) || ''}</span>
            </DrcTooltip>
        );
    };

    const dateFormatter = ({ value, row }) => {
        return (
            <DrcTooltip tipText={DuDateUtilities.FormatDateFromIso(value) || ''}>
                <span>{DuDateUtilities.FormatDateFromIso(value) || ''}</span>
            </DrcTooltip>
        );
    };

    const dateTimeFormatter = ({ value, row }) => {
        return (
            <DrcTooltip tipText={DuDateUtilities.FormatDateTimeFromIso(value) || ''}>
                <span>{DuDateUtilities.FormatDateTimeFromIso(value) || ''}</span>
            </DrcTooltip>
        );
    };

    const columns = [
        {
            key: 'chemicalCommodity',
            name: 'Commodity Type',
            width: 200,
            formatter: cellFormatter
        },
        {
            key: 'inactiveSince',
            name: 'Inactive Since',
            width: 200,
            formatter: dateFormatter
        },
        {
            key: 'lastUpdatedBy',
            name: 'Last Updated By',
            width: 200,
            formatter: cellFormatter
        },
        {
            key: 'lastUpdatedDateTime',
            name: 'Last Updated Date Time',
            width: 200,
            formatter: dateTimeFormatter
        }
    ];

    useEffect(() => {
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
    }, [setPageTitle, isMasterDataInitialized]);

    return (
        <React.Fragment>
            <div className="col-xs-12">
                <h1 className={`${classes.title}`}>Commodity Inactivity History</h1>
                <DrcGrid columns={columns} rows={props.inactivityHistory} className={`${classes.grid}`} fullHeightOffset={10} />
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        chemical: state.chemicalMaintenance.chemical,
        inactivityHistory: state.chemicalMaintenance.inactivityHistory,
        isMasterDataInitialized: state.masterReducer.isInitialized
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(InactivityHistory)));
