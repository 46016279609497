import { Middleware } from 'one-ring';
import { DuDateUtilities } from 'driscolls-react-utilities';

const chemicalCommodityMapping = [
    Middleware.CreateMapping('Commodities', 'Commodities'),
    Middleware.CreateMapping('ChemicalId', 'ChemicalId'),
    Middleware.CreateMapping('TransactionInfo', 'TransactionInfo'),
    Middleware.CreateMapping('Username', 'Username'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('LastChangedDateTime', 'LastChangedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('ChemicalCommodityId', 'ChemicalCommodityId'),
    Middleware.CreateMapping('ChemicalCommodity', 'ChemicalCommodity'),
    Middleware.CreateMapping('IsActive', 'IsActive'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('chemicalCommodity', 'GET')) {
        Middleware.RegisterMapping('chemicalCommodity', 'GET', chemicalCommodityMapping);
        Middleware.RegisterMapping('chemicalCommodity', 'POST', chemicalCommodityMapping);
    }
}

const chemicalCommodityTypesGroup = {
    ConfigureMiddleWare
};

export default chemicalCommodityTypesGroup;
