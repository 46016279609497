import {
    SET_MASTER_DATA_INITIALIZED,
    COMMODITY_TYPES,
    CHEMICAL_TYPES,
    SET_MASTER_DATA_REDIRECT_URL,
    SET_IS_ADMIN,
    SET_RANCHES,
    SET_COUNTRY_LIST,
    SET_PRODUCING_AREAS,
    SET_OPEN_PRODUCING_AREA,
    SET_CLOSE_PRODUCING_AREA
} from '../constants/constant';
const initialState = {
    redirectUrl: '/App',
    isInitialized: false,
    isAdmin: false,
    chemicalTypeStatic: [],
    holdArea: [],
    activeIngrediantList: [],
    commodityTypes: [],
    chemicalTypes: [],
    ranches: [],
    countryList: [],
    producingAreas: []
};

const masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MASTER_DATA_INITIALIZED:
            return {
                ...state,
                isInitialized: action.payload
            };
        case SET_IS_ADMIN:
            return {
                ...state,
                isAdmin: action.payload
            };
        case SET_MASTER_DATA_REDIRECT_URL:
            return {
                ...state,
                redirectUrl: action.payload
            };
        case CHEMICAL_TYPES:
            let chemicalTypeOptions = action.payload.map((item) => {
                return {
                    label: item.ChemicalType,
                    value: item.ChemicalTypeId
                };
            });
            return Object.assign({}, state, {
                chemicalTypes: action.payload,
                chemicalTypeOptions
            });
        case COMMODITY_TYPES:
            let commodityTypes = [
                { chemicalCommodity: 'BLACK', chemicalCommodityId: 1, isActive: false },
                { chemicalCommodity: 'BLUE', chemicalCommodityId: 2, isActive: false },
                { chemicalCommodity: 'RASP', chemicalCommodityId: 3, isActive: false },
                { chemicalCommodity: 'STRAW', chemicalCommodityId: 4, isActive: false }
            ];
            let commodityTypeOptions = commodityTypes.map((item) => {
                return {
                    label: item.chemicalCommodity,
                    value: item.chemicalCommodityId,
                    isActive: item.isActive
                };
            });
            return Object.assign({}, state, {
                commodityTypes: action.payload,
                commodityTypeOptions
            });
        case SET_RANCHES:
            return { ...state, ranches: (action.payload || []).sort((a, b) => a.ranchNumber - b.ranchNumber) };
        case SET_PRODUCING_AREAS:
            //return { ...state, producingAreas: action.payload };
            return { ...state, producingAreas: [...action.payload] };
        case SET_CLOSE_PRODUCING_AREA:
            let index = state.producingAreas.findIndex((area) => area.producingArea === action.payload);
            let prodAreas = state.producingAreas;
            prodAreas[index].status = 'Closed';
            return Object.assign({}, state, { producingAreas: [...prodAreas] });
        case SET_OPEN_PRODUCING_AREA:
            let Index = state.producingAreas.findIndex((area) => area.producingArea === action.payload);
            let ProdAreas = state.producingAreas;
            ProdAreas[Index].status = 'Open';
            return Object.assign({}, state, { producingAreas: [...ProdAreas] });
        case SET_COUNTRY_LIST:
            let countryListOptions = action.payload.map((item) => {
                return {
                    label: item.areaCode,
                    value: item.id
                };
            });
            return { ...state, countryList: action.payload, countryListOptions };
        default:
            return state;
    }
};

export default masterReducer;
