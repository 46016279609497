import React from 'react';
import { Redirect } from 'react-router-dom';
import APIEndPoints from '../services/api';
import { Middleware } from 'one-ring';
import LoggingUtilities from './LoggingUtilities';
import { DrcIcons } from 'driscolls-react-components';

const DEFAULT_CACHE_DURATION_IN_MINUTES = 60;
const timeSpanInfoAlert = 7200000; // 2 hours
const timeSpanWarningAlert = 32400000; // 9 hours

let lastMasterDataLoadTime = null;
let hasLoggedMasterDataWarning = false;
let hasLoggedMasterDataInfo = false;
var redirectLocationReducerFunction = () => {};

const masterDataCalls = [
    {
        name: 'chemicalType',
        endpoint: APIEndPoints.CHEMICAL_TYPES,
        weight: 5,
        returnFunction: (data, props) => {
            props.setChemicalTypes(data.Results);
            // console.log(data);
        }
    },
    {
        name: 'ranches',
        endpoint: APIEndPoints.RANCHES,
        weight: 30,
        returnFunction: (data, props) => {
            props.setRanches(data.Results);
            // console.log(data);
        }
    },
    {
        name: 'chemicalList',
        endpoint: APIEndPoints.CHEMICAL_LIST,
        weight: 5,
        returnFunction: (data, props) => {
            props.setChemicalList(data.Results);
            // console.log(data);
        }
    },
    {
        name: 'countryList',
        endpoint: APIEndPoints.COUNTRY_LIST,
        weight: 5,
        returnFunction: (data, props) => {
            props.setCountryList(data.Results);
            // console.log(data);
        }
    }
];

const getMasterData = (props, token, statusUpdate, onSuccess, onError) => {
    var apiCalls = [];

    masterDataCalls.forEach((call) => {
        apiCalls.push(
            Middleware.AttachReturnFunction(Middleware.CreateSendCall(call.name, token, call.endpoint), call.weight, (data) => {
                call.returnFunction(data, props);
            })
        );
    });

    Middleware.SendMultiple('Initialize', apiCalls, statusUpdate)
        .then((message) => {
            lastMasterDataLoadTime = Date.now();
            onSuccess(message);
            hasLoggedMasterDataInfo = false;
            hasLoggedMasterDataWarning = false;
        })
        .catch((error) => {
            lastMasterDataLoadTime = Date.now();
            onError(error);
        });
};

var checkMasterData = (isMasterDataLoaded) => {
    if (!isMasterDataLoaded) {
        return false;
    }

    if (lastMasterDataLoadTime + timeSpanWarningAlert <= Date.now() && !hasLoggedMasterDataInfo) {
        hasLoggedMasterDataInfo = true;
        LoggingUtilities.Add(
            LoggingUtilities.CreateInfo(
                'MasterDataInit',
                'Master Data',
                "Master Data is outdated and hasn't been updated in a very long time. This can cause issues with missing items that may have been added throughout the day.",
                2,
                '/InitializeApplication/'
            )
        );
    }

    if (lastMasterDataLoadTime + timeSpanInfoAlert <= Date.now() && !hasLoggedMasterDataWarning) {
        hasLoggedMasterDataWarning = true;
        LoggingUtilities.Add(
            LoggingUtilities.CreateInfo(
                'MasterDataInit',
                'Master Data',
                "Master Data is a little stale and hasn't been updated in a while. This can cause issues with missing items that may have been added recently. It's a good idea to keep this up to date periodically.",
                3,
                '/InitializeApplication/'
            )
        );
    }

    return true;
};

var redirectToLoadMasterData = () => {
    redirectLocationReducerFunction(window.location.pathname);
    return <Redirect to="/InitializeApplication/" />;
};

var registerMasterDataReducerVariables = (setMasterDataRedirectLocation) => {
    redirectLocationReducerFunction = setMasterDataRedirectLocation;
    //setMasterDataInitialized = setMasterDataInitialized;
};

const mapCommodity = (name) => {
    switch (name) {
        case 'BLACK':
            return 'Blackberry';
        case 'BLUE':
            return 'Blueberry';
        case 'RASP':
            return 'Raspberry';
        case 'STRAW':
            return 'Strawberry';
        default:
            return name;
    }
};

const renderCommodityOptions = (commodity) => {
    if (commodity) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                {DrcIcons.GetSmallBerryIcon(commodity.value)}
                <label style={{ marginLeft: '5px' }}>{mapCommodity(commodity.label)}</label>
            </div>
        );
    }
};

const MasterDataUtilities = {
    Load: getMasterData,
    Check: checkMasterData,
    Redirect: redirectToLoadMasterData,
    Register: registerMasterDataReducerVariables,
    MapCommodity: mapCommodity,
    RenderCommodityOptions: renderCommodityOptions
};

export default MasterDataUtilities;
