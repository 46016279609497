//Admin
export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export const SET_WRITE_ACCESS = 'SET_WRITE_ACCESS';

//Master Data
export const SET_MASTER_DATA_INITIALIZED = 'SET_MASTER_DATA_INITIALIZED';
export const SET_MASTER_DATA_REDIRECT_URL = 'SET_MASTER_DATA_REDIRECT_URL';
export const SET_RANCHES = 'SET_RANCHES';
export const SET_COUNTRY_LIST = 'SET_COUNTRY_LIST';
export const SET_PRODUCING_AREAS = 'SET_PRODUCING_AREAS';
export const SET_OPEN_PRODUCING_AREA = 'SET_OPEN_PRODUCING_AREA';
export const SET_CLOSE_PRODUCING_AREA = 'SET_CLOSE_PRODUCING_AREA';
//Maintenance
//AI
export const CHEMICAL = 'CHEMICAL';
export const CHEMICAL_LIST = 'CHEMICAL_LIST';
export const CHEMICAL_DETAILS = 'CHEMICAL_DETAILS';
export const CLEAR_CHEMICAL_DETAILS = 'CLEAR_CHEMICAL_DETAILS';
export const COMMODITY_TYPES = 'COMMODITY_TYPES';
export const CHEMICAL_TYPES = 'CHEMICAL_TYPES';

//AI Map
export const ACTIVE_INGREDIENT_LIST = 'ACTIVE_INGREDIENT_LIST';
export const EDIT_ACTIVE_INGREDIENT = 'EDIT_ACTIVE_INGREDIENT';

//RanchOverview
export const RANCH = 'RANCH';
export const CLEAR_RANCH = 'CLEAR_RANCH';
export const SET_RANCH_DETAILS = 'SET_RANCH_DETAILS';
export const SET_HOLDS = 'SET_HOLDS';
export const SET_APPLICATIONS = 'SET_APPLICATIONS';
export const SET_BLOCK_LIST = 'SET_BLOCK_LIST';
export const SET_MRL_BLOCKS = 'SET_MRL_BLOCKS';
export const CLEAR_MRL_BLOCKS = 'CLEAR_MRL_BLOCKS';

//Holds
export const SET_RANCH_LEVEL_HOLD_DETAILS = 'SET_RANCH_LEVEL_HOLD_DETAILS';
export const CLEAR_HOLD_DETAILS = 'CLEAR_HOLD_DETAILS';
export const HOLD_PERIOD = 'HOLD_PERIOD';
export const ADD_HOLD_PERIOD = 'ADD_HOLD_PERIOD';
export const FOOD_SAFETY_HOLD = 'FOOD_SAFETY_HOLD';

//Country Restrictions
export const SET_COUNTRY_RESTRICTIONS = 'SET_COUNTRY_RESTRICTIONS';
export const SET_PUR_RESTRICTIONS = 'SET_PUR_RESTRICTIONS';

//errorList
export const SET_ERROR_LIST = 'SET_ERROR_LIST';
