import React, { useState, useEffect } from 'react';
import { Middleware } from 'one-ring';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import APIEndPoints from '../../services/api';
import { DrcPageWarning, DrcTooltip, DrcSelect, DrcDialog, DrcButton, DrcInput, DrcDateTimePicker } from 'driscolls-react-components';
import { DuAuthenticationUtilities, DuDateUtilities } from 'driscolls-react-utilities';
import { withAuth } from '@okta/okta-react';
import { setHolds, clearHoldDetails, setBlockList, setApplications, setRanchLevelHoldDetails } from '../../actions/ranchOverviewActions';
import RanchUtilities from '../../data/RanchUtilities';
import RanchHoldButton from '../../components/RanchOverview/Holds/RanchHoldButton';
import RanchHoldDialog from '../../components/RanchOverview/Holds/RanchHoldDialog';
import RefreshButton from '../../components/RanchOverview/Holds/RefreshButton';
import DrcPageDataMaintenance from '../../components/drc/DrcPageDataMaintenance';

const pageTitle = 'Inspection Holds';
const PAGE_TYPE = 'inspectionHold';

let styles = (theme) => ({
    grid: {
        padding: 0,
        margin: 0,
        '& div[class^="DrcPanel-"]': {
            border: `none`
        },
        '& .MuiButton-containedPrimary': {
            backgroundColor: theme.light.accent.opacity(0),
            border: '1px solid ' + theme.light.accent.primary,
            color: `${theme.light.accent.primary}`,
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: theme.light.accent.opacity(0.05),
                [theme.darkTheme]: {
                    border: '1px solid ' + theme.dark.accent.primary,
                    backgroundColor: theme.dark.primary,
                    '&:hover': {
                        color: theme.dark.text.secondary,
                        backgroundColor: theme.dark.secondary,
                        border: '1px solid ' + theme.dark.accent.primary
                    }
                }
            }
        }
    }
});

const adminGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || []);

const dateTimeFormatter = (value) => {
    return (
        <DrcTooltip tipText={value ? DuDateUtilities.ToPrettyDateTime(value) : ''}>
            <span>{value ? DuDateUtilities.ToPrettyDateTime(value) : ''}</span>
        </DrcTooltip>
    );
};

const cellFormatter = (value) => {
    return (
        <DrcTooltip tipText={value || ''}>
            <span>{value || ''}</span>
        </DrcTooltip>
    );
};

const InspectionHold = (props) => {
    const { classes } = props;

    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState('');
    const [isWritable, setIsWritable] = useState('');

    const [addDialog, setAddDialog] = useState(false);
    const [comment, setComment] = useState('');
    const [mrlBlock, setMrlBlock] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [ranchHoldDialogOpen, setRanchHoldDialogOpen] = useState(false);
    const holdName = 'Inspection Hold';
    const holdFlagType = 'InspectionHold';

    useEffect(() => {
        async function isUserWritable() {
            let token = await props.auth.getAccessToken();
            setIsWritable(DuAuthenticationUtilities.IsInGroup(token, adminGroups));
        }
        isUserWritable();
        setMinDate(new Date(new Date().setDate(new Date().getDate() - 89)));
        setMaxDate(new Date(new Date().setDate(new Date().getDate() + 89)));
        setStartDate(new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))); // set to start date of the week by default
        setEndDate(new Date(new Date().setDate(new Date().getDate() + 6 - new Date().getDay()))); //set to end date of the week by default
    }, [props.auth]);

    const defaultColumnProperties = {
        resizable: true
    };

    const columns = [
        { key: 'id', name: 'Transaction Id', isRequired: false, width: 0, isDisabled: true, isHidden: true },
        {
            key: 'mrlBlock',
            name: 'Block Name',
            selectOptions: props.blocks || [],
            isMulti: true,
            inputType: 'Select',
            isRequired: true,
            width: 100,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.mrlBlock)
        },
        {
            key: 'comments',
            name: 'Comment',
            isRequired: true,
            width: 150,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.comments)
        },
        {
            key: 'startDate',
            name: 'Start Date',
            isRequired: true,
            inputType: 'Date',
            defaultValue: new Date(),
            width: 180,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.startDate)
        },
        {
            key: 'endDate',
            name: 'End Date',
            isRequired: true,
            inputType: 'Date',
            defaultValue: new Date(),
            width: 180,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => cellFormatter(row.endDate)
        },
        {
            key: 'createdBy',
            name: 'Created By',
            isRequired: false,
            width: 180,
            isDisabled: true,
            isHidden: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.createdBy)
        },
        {
            key: 'createdDateTime',
            name: 'Created Date Time',
            isRequired: false,
            width: 180,
            isDisabled: true,
            isHidden: true,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.createdDateTime)
        },
        {
            key: 'modifiedBy',
            name: 'Modified By',
            isRequired: false,
            width: 180,
            isDisabled: true,
            isHidden: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.modifiedBy)
        },
        {
            key: 'modifiedDateTime',
            name: 'Modified Date Time',
            isRequired: false,
            width: 180,
            isDisabled: true,
            isHidden: true,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.modifiedDateTime)
        }
    ].map((c) => ({ ...c, ...defaultColumnProperties }));

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const blockExists = () => {
        let duplicateBlockValues = [];
        mrlBlock.forEach((el) => {
            if (RanchUtilities.CheckIfHoldsExists(el.value, startDate, endDate, props.inspectionHold)) {
                duplicateBlockValues.push(el.value);
            }
        });
        return duplicateBlockValues || [];
    };

    const handleAdd = () => {
        // check if start date, end date are equal
        if (
            new Date(DuDateUtilities.FormatDateTimeFromIso(new Date(startDate).toISOString())).getTime() >=
            new Date(DuDateUtilities.FormatDateTimeFromIso(new Date(endDate).toISOString())).getTime()
        ) {
            setOnError(true);
            showErrorMessage('End date time should always be greater than Start date time');
            return;
        }
        let duplicateBlocks = blockExists().toString();
        if (duplicateBlocks) {
            showErrorMessage(`${holdName} already exists for ${duplicateBlocks} blocks for seleted date range`);
            setOnError(true);
        } else {
            props.auth.getAccessToken().then((token) => {
                let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
                let date = new Date().toISOString();

                let payload = {};

                let ranchBlocks = mrlBlock.map((item) => {
                    return {
                        ranchNbr: props.ranch,
                        blockDesignator: item.value,
                        blockType: 'FOODSAFETY'
                    };
                });
                payload.comments = comment;
                payload.ranchBlocks = ranchBlocks;
                payload.holdFlagType = holdFlagType;
                payload.sourceBatchId = null;
                payload.sourceDocumentId = '';
                payload.isoStartDateTime = startDate.toISOString();
                payload.isoEndDateTime = endDate.toISOString();
                payload.transInfo = {
                    TransactionDateTime: date,
                    SourceSystem: 'FACTS',
                    SourceUser: loggedInUser
                };
                payload.companyId = 210;

                Middleware.Send('holdDetails', token, APIEndPoints.ADD_HOLD, 'POST', payload, { overrideResponseMapping: true })
                    .then((data) => {
                        if (data.status && data.status !== 201) {
                            showErrorMessage(data.message);
                            setOnError(true);
                        } else {
                            showErrorMessage(`${holdName} successfully added to system. Please allow a minute or two for the results to show below.`);
                            setOnError(false);
                            setAddDialog(false);
                            refreshGrid();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        showErrorMessage(`Failed to add ${holdName}!`);
                        setOnError(true);
                    });
            });
        }
    };
    const refreshGrid = () => {
        props.auth.getAccessToken().then((token) => {
            RanchUtilities.RefreshRanchData(
                token,
                props.ranch,
                props.setBlockList,
                props.setHolds,
                props.setApplications,
                props.setRanchLevelHoldDetails,
                (data) => {
                    console.log(data);
                },
                props.clearHoldDetails
            );
        });
    };
    const onDeleteConfirm = (val) => {
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            let delObj = {
                ranchBlocks: [
                    {
                        ranchNbr: props.ranch,
                        blockDesignator: val.mrlBlock,
                        blockType: 'FOODSAFETY',
                        sourceDocumentId: val.id
                    }
                ],
                holdFlagType: holdFlagType,
                isoStartDateTime: new Date(val.startDate).toISOString(),
                transInfo: {
                    TransactionDateTime: new Date().toISOString(),
                    SourceSystem: 'FACTS',
                    SourceUser: loggedInUser
                },
                companyId: 210
            };
            Middleware.Send('holdDetails', token, APIEndPoints.ADD_HOLD, 'DELETE', delObj, { overrideResponseMapping: true })
                .then((data) => {
                    if (data && data.status) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    }
                    showErrorMessage(`${holdName} successfully removed from system. Please allow a minute or two for the results to show below.`);
                    setOnError(false);
                    refreshGrid();
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage(`Failed to remove ${holdName}. Please try again.`);
                    setOnError(true);
                });
        });
    };

    const gridDataFormatter = (data) => {
        if (data.length === 0) {
            return [];
        }
        let { comments, startDate, endDate, mrlBlock } = data[0];

        let modifiedData = (mrlBlock || []).map((item) => {
            return {
                mrlBlock: item.value,
                comments,
                endDate: endDate,
                startDate: startDate,
                createdDateTime: new Date(),
                modifiedDateTime: new Date()
            };
        });

        return modifiedData;
    };

    const showAddDialog = () => {
        setMrlBlock([]);
        setComment('');
        setAddDialog(true);
        setIsValid(false);
    };

    const onAddDialogClose = () => {
        setAddDialog(false);
    };

    const handleOnChangeOfDialogFields = (event, field) => {
        if (field === 'startDate') {
            setStartDate(event);
            if (event && endDate && event.getTime() >= endDate.getTime()) {
                //add extra 1 min to end date
                setEndDate(new Date(event.getTime() + 60000));
            }
            setIsValid(event && endDate && mrlBlock ? mrlBlock.length : false);
        } else if (field === 'endDate') {
            setEndDate(event);
            setIsValid(event && startDate && mrlBlock ? mrlBlock.length : false);
        } else if (field === 'mrlBlock') {
            setMrlBlock(event);
            setIsValid(event ? event.length && endDate && startDate : false);
        } else if (field === 'comment') {
            setComment(event.target.value);
        }
    };

    const handleRanchHoldOpen = () => {
        setRanchHoldDialogOpen(true);
    };

    const handleRanchHoldClose = () => {
        setRanchHoldDialogOpen(false);
    };

    const handleRanchHoldSubmit = (holdObj) => {
        // check if start date, end date are equal
        if (
            new Date(DuDateUtilities.FormatDateTimeFromIso(new Date(holdObj.startDate).toISOString())).getTime() >=
            new Date(DuDateUtilities.FormatDateTimeFromIso(new Date(holdObj.endDate).toISOString())).getTime()
        ) {
            setOnError(true);
            showErrorMessage('End date time should always be greater than Start date time');
            return;
        }
        //check if there are duplicate holds with same time
        const isExists = RanchUtilities.CheckIfHoldsExists('ALL', holdObj.startDate, holdObj.endDate, props.inspectionHold);
        if (isExists) {
            setOnError(true);
            showErrorMessage(`You already have an ${holdName} in place for this ranch during this time frame`);
            return;
        }

        let payload = { ...holdObj };
        payload.holdFlagType = holdFlagType;
        props.auth.getAccessToken().then((token) => {
            RanchUtilities.AddHoldOnRanch(token, props.ranch, payload, onRanchHoldAddSuccess, onRanchHoldAddFailure);
            setRanchHoldDialogOpen(false);
        });
    };

    const onRanchHoldAddSuccess = (data) => {
        refreshGrid();
        setOnError(false);
        showErrorMessage(`${holdName} successfully added to system. Please allow a minute or two for the results to show below.`);
    };

    const onRanchHoldAddFailure = (data) => {
        setOnError(true);
        showErrorMessage(`Failed to add ${holdName} at Ranch level. Please try again.`);
    };

    return isWritable === '' ? null : (
        <div>
            {props.inspectionHold && (
                <React.Fragment>
                    <RefreshButton onClick={refreshGrid}></RefreshButton>
                    {ranchHoldDialogOpen && (
                        <RanchHoldDialog
                            holdName={holdName}
                            isOpen={ranchHoldDialogOpen}
                            onClose={handleRanchHoldClose}
                            onSubmit={handleRanchHoldSubmit}
                        ></RanchHoldDialog>
                    )}
                    <DrcPageDataMaintenance
                        hideCount={true}
                        className={`${classes.grid}`}
                        data={props.inspectionHold}
                        columns={columns}
                        pageType={PAGE_TYPE}
                        textOptions={{ PageTitle: '', AddBtn: 'Apply Block Hold' }}
                        settings={{
                            EnableExport: false,
                            ShowCount: false,
                            EnableAdd: isWritable,
                            EnableEdit: false,
                            EnableDelete: isWritable,
                            OverrideAdd: showAddDialog
                        }}
                        type={pageTitle}
                        customButtons={isWritable ? <RanchHoldButton onClick={handleRanchHoldOpen}></RanchHoldButton> : null}
                        fullWidth={true}
                        onAdd={handleAdd}
                        onDelete={onDeleteConfirm}
                        gridDataFormatter={gridDataFormatter}
                        pageSize={10}
                        paginator
                    ></DrcPageDataMaintenance>
                </React.Fragment>
            )}
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}
            {addDialog ? (
                <DrcDialog
                    open={addDialog}
                    title={`Add New ${pageTitle}`}
                    buttons={
                        <div>
                            <DrcButton isSecondary onClick={onAddDialogClose} floatRight>
                                Cancel
                            </DrcButton>
                            <DrcButton isPrimary onClick={handleAdd} floatRight disabled={!isValid}>
                                Save
                            </DrcButton>
                        </div>
                    }
                >
                    <div className="row" style={{ margin: '1rem', padding: ' 0 .5rem' }}>
                        <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                            <DrcSelect
                                label={'Mrl Block'}
                                required
                                value={mrlBlock}
                                isMulti
                                selectAllAllowed={(props.blocks || []).length ? true : false}
                                options={props.blocks || []}
                                onChange={(e) => handleOnChangeOfDialogFields(e, 'mrlBlock')}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                            <DrcInput
                                label={'Comments'}
                                value={comment}
                                onChange={(e) => handleOnChangeOfDialogFields(e, 'comment')}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 200 }}
                            />
                        </div>
                        <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                            <DrcDateTimePicker
                                label={'Start Date'}
                                required
                                value={startDate}
                                onChange={(e) => handleOnChangeOfDialogFields(e, 'startDate')}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                                maxDate={maxDate}
                                minDate={minDate}
                            />
                        </div>
                        <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                            <DrcDateTimePicker
                                label={'End Date'}
                                required
                                value={endDate}
                                onChange={(e) => handleOnChangeOfDialogFields(e, 'endDate')}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                                minDate={startDate}
                                maxDate={maxDate}
                            />
                        </div>
                    </div>
                </DrcDialog>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAdmin: state.masterReducer.isAdmin,
        ranch: state.ranchOverview.ranch,
        inspectionHold: state.ranchOverview.inspectionHold,
        ranchOverview: state.ranchOverview,
        blocks: state.ranchOverview.blocks
    };
};

const mapDispatchToProps = (dispatch) => ({
    setHolds: (data) => dispatch(setHolds(data)),
    clearHoldDetails: () => dispatch(clearHoldDetails()),
    setBlockList: (data) => dispatch(setBlockList(data)),
    setApplications: (data) => dispatch(setApplications(data)),
    setRanchLevelHoldDetails: (data) => dispatch(setRanchLevelHoldDetails(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(InspectionHold)));
