import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { setPageTitleAction } from '../../actions/actions';
import MasterDataUtilities from './../../data/MasterDataUtilities';

import { DrcPanel } from 'driscolls-react-components';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import PublicIcon from '@material-ui/icons/Public';
import RestoreIcon from '@material-ui/icons/Restore';

import ActiveIngredient from './ActiveIngredient';
import EndOfSeason from './EndOfSeason';
import Countries from './Countries';

import { DrcTabs } from 'driscolls-react-components';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import { withAuth } from '@okta/okta-react';
import MrlBlock from './MrlBlock';
import DocumentReprocess from './DocumentReprocess';

const pageTitle = 'Maintenance';

let styles = (theme) => ({
    panel: {
        border: `none`,
        marginTop: `1rem`,
        borderRadius: 2,
        boxShadow: `0 2px 4px 0 #C3C3C3`,
        paddingTop: 0
    },
    tabItem: {
        marginTop: theme.spacing(1.5)
    },
    tabIcon: {
        paddingTop: theme.spacing(1),
        fontSize: 24,
        transform: 'translateY(4px)'
    }
});

const adminGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || []);

const Maintenance = (props) => {
    const { classes, isMasterDataInitialized, setPageTitle } = props;
    const [menuItems, setMenuItems] = useState([]);

    const tabs = [
        // {
        //     tabName: (
        //         <div>
        //             <span>
        //                 <GroupWorkIcon className={`${classes.tabIcon}`} />
        //             </span>
        //             <span>Active Ingredients</span>
        //         </div>
        //     ),
        //     tabIndex: 0,
        //     component: Chemical,
        //     route: `/AI`,
        //     isDefault: true,
        //     groupsAllowed: adminGroups
        // },
        {
            tabName: (
                <div>
                    <span>
                        <AccountTreeIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span>Active Ingredients Map</span>
                </div>
            ),
            tabIndex: 0,
            component: ActiveIngredient,
            route: `/AIMap`,
            groupsAllowed: adminGroups
        },
        {
            tabName: (
                <div>
                    <span>
                        <PublicIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span>Countries</span>
                </div>
            ),
            tabIndex: 1,
            component: Countries,
            route: `/Countries`,
            groupsAllowed: adminGroups
        },
        {
            tabName: (
                <div>
                    <span>
                        <WbSunnyIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span>End of Season</span>
                </div>
            ),
            tabIndex: 2,
            component: EndOfSeason,
            route: `/EndOfSeason`,
            isDefault: props.isAdmin ? false : true,
            groupsAllowed: adminGroups
        },
        {
            tabName: (
                <div>
                    <span>
                        <ViewColumnIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span>Mrl Blocks</span>
                </div>
            ),
            tabIndex: 3,
            component: MrlBlock,
            route: `/MrlBlocks`,
            groupsAllowed: adminGroups
        },
        {
            tabName: (
                <div>
                    <span>
                        <RestoreIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span>Doc Reprocess</span>
                </div>
            ),
            tabIndex: 4,
            component: DocumentReprocess,
            route: `/DocumentReprocess`,
            groupsAllowed: adminGroups
        }
    ];

    useEffect(() => {
        setPageTitle(pageTitle);
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
    }, [setPageTitle, isMasterDataInitialized]);

    const assignMenuItemsForUser = () => {
        props.auth.getAccessToken().then((token) => {
            const items = tabs.filter((item) => DuAuthenticationUtilities.IsInGroup(token, item.groupsAllowed || []));
            setMenuItems(items);
        });
    };
    useEffect(assignMenuItemsForUser, []);

    return (
        menuItems.length > 0 && (
            <DrcPanel className={`${classes.panel}`} maxWidth="100%">
                <DrcTabs classes={{ tabItem: classes.tabItem }} menuItems={menuItems} centered registerRoutes></DrcTabs>
            </DrcPanel>
        )
    );
};

const mapStateToProps = (state) => {
    return {
        chemicalList: state.chemicalMaintenance.chemicalList,
        isAdmin: state.masterReducer.isAdmin,
        isMasterDataInitialized: state.masterReducer.isInitialized
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(Maintenance)));
