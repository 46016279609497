import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../../actions/actions';
import { setCountryList } from '../../actions/masterActions';
import MasterDataUtilities from './../../data/MasterDataUtilities';
import { Middleware } from 'one-ring';
import { DrcButton, DrcInput, DrcDialog, DrcTooltip, DrcPageWarning, DrcLegend, DrcTabs, DrcSelect } from 'driscolls-react-components';
import { DuAuthenticationUtilities, DuDateUtilities } from 'driscolls-react-utilities';
import APIEndPoints from '../../services/api';
import DrcPageDataMaintenance from '../../components/drc/DrcPageDataMaintenance';
import DrcDataGrid from '../../components/drc/DrcDataGrid';
import GridStyles from '../../styles/gridStyles';

let styles = (theme) => ({
    gridStyles: GridStyles.styles(theme, '260px'),
    input: {
        marginTop: theme.spacing(3)
    },
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '100%',
            width: '100%'
        }
    },
    alertDialog: {
        '& .MuiDialog-paper': {
            maxWidth: '80%',
            width: '80%'
        }
    }
});

const pageTitle = 'Country Maintenance';
const PAGE_TYPE = 'countryList';

const adminGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || []);

const Countries = (props) => {
    const { classes, isMasterDataInitialized } = props;
    const [isWritable, setIsWritable] = useState('');
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [showAddDialog, setAddDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [invalidISOCode, setInvalidISOCode] = useState(false);
    const [invalidName, setInvalidName] = useState(false);
    const [deletingRow, setDeletingRow] = useState(null);
    const [deleteDialogVisibility, setDeleteDialogVisibility] = useState(false);
    const [countryRestrictions, setCountryRestrictions] = useState([]);
    const [purRestrictions, setPurRestrictions] = useState([]);
    const [value, setValue] = useState(0);
    const [type, setType] = useState({ label: 'Country', value: 'Country' });
    const [entity, setEntity] = useState('');
    const [countryAndUnionList, setCountryAndUnionList] = useState([]);

    const cellFormatter = (value) => {
        return (
            <DrcTooltip tipText={value || ''}>
                <span>{value || ''}</span>
            </DrcTooltip>
        );
    };

    const dateTimeFormatter = ({ value }) => {
        return (
            <DrcTooltip tipText={DuDateUtilities.ToPrettyDateTime(value) || ''}>
                <span>{DuDateUtilities.ToPrettyDateTime(value) || ''}</span>
            </DrcTooltip>
        );
    };

    const dateFormatter = (value) => {
        var date = value || '';
        if (date.includes('T')) {
            date = date.split('T')[0];
        } else if (date.includes(' ')) {
            date = date.split(' ')[0];
        }
        date = DuDateUtilities.ToPrettyDate(date);
        return (
            <DrcTooltip tipText={date}>
                <span>{date}</span>
            </DrcTooltip>
        );
    };

    const columns = [
        { key: 'id', name: 'Transaction Id', isRequired: false, width: 0, isDisabled: true, isHidden: true },
        {
            key: 'areaCode',
            name: 'Country ISO Code',
            isRequired: false,
            width: 130,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.areaCode?.toUpperCase())
        },
        { key: 'abbreviation', name: 'Name', isRequired: false, width: 130, filter: true, columnTemplate: (row) => cellFormatter(row.abbreviation) },
        { key: 'createdBy', name: 'Created By', isRequired: false, width: 180, filter: true, columnTemplate: (row) => cellFormatter(row.createdBy) },
        {
            key: 'createdAt',
            name: 'Created Date',
            isRequired: false,
            width: 180,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateFormatter(row.createdAt)
        },
        {
            key: 'modifiedBy',
            name: 'Modified By',
            isRequired: false,
            width: 180,
            formatter: cellFormatter,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.modifiedBy)
        },
        {
            key: 'modifiedAt',
            name: 'Modified Date',
            isRequired: false,
            width: 180,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateFormatter(row.modifiedAt)
        }
    ];

    const holdColumns = [
        { key: 'id', name: 'Transaction Id', isRequired: false, width: 0, isDisabled: true, isHidden: true },
        {
            key: 'AreaCode',
            name: 'Country ISO Code',
            isRequired: false,
            width: '25%',
            isDisabled: true,
            columnTemplate: (row) => cellFormatter(row.AreaCode)
        },
        {
            key: 'Abbreviation',
            name: 'Name',
            isRequired: false,
            width: '25%',
            isDisabled: true,
            columnTemplate: (row) => cellFormatter(row.Abbreviation)
        },
        {
            key: 'MrlBlock',
            name: 'Mrl Block',
            isRequired: false,
            width: '25%',
            isDisabled: true,
            columnTemplate: (row) => cellFormatter(row.MrlBlock)
        },
        {
            key: 'RanchNumber',
            name: 'Ranch Number',
            isRequired: false,
            width: '25%',
            isDisabled: true,
            columnTemplate: (row) => cellFormatter(row.RanchNumber)
        }
    ];

    useEffect(() => {
        props.setPageTitle(pageTitle);

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }
    }, [props, isMasterDataInitialized]);

    const isUserWritable = () => {
        props.auth.getAccessToken().then((token) => {
            setIsWritable(DuAuthenticationUtilities.IsInGroup(token, adminGroups));
        });
    };
    useEffect(isUserWritable, []);

    const refreshList = () => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.COUNTRY_LIST, 'GET').then((data) => {
                if (data && data.status) {
                    showErrorMessage('Failed to fetch country list');
                    setOnError(true);
                }
                props.setCountryList(data.Results);
            });
        });
    };

    const getCountryAndUnionList = () => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                PAGE_TYPE,
                token,
                APIEndPoints.GET_COUNTRY_LIST,
                'GET',
                {},
                {
                    overrideResponseMapping: true,
                    overrideRequestMapping: true
                }
            ).then((data) => {
                if (data && data.status) {
                    showErrorMessage('Failed to fetch country list');
                    setOnError(true);
                }
                const countries = (data?.country || [])
                    .filter((item) => {
                        return item;
                    })
                    .map((item) => {
                        return {
                            label: item.CountryCode + ' - ' + item.CountryName,
                            value: item.CountryCode,
                            type: 'Country',
                            country: item.CountryName
                        };
                    });
                const unions = (data?.union || [])
                    .filter((item) => {
                        return item;
                    })
                    .map((item) => {
                        return { label: item, value: item, type: 'Union', union: item };
                    });
                setCountryAndUnionList(countries.concat(unions));
            });
        });
    };

    useEffect(refreshList, []);
    useEffect(getCountryAndUnionList, []);

    const openAddDialog = () => {
        setName('');
        setCode('');
        setType({ label: 'Country', value: 'Country' });
        setEntity('');
        setAddDialog(true);
    };

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const countryExists = () => {
        let value = '';

        let found = props.countryList.find((record) => record.areaCode.toLowerCase() === code.toLowerCase());
        if (found) return (value = 'country code');

        found = props.countryList.find((record) => record.abbreviation.toLowerCase() === name.toLowerCase());
        if (found) return (value = 'name');

        return value;
    };

    const onAdd = () => {
        if (name.length < 4) {
            showErrorMessage('Minimum characters for the name is 4');
            setOnError(true);
            return;
        }
        if (code.length < 3) {
            showErrorMessage('Minimum characters for the iso code is 3');
            setOnError(true);
            return;
        }
        let value = countryExists();
        if (value) {
            showErrorMessage(`Country already exists with same ${value}`);
            setOnError(true);
            return;
        }
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';

            let payload = {};

            payload.id = null;
            payload.type = type.value;
            payload.areaCode = code?.toUpperCase();
            payload.abbreviation = name;
            payload.createdBy = loggedInUser;
            payload.isActive = null;
            payload.modifiedBy = loggedInUser;
            //https://driscollsit.atlassian.net/browse/FE-175
            //Added additional header to invalidate cache
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.COUNTRY_LIST, 'POST', payload, { overrideResponseMapping: true }, null, 0, {
                invalidate: 'invalidate'
            })
                .then((data) => {
                    if (data && data.status) {
                        showErrorMessage('Failed to add country to the list');
                        setOnError(true);
                        return;
                    }
                    setAddDialog(false);
                    showErrorMessage('Added country to the list successfully');
                    setOnError(false);
                    refreshList();
                    getCountryAndUnionList();
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage('Failed to add country to the list');
                    setOnError(true);
                });
        });
    };

    const onDelete = () => {
        let row = deletingRow;
        //Remove below lines once API is ready
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';

            let payload = {
                HoldAreaId: row.id,
                HoldArea: row.areaCode,
                TransInfo: { TransactionDateTime: new Date().toISOString(), SourceSystem: 'FACTS', SourceUser: loggedInUser },
                Type:row.type
            };

            Middleware.Send(PAGE_TYPE, token, APIEndPoints.DELETE_COUNTRY_HOLD, 'DELETE', payload, {
                overrideRequestMapping: true,
                overrideResponseMapping: true
            })
                .then((data) => {
                    setDeleteDialogVisibility(false);
                    showErrorMessage(`Deleted Country and Hold for ${row.areaCode}`);
                    setOnError(false);
                    refreshList();
                    getCountryAndUnionList();
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage(`Failed to Delete Country!`);
                    setOnError(true);
                    setDeleteDialogVisibility(false);
                });
        });
    };

    const handleChangeOfFields = (event, field) => {
        let allowedCodeCharacters = RegExp(/^[a-zA-Z]*$/);

        let value = event.target.value.trim();
        let nameValue = event.target.value;

        if (field === 'isoCode') {
            setCode(value.substring(0, 3));
            if (!allowedCodeCharacters.test(value.substring(0, 3))) {
                setIsValid(false);
                setInvalidISOCode(true);
            } else {
                setInvalidISOCode(false);
                setIsValid(value && name);
            }
        } else if (field === 'name') {
            setName(nameValue.substring(0, 255));
            validateName(nameValue);
        }
    };

    const validateName = (nameValue, tempCode) => {
        let allowedNameCharacters = RegExp(/^[a-zA-Z'-/\s]*$/);
        if (!allowedNameCharacters.test(nameValue.substring(0, 255))) {
            setIsValid(false);
            setInvalidName(true);
        } else {
            setInvalidName(false);
            setIsValid((code || tempCode) && nameValue && !invalidISOCode);
        }
    };

    const showDeleteDialog = async (row) => {
        let token = await props.auth.getAccessToken();
        try {
            let response = await Middleware.Send(
                'editChemical',
                token,
                APIEndPoints.GET_ACTIVE_RESTRICTIONS_FROM_COUNTRY(row.id),
                'GET',
                {},
                {
                    overrideRequestMapping: true,
                    overrideResponseMapping: true
                }
            );
            setCountryRestrictions(response?.CountryRestrictions || []);
            setPurRestrictions(response?.PurRestrictions || []);
            setDeletingRow(row);
            setDeleteDialogVisibility(true);
        } catch (error) {}
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && children}
            </div>
        );
    }

    const countryRestrictionsTab = () => {
        return countryRestrictions.length > 0 ? (
            <div className="row">
                <DrcLegend>Active Holds</DrcLegend>
                <div style={{ width: '100%' }}>
                    <DrcDataGrid
                        rows={countryRestrictions}
                        columns={holdColumns.map((c) => {
                            return { ...c, filter: false };
                        })}
                        hideCount={true}
                    />
                </div>
            </div>
        ) : (
            <span> 'There is no active holds for this country'</span>
        );
    };

    const handleMenuItemChange = (index) => {
        setValue(index);
    };

    const handleOnChangeOfDialogFields = (event, type) => {
        if (type === 'type') {
            setType(event);
            setEntity('');
            setName('');
        } else if (type === 'Country') {
            setEntity(event);
            setCode(event.value.substring(0, 3));
            setName(event.country);
            validateName(event.country, event.value.substring(0, 3));
        } else if (type === 'Union') {
            setCode(event.value.substring(0, 3));
            setEntity(event);
        }
    };

    const allGroups = (window.config.OKTA_ADMIN_GROUPS || [])
        .concat(window.config.OKTA_REGULAR_GROUPS || [], window.config.OKTA_READ_ONLY_GROUPS || [])
        .concat(window.config.OKTA_DC_USER_GROUPS || []);

    const tabs = [
        {
            tabName: (
                <div>
                    <span>Country</span>
                </div>
            ),
            tabIndex: 0,
            isDefault: true,
            groupsAllowed: allGroups
        },
        {
            tabName: (
                <div>
                    <span>PUR</span>
                </div>
            ),
            tabIndex: 1,
            groupsAllowed: allGroups
        }
    ];

    return (
        <div>
            <DrcPageDataMaintenance
                hideCount={true}
                className={`${classes.grid}`}
                columns={columns}
                fullWidth={true}
                data={props.countryList}
                type={''}
                textOptions={{ PageTitle: 'Countries', AddBtn: 'Add Country' }}
                settings={{
                    ...(window?.config?.isMaintenanceCountryDeleteEnabled ? {} : { ActionColumnSetting: 0 }),
                    EnableExport: false,
                    EnableAdd: props.isAdmin,
                    EnableEdit: false,
                    EnableDelete: props.isAdmin && window?.config?.isMaintenanceCountryDeleteEnabled,
                    OverrideAdd: openAddDialog,
                    OverrideDelete: showDeleteDialog
                }}
                onAdd={onAdd}
                pageSize={25}
                paginator
            />

            {showAddDialog ? (
                <DrcDialog
                    open={showAddDialog}
                    title={'Add new Country'}
                    buttons={
                        <div>
                            <DrcButton isPrimary onClick={onAdd} disabled={!isValid}>
                                Yes, we're good
                            </DrcButton>
                            <DrcButton
                                isSecondary
                                onClick={() => {
                                    setAddDialog(false);
                                    setCode('');
                                    setName('');
                                }}
                            >
                                Cancel
                            </DrcButton>
                        </div>
                    }
                >
                    <DrcSelect
                        floatRight
                        label={'Type'}
                        required
                        value={type}
                        options={[
                            { label: 'Country', value: 'Country' },
                            { label: 'Union', value: 'Union' }
                        ]}
                        onChange={(e) => {
                            handleOnChangeOfDialogFields(e, 'type');
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <DrcSelect
                        floatRight
                        label={type.value == 'Country' ? 'Select Country' : 'Select Union'}
                        required
                        value={entity}
                        options={countryAndUnionList.filter((item) => {
                            return item.type === type?.value;
                        })}
                        onChange={(e) => {
                            handleOnChangeOfDialogFields(e, type.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                    {/* <DrcInput
                        label={'Country ISO Code'}
                        value={code}
                        placeholder={'Please enter a code'}
                        onChange={(e) => {
                            handleChangeOfFields(e, 'isoCode');
                        }}
                        required
                        helperText={invalidISOCode ? 'Please enter valid ISO Code' : ''}
                        inputProps={{ minLength: 3, maxLength: 3, style: { textTransform: 'uppercase' } }}
                    ></DrcInput> */}
                    <DrcInput
                        label={'Name'}
                        value={name}
                        placeholder={'Please enter a Name'}
                        onChange={(e) => {
                            handleChangeOfFields(e, 'name');
                        }}
                        required
                        helperText={invalidName ? 'Please enter valid name' : ''}
                        inputProps={{ minLength: 4, maxLength: 255 }}
                    ></DrcInput>
                </DrcDialog>
            ) : null}
            {deleteDialogVisibility ? (
                <DrcDialog
                    className={classes.alertDialog}
                    maxWidth={'100%'}
                    open={deleteDialogVisibility}
                    title={'Delete Country'}
                    buttons={
                        <div>
                            <DrcButton isPrimary onClick={onDelete}>
                                Yes, we're good
                            </DrcButton>
                            <DrcButton
                                isSecondary
                                onClick={() => {
                                    setValue(0);
                                    setDeleteDialogVisibility(false);
                                }}
                            >
                                Cancel
                            </DrcButton>
                        </div>
                    }
                >
                    <div className="col">
                        <div className="row">
                            <h3>Do you want to delete the country {`${deletingRow.areaCode}`} ?</h3>
                        </div>
                        <div className="row">
                            <div style={{ flex: 1, flexDirection: 'column' }}>
                                <DrcLegend>Active Holds</DrcLegend>
                                <DrcTabs
                                    simple
                                    handleMenuItemChange={handleMenuItemChange}
                                    classes={{
                                        root: classes.tabItem,
                                        tabItem: `${classes.tabItem} ${classes.tabItemButton}`,
                                        activeTabItem: classes.activeTabItem
                                    }}
                                    menuItems={tabs}
                                ></DrcTabs>

                                <TabPanel value={value} index={0}>
                                    {countryRestrictions.length > 0 ? (
                                        <div className="row">
                                            <div style={{ width: '100%' }}>
                                                <DrcDataGrid
                                                    hideCount={false}
                                                    showReport={true}
                                                    pageSize={10}
                                                    paginator
                                                    totalRecords={countryRestrictions.length}
                                                    height="200px"
                                                    rows={countryRestrictions}
                                                    columns={holdColumns.map((c) => {
                                                        return { ...c, filter: false };
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <span> 'There is no active holds for this country'</span>
                                    )}
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    {purRestrictions.length > 0 ? (
                                        <div className="row">
                                            <div style={{ width: '100%' }}>
                                                <DrcDataGrid
                                                    gridStyles={classes.gridStyles}
                                                    hideCount={false}
                                                    showReport={true}
                                                    pageSize={10}
                                                    paginator
                                                    totalRecords={purRestrictions.length}
                                                    height={200}
                                                    rows={purRestrictions}
                                                    columns={holdColumns.map((c) => {
                                                        return { ...c, filter: false };
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <span> 'There is no active holds for this PUR'</span>
                                    )}
                                </TabPanel>
                            </div>
                        </div>
                    </div>
                </DrcDialog>
            ) : null}
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        countryList: state.masterReducer.countryList,
        countryListOptions: state.masterReducer.countryListOptions,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        isAdmin: state.masterReducer.isAdmin
    };
};
const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setCountryList: (countryData) => dispatch(setCountryList(countryData))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(Countries)));
