import React, { useState, useEffect, useCallback } from 'react';
import { DrcGrid, DrcInput, DrcDialog, DrcButton, DrcPageWarning, DrcSelect, DrcBerrySelect, DrcTooltip } from 'driscolls-react-components';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withAuth } from '@okta/okta-react';
import { setProducingAreas, setOpenProducingArea, setCloseProducingArea } from '../../actions/masterActions';
import { setPageTitleAction } from '../../actions/actions';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { Middleware } from 'one-ring';
import APIEndPoints from '../../services/api';
import HoldAppliedIcon from '@material-ui/icons/CheckCircleOutline';
import HoldNotAppliedIcon from '@material-ui/icons/HighlightOff';
import { Toolbar, Data } from 'react-data-grid-addons';
import RanchUtilities from '../../data/RanchUtilities';
import RefreshButton from '../../components/RanchOverview/Holds/RefreshButton';
const selectors = Data.Selectors;

let styles = (theme) => ({
    holdIcons: {}
});

const PAGE_TYPE = 'producingAreas';
const PAGE_TITLE = 'End of Season Maintenance';

const EndOfSeason = (props) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState('');
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [currentProducingArea, setCurrentProducingArea] = useState(null);
    const [currentProducingAreaOption, setCurrentProducingAreaOption] = useState(null);
    const [comment, setComment] = useState('');
    const [warning, setWarning] = useState(false);
    const [ranchList, setRanchList] = useState([]);
    const [filters, setFilters] = useState({});
    const [allRanchIsHold, setAllRanchIsHold] = useState(false);
    const [commodity, setCommodity] = useState(null);

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const getProducingAreaData = useCallback(() => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.END_OF_SEASON_HOLD, 'GET')
                .then((results) => {
                    if (results && results.Status) {
                        showErrorMessage(results.ErrorMessage);
                        setOnError(true);
                        return;
                    }
                    props.setProducingAreas(results.data);
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage('Failed to fetch Producing Area details. Please try again.');
                    setOnError(true);
                });
        });
    }, [props]);

    const onLoad = () => {
        const { isMasterDataInitialized } = props;

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        props.setPageTitle(PAGE_TITLE);

        if (!props.producingAreas.length) {
            getProducingAreaData();
        }
    };

    useEffect(onLoad, []);

    const filteredRows = getRows(ranchList, filters);

    function getRows(rows, filters) {
        return selectors.getRows({ rows, filters });
    }

    const handleFilterChange = (filter) => (filters) => {
        const newFilters = { ...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        return newFilters;
    };

    const cellFormatter = ({ value, row }) => {
        return (
            <DrcTooltip tipText={value || ''}>
                <span>{value || ''}</span>
            </DrcTooltip>
        );
    };

    const actionFormatter = ({ row, value }) => {
        return <DrcButton onClick={() => handleRanchChange(row)}>{!row.isHoldApplied ? `Apply Hold` : `Remove`}</DrcButton>;
    };

    const statusFormatter = ({ row, value }) => {
        return !!row.isHoldApplied ? ( //TODO: Need to connect this to valid data
            <DrcTooltip tipText="Hold Applied" className={props.classes.holdIcons}>
                <HoldAppliedIcon />
            </DrcTooltip>
        ) : (
            <DrcTooltip tipText="No Hold Applied" className={props.classes.holdIcons}>
                <HoldNotAppliedIcon />
            </DrcTooltip>
        );
    };

    const columns = [
        {
            key: 'overallStatus',
            name: 'Action',
            width: 100,
            formatter: actionFormatter,
            frozen: true
        },
        {
            key: 'isHoldApplied',
            name: 'Hold Applied',
            width: 120,
            formatter: statusFormatter,
            filterable: false
        },
        {
            key: 'ranchNumber',
            name: 'Ranch Number',
            width: 120,
            filterable: true,
            formatter: cellFormatter
        },
        {
            key: 'ranchName',
            name: 'Ranch Name',
            width: 220,
            filterable: true,
            formatter: cellFormatter
        },
        {
            key: 'createdBy',
            name: 'Created By',
            width: 180,
            filterable: true,
            isDisabled: true,
            formatter: cellFormatter
        },
        {
            key: 'createdDateTime',
            name: 'Created Date Time',
            width: 180,
            filterable: true,
            isDisabled: true,
            formatter: cellFormatter
        },
        {
            key: 'modifiedBy',
            name: 'Modified By',
            width: 180,
            filterable: true,
            isDisabled: true,
            formatter: cellFormatter
        },
        {
            key: 'modifiedDateTime',
            name: 'Modified Date Time',
            width: 180,
            filterable: true,
            isDisabled: true,
            formatter: cellFormatter
        },
        {
            key: 'sourceDocumentId',
            name: 'Source Document Id',
            width: 0,
            filterable: false
        },
        {
            key: 'startDate',
            name: 'Start Date',
            width: 0,
            filterable: false
        }
    ];

    const getRanchHolds = (producingArea, commodityId) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                'producingAreaEndOfSeasonHolds',
                token,
                APIEndPoints.PRODUCING_AREA_END_OF_SEASON_HOLD(producingArea, commodityId),
                'GET',
                {},
                null
            ).then((data) => {
                if (data && data.Status) {
                    showErrorMessage(data.ErrorMessage);
                    setOnError(true);
                    return;
                }
                //Get latest status for the ranches and make a nice look list
                let activeHolds = (data.Results || []).filter((el) => el.EndOfSeasonHold);
                let ranches = props.ranches.filter((ranch) => {
                    return ranch.producingArea === producingArea && ranch.overallStatus === 'Open' && ranch.commodityId === commodityId;
                });
                let ranchesWithHolds = ranches.map((ranch) => {
                    let record = activeHolds.find((el) => el.RanchNumber === ranch.ranchNumber);
                    return {
                        ...ranch,
                        isHoldApplied: (record || {}).EndOfSeasonHold,
                        sourceDocumentId: (record || {}).SourceDocumentId,
                        startDate: (record || {}).StartDate,
                        createdBy: (record || {}).CreatedBy,
                        createdDateTime: (record || {}).CreatedDateTime,
                        modifiedBy: (record || {}).ModifiedBy,
                        modifiedDateTime: (record || {}).ModifiedDateTime
                    };
                });
                setRanchList(ranchesWithHolds);
                // if all ranches are on hold
                setAllRanchIsHold((ranchesWithHolds || []).every((el) => el.isHoldApplied));
            });
        });
    };

    const handleRanchChange = (row) => {
        props.auth.getAccessToken().then((token) => {
            let holdObj = {
                startDate: new Date().toISOString(),
                comment: 'End of Season hold applied',
                holdFlagType: 'EndOfSeasonHold'
            };
            if (row.isHoldApplied) {
                //remove applied hold
                holdObj.sourceDocumentId = row.sourceDocumentId;
                holdObj.startDate = row.startDate;
                holdObj.producingArea = row.producingArea;

                RanchUtilities.RemoveEndOfSeasonHold(
                    token,
                    row['ranchNumber'],
                    holdObj,
                    (data) => {
                        if (data && data.Status) {
                            showErrorMessage(data.ErrorMessage);
                            setOnError(true);
                            return;
                        }
                        showErrorMessage(
                            `End of Season successfully removed for '${row['ranchNumber']}'. Please allow a minute or two for the results to show below!`
                        );
                        setOnError(false);
                        getRanchHolds(row.producingArea, row.commodityId);
                    },
                    (error) => {
                        showErrorMessage('Failed to remove hold for Ranch.');
                        setOnError(true);
                        console.log('Error', error);
                    }
                );
            } else {
                //apply hold
                RanchUtilities.AddEndOfSeasonHoldOnRanch(
                    token,
                    row['ranchNumber'],
                    holdObj,
                    (data) => {
                        if (data && data.Status) {
                            showErrorMessage(data.ErrorMessage);
                            setOnError(true);
                            return;
                        }
                        showErrorMessage(
                            `End of Season successfully added for '${row['ranchNumber']}'. Please allow a minute or two for the results to show below!`
                        );
                        setOnError(false);
                        //refresh current producing area hold details
                        getRanchHolds(row.producingArea, row.commodityId);
                    },
                    (error) => {
                        showErrorMessage('Failed to add hold for Ranch.');
                        setOnError(true);
                        console.log('Error', error);
                    }
                );
            }
        });
    };

    const handleHoldConfirm = () => {
        // we can change this as and when we find a better solution i.e after we have an API to update the data
        var el = document.querySelector('.react-grid-Canvas');
        if (!el) {
            return;
        }

        var left = el.scrollLeft;
        var top = el.scrollTop;
        el.scroll(left + 1, top + 1);
        el.scroll(left - 1, top - 1);
        el.scroll(left, top);
        setConfirmDialog(false);
        // refresh prod areas and ranches
        setWarning(true);
        // killing the snackbar!!
        setTimeout(() => {
            setWarning(false);
        }, 3000);
    };

    const onCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleProducingAreaHold = () => {
        props.auth.getAccessToken().then((token) => {
            let date = new Date().toISOString();
            let payload = {};
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            payload.producingArea = currentProducingArea.code;
            payload.blockType = 'FOODSAFETY';
            payload.holdFlagType = 'EndOfSeasonHold';
            payload.eventSource = 'FACTS';
            payload.isoStartDateTime = date;
            payload.commodityId = commodity.value;
            payload.transInfo = {
                TransactionDateTime: date,
                SourceSystem: 'FACTS',
                SourceUser: loggedInUser
            };
            payload.companyId = 210;
            //include sourceDocumentId if removing hold
            // payload.sourceDocumentId = null;
            payload.comments = 'End of Season hold applied';
            Middleware.Send('holdDetails', token, APIEndPoints.ADD_END_OF_SEASON_HOLD, 'POST', payload, { overrideResponseMapping: true })
                .then((data) => {
                    if (data && data.status && data.status !== 201) {
                        showErrorMessage('Failed to add hold for Producing Area.');
                        setOnError(true);
                    } else {
                        showErrorMessage('Added End of Season Hold for Producing Area Successfully!');
                        setOnError(false);

                        getRanchHolds(currentProducingArea.code, commodity.value);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage('Failed to add hold for Producing Area.');
                    setOnError(true);
                });
        });
    };

    const handleProducingAreaChange = (option) => {
        var producingArea = props.producingAreas.find((pa) => {
            return pa.code === option.value;
        });

        setCurrentProducingArea(producingArea || null);
        setCurrentProducingAreaOption(option);

        if (producingArea && commodity) {
            getRanchHolds(producingArea.code, commodity.value);
        }
    };

    const handleCommodityChange = (event) => {
        setCommodity(event);

        if (currentProducingArea && currentProducingArea.code && event) {
            getRanchHolds(currentProducingArea.code, event.value);
        }
    };

    return (
        <div>
            <br />
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    <DrcBerrySelect
                        label={'Commodity Type'}
                        placeholder={'Please select Commodity'}
                        value={commodity}
                        onChange={handleCommodityChange}
                        isClearable={false}
                    />
                </div>
                <div className="col-xs-12 col-sm-6">
                    <DrcSelect
                        label={'Producing Areas'}
                        placeholder={'Please select a Producing Area'}
                        value={currentProducingAreaOption}
                        onChange={(option) => {
                            handleProducingAreaChange(option);
                        }}
                    >
                        {props.producingAreas.map((pa) => {
                            return { label: `${pa.code}: ${pa.descript}`, value: pa.code };
                        })}
                    </DrcSelect>
                </div>
            </div>
            {currentProducingAreaOption && currentProducingAreaOption.value.length > 0 && commodity && commodity.value > 0 ? (
                <>
                    <DrcButton isPrimary onClick={handleProducingAreaHold} style={{ marginLeft: 0 }} disabled={allRanchIsHold}>
                        Apply Hold to Producing Area
                    </DrcButton>
                    {currentProducingArea.code && (
                        <RefreshButton onClick={() => getRanchHolds(currentProducingArea.code, commodity.value)}></RefreshButton>
                    )}
                    <DrcGrid
                        columns={columns}
                        onAddFilter={(filter) => setFilters(handleFilterChange(filter))}
                        onClearFilters={() => ({})}
                        rowGetter={(i) => filteredRows[i]}
                        toolbar={<Toolbar enableFilter={true} />}
                        rows={ranchList}
                    ></DrcGrid>
                </>
            ) : null}
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}
            {confirmDialog && (
                <DrcDialog
                    open={confirmDialog}
                    title={`Are you sure you want to add an End of Season Hold to the Producing area ${
                        currentProducingArea ? currentProducingArea.descript : 'N/A'
                    } for commodity ${commodity ? commodity.label : 'N/A'}`}
                    buttons={
                        <div>
                            <DrcButton isPrimary onClick={handleHoldConfirm}>
                                Yes
                            </DrcButton>
                            <DrcButton isSecondary onClick={() => setConfirmDialog(false)}>
                                Cancel
                            </DrcButton>
                        </div>
                    }
                >
                    <DrcInput
                        label={'Comment'}
                        size="Small"
                        value={comment}
                        placeholder={'Please enter a comment'}
                        onChange={(e) => onCommentChange(e)}
                        inputProps={{ maxLength: 200 }}
                    ></DrcInput>
                </DrcDialog>
            )}
            {warning && <DrcPageWarning anchorVertical="top" message={'API is not yet integrated'} isError={true} />}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        ranches: state.masterReducer.ranches,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        producingAreas: state.masterReducer.producingAreas,
        commodityTypeOptions: state.masterReducer.commodityTypeOptions
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setProducingAreas: (data) => dispatch(setProducingAreas(data)),
    setOpenProducingArea: (payload) => dispatch(setOpenProducingArea(payload)),
    setCloseProducingArea: (payload) => dispatch(setCloseProducingArea(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(EndOfSeason)));
