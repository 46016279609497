import React from 'react';
import { IconButton } from '@material-ui/core';
import { DrcTooltip } from 'driscolls-react-components';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withStyles } from '@material-ui/core/styles';

let styles = (theme) => ({
    refresh: {
        margin: '5px 5px 0 -17px',
        position: 'relative',
        float: 'right'
    }
});

const RefreshButton = (props) => {
    const { classes, onClick } = props;
    return (
        <IconButton color="primary" component="span" onClick={onClick} className={classes.refresh}>
            <DrcTooltip tipText={'Refresh Grid'}>
                <RefreshIcon style={{ fontSize: '30px' }} />
            </DrcTooltip>
        </IconButton>
    );
};

export default withStyles(styles)(RefreshButton);
