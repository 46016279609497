import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withAuth } from '@okta/okta-react';

import { DrcPanel, DrcTabs } from 'driscolls-react-components';
import BlockIcon from '@material-ui/icons/Block';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import WidgetsIcon from '@material-ui/icons/Widgets';

import Header from '../../components/RanchOverview/Header';

import Holds from './../Holds/holds';
import ChemicalActivity from '../ChemicalApplication/chemicalActivity';
import Restrictions from '../Restrictions/restrictions';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import { setPageTitleAction } from '../../actions/actions';
import {
    setRanch,
    setRanchDetails,
    setBlockList,
    setHolds,
    setApplications,
    setRanchLevelHoldDetails,
    clearHoldDetails,
    clearRanch
} from '../../actions/ranchOverviewActions';

import RanchUtilities from '../../data/RanchUtilities';

const pageTitle = 'Ranch Overview';

let styles = (theme) => ({
    subTabItems: {
        border: '1px solid black',
        backgroundColor: ''
    },
    panel: {
        border: `none`,
        marginTop: `1rem`,
        borderRadius: 2,
        boxShadow: `0 2px 4px 0 #C3C3C3`
    },
    tabPanel: {
        border: `none`,
        paddingTop: 0,
        borderRadius: 2,
        boxShadow: `0 2px 4px 0 #C3C3C3`
    },
    tabItem: {
        marginTop: theme.spacing(1.5),
        color: `${theme.palette.primary.main} !important`,
        textTransform: 'uppercase',
        opacity: 0.7,
        borderBottomColor: `#FFF`,
        '& a': {
            border: `1px solid gray`,
            borderRadius: theme.spacing(0.5)
        }
    },
    activeTabItem: {
        opacity: 1,
        color: `#6F5091 !important`,
        borderBottomColor: `#6F5091`
    },
    tabIcon: {
        transform: 'translateY(4px)',
        marginRight: 4
    }
});

const allGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(
    window.config.OKTA_REGULAR_GROUPS || [],
    window.config.OKTA_READ_ONLY_GROUPS || [],
    window.config.OKTA_DC_USER_GROUPS
);

const adminGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(
    window.config.OKTA_REGULAR_GROUPS || [],
    window.config.OKTA_READ_ONLY_GROUPS || []
);

const RanchOverview = (props) => {
    const { classes } = props;

    const tabs = [
        {
            tabName: (
                <div>
                    <span>
                        <BlockIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span>Holds</span>
                </div>
            ),
            tabIndex: 0,
            component: Holds,
            route: '/Holds',
            isDefault: true,
            groupsAllowed: allGroups
        },
        {
            tabName: (
                <div>
                    <span>
                        <LibraryBooksIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span>Applications</span>
                </div>
            ),
            tabIndex: 1,
            route: '/Applications',
            component: ChemicalActivity,
            groupsAllowed: allGroups
        },
        {
            tabName: (
                <div>
                    <span>
                        <WidgetsIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span>Restrictions</span>
                </div>
            ),
            tabIndex: 2,
            route: '/Restrictions',
            component: Restrictions,
            groupsAllowed: adminGroups
        }
    ];

    const [inputRanchNumber, setRanchNumber] = useState({
        ranchNumber: '',
        showHeaderFields: false,
        invalidRanchNumber: false
    });
    const [isInvalidRanchNumber, setIsInvalidRanchNumber] = useState(false);

    const [menuItems, setMenuItems] = useState([]);
    let redirect = '';

    const onLoad = () => {
        props.setPageTitle(pageTitle);
        props.clearRanch();

        //get ranch number from url
        let ranchNumber = '';
        let pathChunks = props.location.pathname.split('/RanchOverview/');
        if (pathChunks.length > 1) {
            let paramChunks = pathChunks[1].split('/');
            if (paramChunks.length > 0) {
                ranchNumber = paramChunks[0];
                let modifiedParamChunks = paramChunks;
                modifiedParamChunks.splice(0, 1); // remove ranch number from the path
                redirect = ranchNumber ? '/' + modifiedParamChunks.join('/') : '';
            }
        }
        setCurrentRanch(ranchNumber);
    };
    useEffect(onLoad, []);

    const handleRanchSelect = (option) => {
        let selectedRanchNumber = (option || {}).value;
        setCurrentRanch(selectedRanchNumber);
    };

    const setCurrentRanch = (selectedRanchNumber) => {
        // check for ranch number entered in url directly
        if (selectedRanchNumber) {
            let isValidRanchNumber = props.ranches.find((record) => record.ranchNumber === selectedRanchNumber);
            if (!isValidRanchNumber) {
                setRanchNumber({ ranchNumber: selectedRanchNumber, showHeaderFields: false });
                setIsInvalidRanchNumber(true);
                props.setRanch(selectedRanchNumber);
                let newPath = `${props.match.path}${selectedRanchNumber ? `/${selectedRanchNumber}${redirect}` : ''}`;
                props.history.push(newPath, { ranch: selectedRanchNumber });
                return;
            }
        }
        setRanchNumber({
            ranchNumber: `${selectedRanchNumber ? selectedRanchNumber : ''}`,
            showHeaderFields: selectedRanchNumber ? true : false
        });
        setIsInvalidRanchNumber(false);
        props.setRanch(selectedRanchNumber);
        let newPath = `${props.match.path}${selectedRanchNumber ? `/${selectedRanchNumber}${redirect}` : ''}`;
        console.log(newPath);
        props.history.push(newPath, { ranch: selectedRanchNumber });
        if (selectedRanchNumber) {
            props.auth.getAccessToken().then((token) => {
                createRoutesForRanch(token);
                props.clearHoldDetails();
                RanchUtilities.SetRanchData(
                    token,
                    selectedRanchNumber,
                    props.setBlockList,
                    props.setHolds,
                    props.setApplications,
                    props.setRanchLevelHoldDetails,
                    () => {}
                );
            });
            getRanchDetails(selectedRanchNumber);
        }
    };

    const getRanchDetails = (ranchNumber) => {
        const selectedRanchDetails = (props.ranches || []).find((r) => r.ranchNumber === ranchNumber);
        props.setRanchDetails(selectedRanchDetails);
    };

    const createRoutesForRanch = (token) => {
        let { ranch } = props.history.location.state || '';
        const items = tabs.filter((item) => DuAuthenticationUtilities.IsInGroup(token, item.groupsAllowed || []));
        const newTabs = items.map((item) => {
            return { ...item, route: `${ranch ? `/${ranch}` : ''}${item.route}` };
        });
        setMenuItems(newTabs);
    };

    return (
        <div>
            <DrcPanel maxWidth="100%" className={`${classes.panel}`}>
                <Header
                    onChange={handleRanchSelect}
                    showHeaderFields={inputRanchNumber.showHeaderFields}
                    isInvalidRanchNumber={isInvalidRanchNumber}
                    setCurrentRanch={setCurrentRanch}
                ></Header>
            </DrcPanel>

            {inputRanchNumber.ranchNumber && inputRanchNumber.showHeaderFields && (
                <DrcPanel maxWidth="100%" className={`${classes.tabPanel}`}>
                    <DrcTabs
                        classes={{ tabItem: classes.tabItem, activeTabItem: classes.activeTabItem }}
                        menuItems={menuItems}
                        registerRoutes
                        baseLocation={`${props.match.path}${props.ranch ? `/${props.ranch}` : ''}`}
                    ></DrcTabs>
                </DrcPanel>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isMasterDataInitialized: state.masterReducer.isInitialized,
        ranches: state.masterReducer.ranches,
        ranch: state.ranchOverview.ranch
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    clearRanch: () => dispatch(clearRanch()),
    setRanch: (data) => dispatch(setRanch(data)),
    setRanchDetails: (data) => dispatch(setRanchDetails(data)),
    setBlockList: (data) => dispatch(setBlockList(data)),
    setHolds: (data) => dispatch(setHolds(data)),
    setApplications: (data) => dispatch(setApplications(data)),
    setRanchLevelHoldDetails: (data) => dispatch(setRanchLevelHoldDetails(data)),
    clearHoldDetails: () => dispatch(clearHoldDetails())
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(RanchOverview)));
