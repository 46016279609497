import { withStyles } from '@material-ui/core/styles';
import { DrcBerrySelect, DrcDatePicker, DrcSelect } from 'driscolls-react-components';
import { InputText } from 'primereact/inputtext';
import React from 'react';



const styles = (theme) => ({
    inputEditor: {
        height: '90%',
        border: '1px solid #D1CBCB',
        [theme.darkTheme]: {
            backgroundColor: '#404040',
            border: '1px solid #5d5b5b',
            color: '#DDD'
        }
    },
    inputEditorInValid: {
        height: '90%',
        border: '1px solid #f44336',
        [theme.darkTheme]: {
            backgroundColor: '#404040',
            border: '1px solid #f44336',
            color: '#DDD'
        }
    }
});

class DrcDatagridEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { fieldValue: this.props.rowData[this.props.field], isError: false };
    }

    componentDidMount() {
        this.setState({ fieldValue: this.props.rowData[this.props.field] });
    }

    onEditorValueChange = (value) => {
        if (
            this.props.columnEditorValidator === undefined ||
            (this.props.columnEditorValidator && this.columnEditorValidator.call(this, value, this.props.rowData, this.props.field))
        ) {
            this.setState({ fieldValue: isNaN(value) ? value : value === '' ? '' : parseInt(value) });
            this.setState({ isError: value === '' && this.props.required });
        }
    };

    onEditorValueCommit = async (gridProps, value) => {
        if (this.props.rowData[this.props.field] !== value) {
            await this.props.onEditorValueCommit(this.props, value);
        }
    };

    berrySelect = (optn,value) => {
        if (
            this.props.columnEditorValidator === undefined ||
            (this.props.columnEditorValidator && this.columnEditorValidator.call(this, value, this.props.rowData, this.props.field))
        ) {
            this.setState({ fieldValue: optn });
            this.props.onEditorValueCommit(this.props, optn.label);
        }
    };

    datePickerChange(filterDate,value) {
        if (
            this.props.columnEditorValidator === undefined ||
            (this.props.columnEditorValidator && this.columnEditorValidator.call(this, value, this.props.rowData, this.props.field))
        ) {
            this.setState({ fieldValue: filterDate });
            this.props.onEditorValueCommit(this.props, filterDate.toDateString());
        }
    }

    handleSelectChange = (value, action) => {
        if (
            this.props.columnEditorValidator === undefined ||
            (this.props.columnEditorValidator && this.columnEditorValidator.call(this, value, this.props.rowData, this.props.field))
        ) {
            this.setState({ fieldValue: value });
            this.props.onEditorValueCommit(this.props, value.value);
        }
    };

    handleSwitchChange(event, checked) {
        if (
            this.props.columnEditorValidator === undefined ||
            (this.props.columnEditorValidator && this.columnEditorValidator.call(this, checked, this.props.rowData, this.props.field))
        ) {
            this.setState({ fieldValue: checked });
            this.props.onEditorValueCommit(this.props, checked);
        }
    }

    getKeyFilter = (dataType) => {
        switch (dataType) {
            case 'text':
                return null;
            case 'int':
                return 'int';
            case 'decimal':
            case 'float':
                return 'num';
            default:
                return null;
        }
    };

    render() {
        const textTypes = ['text', 'int', 'float', 'decimal'];
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {textTypes.includes(this.props.editorType) ? (
                    <InputText
                        type="text"
                        className={this.state.isError ? this.props.classes.inputEditorInValid : this.props.classes.inputEditor}
                        value={this.state.fieldValue}
                        onChange={(e) => this.onEditorValueChange(e.target.value)}
                        onBlur={(e) => this.onEditorValueCommit(this.props, e.target.value)}
                        required={this.props.required}
                        keyfilter={this.getKeyFilter(this.props.editorType)}
                    />
                ) : null}
                {this.props.editorType === 'berryselect' ? <DrcBerrySelect value={this.state.fieldValue} onChange={this.berrySelect} required={this.props.required} /> : null}
                {this.props.editorType === 'date' ? (
                    <DrcDatePicker
                        selectedDate={this.state.fieldValue ? this.state.fieldValue : null}
                        onChange={(date) => this.datePickerChange(date)}
                        required={this.props.required}
                    />
                ) : null}
                {this.props.editorType === 'select' ? (
                    <DrcSelect
                        onChange={this.handleSelectChange}
                        label={this.props.field}
                        value={this.state.fieldValue}
                        options={this.props.options}
                        required={this.props.required}
                    />
                ) : null}
            </div>
        );
    }
}

export default withStyles(styles)(DrcDatagridEditor);
