import { Middleware } from 'one-ring';
import { DuDateUtilities } from 'driscolls-react-utilities';

const countryRestrictionMapping = [
    Middleware.CreateMapping('ranchBlocks', 'RanchBlocks'),
    Middleware.CreateMapping('ranchNbr', 'RanchNbr'),
    Middleware.CreateMapping('blockDesignator', 'BlockDesignator'),
    Middleware.CreateMapping('blockType', 'BlockType'),
    Middleware.CreateMapping('sourceDocumentId', 'SourceDocumentId'),
    Middleware.CreateMapping('holdArea', 'HoldArea'),
    Middleware.CreateMapping('isoStartDateTime', 'IsoStartDateTime'),
    Middleware.CreateMapping('isoEndDateTime', 'IsoEndDateTime'),
    Middleware.CreateMapping('transInfo', 'TransInfo'),
    Middleware.CreateMapping('companyId', 'CompanyId'),
    Middleware.CreateMapping('Comments', 'Comments'),
    Middleware.CreateMapping('id', 'Id'),
    Middleware.CreateMapping('areaCode', 'AreaCode'),
    Middleware.CreateMapping('abbreviation', 'Abbreviation'),
    Middleware.CreateMapping('mrlBlock', 'MrlBlock'),
    Middleware.CreateMapping('startDate', 'StartDate', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('endDate', 'EndDate', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('createdBy', 'CreatedBy'),
    Middleware.CreateMapping('createdDateTime', 'CreatedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('modifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('modifiedDateTime', 'ModifiedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateArrayMapping('Results', 'CountryRestrictions')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('countryRestriction', 'GET')) {
        Middleware.RegisterMapping('countryRestriction', 'GET', countryRestrictionMapping);
        Middleware.RegisterMapping('countryRestriction', 'POST', countryRestrictionMapping);
        Middleware.RegisterMapping('countryRestriction', 'DELETE', countryRestrictionMapping);
    }
}

const countryRestrictionTypesGroup = {
    ConfigureMiddleWare
};

export default countryRestrictionTypesGroup;
