import React, { useState } from 'react';
import { Middleware } from 'one-ring';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import { DrcDialog, DrcButton, DrcPageWarning, DrcInput } from 'driscolls-react-components';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import { setChemicalList, setChemical } from './../../../../actions/chemicalMaintenanceActions';
import APIEndPoints from './../../../../services/api';
import { Grid } from '@material-ui/core';

let styles = (theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '100%',
            width: '50%'
        }
    }
});

const AddChemical = (props) => {
    const { classes, open, onClose, chemical: selectedChemical, chemicalList } = props;

    let chemicalDefaultProps = { chemicalId: '', chemicalName: '', isActive: false };
    let confirmDialogDefaultProps = { isOpen: false, title: '', content: '', onConfirm: '', onCancel: '', onClose: '' };

    const [chemical, setChemical] = useState(chemicalDefaultProps);
    const [confirmDialog, setConfirmDialog] = useState(confirmDialogDefaultProps);
    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState(false);

    const inputFields = [
        {
            name: 'chemicalName',
            label: 'Chemical Name',
            type: 'input'
        }
    ];

    const handleChange = (name) => (event) => {
        setErrorMessage('');
        let chemicalObj = chemical;
        chemicalObj[name] = event.target.value;
        setChemical(chemicalObj);
    };

    const handleKeyPress = (inputType) => (event) => {
        if (inputType !== 'number') return;

        const invalidKeys = ['e', '+', '-', '.'];
        if (invalidKeys.includes(event.key.toLowerCase())) {
            event.preventDefault();
        }
    };

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const isValidChemical = () => {
        const { chemicalName } = chemical;

        if (!(chemicalName || '').trim()) {
            showErrorMessage(`Chemical Name is mandatory`);
            setOnError(true);
            return false;
        }

        if ((chemicalList || []).some((item) => (item.label || '').toLowerCase() === chemicalName.toLowerCase())) {
            if (!(((selectedChemical || {}).label || '').toLocaleLowerCase() === chemicalName.toLocaleLowerCase())) {
                showErrorMessage('Chemical name must be unique');
                setOnError(true);
                return false;
            }
        }

        return true;
    };

    const mapChemicalToOption = (chemical) => {
        return {
            label: chemical.chemicalName,
            value: chemical.chemicalId,
            isActive: chemical.isActive
        };
    };

    const checkDuplicateExists = (chemicalName) => {
        return (props.chemicalList || []).find((el) => el.label === chemicalName);
    };

    const handleAddChemical = async () => {
        const { chemicalName } = chemical;

        let record = checkDuplicateExists(chemicalName);

        if (record) {
            showErrorMessage('Chemical name must be unique.');
            setOnError(true);
            setConfirmDialog(confirmDialogDefaultProps);
            return;
        }

        //build payload for adding chemical and post to API /addChemical
        let payload = {
            chemicalName,
            isActive: true
        };

        props.auth.getAccessToken().then((token) => {
            payload.lastChangedBy = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            payload.lastChangedDateTime = new Date().toISOString();
            //https://driscollsit.atlassian.net/browse/FE-175
            //Added additional header to invalidate cache
            Middleware.Send(
                'addChemical',
                token,
                APIEndPoints.ADD_CHEMICAL,
                'POST',
                payload,
                {
                    overrideRequestMapping: false,
                    overrideResponseMapping: true
                },
                null,
                0,
                { invalidate: 'invalidate' }
            )
                .then((data) => {
                    if (!data || data.status || data.error) {
                        //the api does not send a status code on successful response
                        setConfirmDialog(confirmDialogDefaultProps);
                        showErrorMessage(data.error || 'Invalid response from server');
                        setOnError(true);
                    } else {
                        //set id to select newly added chemical
                        let newChemicalId = data.ChemicalId;
                        chemical.chemicalId = newChemicalId;
                        chemical.isActive = true;
                        setChemical(mapChemicalToOption(chemical));

                        setConfirmDialog(confirmDialogDefaultProps);

                        handleSuccessClose();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setConfirmDialog(confirmDialogDefaultProps);
                    showErrorMessage('Unable to add chemical. Please try again');
                    setOnError(true);
                });
        });
    };

    const confirmNo = () => {
        setConfirmDialog(confirmDialogDefaultProps);
    };

    const confirmAdd = () => {
        if (isValidChemical(true)) {
            setConfirmDialog({
                title: `Confirm Add`,
                content: `Are you sure you want to add this chemical to the master?`,
                isOpen: true,
                onConfirm: handleAddChemical,
                onCancel: confirmNo,
                onClose: confirmNo
            });
        }
    };

    const renderConfirmDialog = () => {
        return (
            <DrcDialog
                className={classes.dialog}
                title={confirmDialog.title}
                open={confirmDialog.isOpen}
                onClose={() => confirmDialog.onCancel()}
                buttons={
                    <div className="row">
                        <div className="col-xs-6">
                            <DrcButton fullWidth isPrimary onClick={() => confirmDialog.onConfirm()}>
                                Yes
                            </DrcButton>
                        </div>
                        <div className="col-xs-6" style={{ paddingRight: '2rem' }}>
                            <DrcButton fullWidth isSecondary onClick={() => confirmDialog.onCancel()}>
                                No
                            </DrcButton>
                        </div>
                    </div>
                }
            >
                <span>{confirmDialog.content}</span>
            </DrcDialog>
        );
    };

    const handleSuccessClose = () => {
        chemical.forceUpdate = true; //force update because if we update reducer, we might miss the chemicals that were simultaneously added by other users
        props.setChemical(chemical);
        //call getdetails for newly added chemical
        onClose(mapChemicalToOption(chemical));
    };

    return (
        <div>
            <DrcDialog
                title="Add New Chemical"
                open={open}
                onClose={() => onClose()}
                buttons={
                    <div className="row">
                        <div className="col-xs-6">
                            <DrcButton fullWidth isPrimary onClick={() => confirmAdd()}>
                                Save
                            </DrcButton>
                        </div>
                        <div className="col-xs-6" style={{ paddingRight: '2rem' }}>
                            <DrcButton fullWidth isSecondary onClick={() => onClose()}>
                                Cancel
                            </DrcButton>
                        </div>
                    </div>
                }
            >
                <Grid container>
                    {inputFields.map((item) => {
                        return (
                            <DrcInput
                                key={item['name']}
                                type={item['type']}
                                label={item['label']}
                                placeholder={item['label']}
                                onChange={handleChange(item.name)}
                                onKeyPress={handleKeyPress(item['type'])}
                                InputLabelProps={{ shrink: true }}
                                inputProps={item['type'] === 'number' ? { min: item['min'] } : {}}
                            ></DrcInput>
                        );
                    })}
                </Grid>
            </DrcDialog>
            {confirmDialog.isOpen && renderConfirmDialog()}
            {errorMessage && <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} />}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        chemical: state.chemicalMaintenance.chemical,
        chemicalList: state.chemicalMaintenance.chemicalList,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        isAdmin: state.masterReducer.isAdmin
    };
};

const mapDispatchToProps = (dispatch) => ({
    setChemicalList: (data) => dispatch(setChemicalList(data)),
    setChemical: (data) => dispatch(setChemical(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(AddChemical)));
