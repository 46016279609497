import { Middleware } from 'one-ring';
import { DuDateUtilities } from 'driscolls-react-utilities';

const getHoldsMapping = [
    Middleware.CreateMapping('id', 'Id'),
    Middleware.CreateMapping('holdTypeId', 'HoldTypeId'),
    Middleware.CreateMapping('ranchNumber', 'RanchNumber'),
    Middleware.CreateMapping('mrlBlock', 'MrlBlock'),
    Middleware.CreateMapping('startDate', 'StartDate', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('endDate', 'EndDate', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('comments', 'Comments'),
    Middleware.CreateMapping('documentId', 'DocumentId'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('createdBy', 'CreatedBy'),
    Middleware.CreateMapping('createdDateTime', 'CreatedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('modifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('modifiedDateTime', 'ModifiedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateArrayMapping('inspectionHolds', 'InspectionHolds'),
    Middleware.CreateArrayMapping('quarantineHolds', 'QuarantineHolds'),
    Middleware.CreateArrayMapping('foodSafetyHolds', 'FoodSafetyHolds'),
    Middleware.CreateArrayMapping('endOfSeasonHolds', 'EndOfSeasonHolds'),
    Middleware.CreateArrayMapping('transferHolds', 'TransferHolds'),
    Middleware.CreateArrayMapping('Results')
];

const holdDetailsMapping = [
    Middleware.CreateMapping('ranchNbr', 'RanchNbr'),
    Middleware.CreateMapping('blockDesignator', 'BlockDesignator'),
    Middleware.CreateMapping('blockType', 'BlockType'),
    Middleware.CreateMapping('id', 'SourceDocumentId'),
    Middleware.CreateMapping('comments', 'Comments'),
    Middleware.CreateMapping('commodityId', 'CommodityId'),
    Middleware.CreateMapping('holdFlagType', 'HoldFlagType'),
    Middleware.CreateMapping('sourceBatchId', 'SourceBatchId'),
    Middleware.CreateMapping('sourceDocumentId', 'SourceDocumentId'),
    Middleware.CreateMapping('isoStartDateTime', 'IsoStartDateTime'),
    Middleware.CreateMapping('isoEndDateTime', 'IsoEndDateTime'),
    Middleware.CreateMapping('transInfo', 'TransInfo'),
    Middleware.CreateMapping('transactionDateTime', 'TransactionDateTime'),
    Middleware.CreateMapping('sourceSystem', 'SourceSystem'),
    Middleware.CreateMapping('sourceUser', 'SourceUser'),
    Middleware.CreateMapping('companyId', 'CompanyId'),
    Middleware.CreateArrayMapping('ranchBlocks', 'RanchBlocks'),
    Middleware.CreateMapping('producingArea', 'ProducingArea'), // End Of Season Hold Mapping
    Middleware.CreateMapping('eventSource', 'EventSource'), // End Of Season Hold Mapping
    Middleware.CreateArrayMapping('Results')
];

const getProducingAreaMapping = [
    Middleware.CreateArrayMapping('code', 'Code'),
    Middleware.CreateArrayMapping('descript', 'Descript'),
    Middleware.CreateArrayMapping('status', 'Status'),
    Middleware.CreateArrayMapping('data', 'Data'),
    Middleware.CreateArrayMapping('Results')
];

const producingAreaEndOfSeasonHoldsMapping = [
    Middleware.CreateMapping('RanchNumber', 'RanchNumber'),
    Middleware.CreateMapping('RanchName', 'RanchName'),
    Middleware.CreateMapping('ProducingArea', 'ProducingArea'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('EndOfSeasonHold', 'EndOfSeasonHold'),
    Middleware.CreateMapping('SourceDocumentId', 'SourceDocumentId'),
    Middleware.CreateMapping('StartDate', 'StartDate', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('getHolds', 'GET')) {
        Middleware.RegisterMapping('getHolds', 'GET', getHoldsMapping);
        Middleware.RegisterMapping('getHolds', 'POST', getHoldsMapping);
    }

    if (!Middleware.CheckMappingExists('holdDetails', 'POST')) {
        Middleware.RegisterMapping('holdDetails', 'POST', holdDetailsMapping);
        Middleware.RegisterMapping('holdDetails', 'DELETE', holdDetailsMapping);
    }

    if (!Middleware.CheckMappingExists('producingAreas', 'GET')) {
        Middleware.RegisterMapping('producingAreas', 'GET', getProducingAreaMapping);
    }

    if (!Middleware.CheckMappingExists('producingAreaEndOfSeasonHolds', 'GET')) {
        Middleware.RegisterMapping('producingAreaEndOfSeasonHolds', 'GET', producingAreaEndOfSeasonHoldsMapping);
    }
}

const holdsDetailsGroup = {
    ConfigureMiddleWare
};

export default holdsDetailsGroup;
