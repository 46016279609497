import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withAuth } from '@okta/okta-react';
//import { Middleware } from 'one-ring';

import AppsIcon from '@material-ui/icons/Apps';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Application from '../../components/ChemicalActivity/Application';
import NoApplication from '../../components/ChemicalActivity/NoApplication';
import { setPageTitleAction } from '../../actions/actions';
import { setApplications } from '../../actions/ranchOverviewActions';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { DrcPageWarning, DrcTabs } from 'driscolls-react-components';
import RanchUtilities from '../../data/RanchUtilities';

const pageTitle = 'Chemical Activity';

let styles = (theme) => ({
    subTabItems: {
        border: '1px solid black',
        backgroundColor: ''
    },
    panel: {
        border: `none`,
        marginTop: `2rem`,
        borderRadius: 2,
        boxShadow: `0 2px 4px 0 #C3C3C3`
    },
    tabItem: {
        color: 'gray !important',
        margin: '4px 0 -5px 0',
        padding: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        minWidth: theme.spacing(15)
    },
    tabItemButton: {
        borderRadius: 4,
        marginRight: 8,
        border: '1px solid #C3C3C3'
    },
    activeTabItem: {
        color: `#FFFFFF !important`,
        borderBottom: `2px solid #FFFFFF`,
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.spacing(0.5)
    },
    tabIcon: {
        transform: 'translateY(4px)',
        marginRight: 4
    }
});

const allGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || [], window.config.OKTA_READ_ONLY_GROUPS || []);

const ChemicalActivity = (props) => {
    const { classes, isMasterDataInitialized, setPageTitle, ranch } = props;

    const tabs = [
        {
            tabName: (
                <div>
                    <span>
                        <CheckBoxOutlineBlankIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span>No Application</span>
                </div>
            ),
            component: NoApplication,
            route: '/NoApplication',
            tabIndex: 0,
            isDefault: true,
            groupsAllowed: allGroups
        },
        {
            tabName: (
                <div>
                    <span>
                        <AppsIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span>Application</span>
                </div>
            ),
            component: Application,
            route: '/Application',
            tabIndex: 1,
            groupsAllowed: allGroups
        }
    ];

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setPageTitle(pageTitle);
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }
    }, [setPageTitle, isMasterDataInitialized]);

    const getData = () => {
        props.auth.getAccessToken().then((token) => {
            RanchUtilities.SetApplicationsDetails(token, props.ranch, props.setApplications, showErrorMessage);
        });
    };
    useEffect(getData, [ranch]);

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    return (
        <div>
            <DrcTabs
                classes={{
                    root: classes.tabItem,
                    tabItem: `${classes.tabItem} ${classes.tabItemButton}`,
                    activeTabItem: classes.activeTabItem
                }}
                menuItems={tabs}
                registerRoutes
            ></DrcTabs>
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} /> : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isMasterDataInitialized: state.masterReducer.isInitialized,
        ranch: state.ranchOverview.ranch,
        noApplication: state.ranchOverview.noApplication
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setApplications: (data) => dispatch(setApplications(data))
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChemicalActivity)));
