import { Middleware } from 'one-ring';

const blocksAndHoldsMapping = [
    Middleware.CreateArrayMapping('inspectionHolds', 'InspectionHolds'),
    Middleware.CreateArrayMapping('foodSafetyHolds', 'FoodSafetyHolds'),
    Middleware.CreateArrayMapping('quarantineHolds', 'QuarantineHolds'),
    Middleware.CreateArrayMapping('endOfSeasonHolds', 'EndOfSeasonHolds'),
    Middleware.CreateArrayMapping('transferHolds', 'TransferHolds'),
    Middleware.CreateMapping('blocks', 'Blocks'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('blocksAndHolds', 'GET')) {
        Middleware.RegisterMapping('blocksAndHolds', 'GET', blocksAndHoldsMapping);
    }
}

const blocksAndHoldsGroup = {
    ConfigureMiddleWare
};

export default blocksAndHoldsGroup;
