import {
    RANCH,
    SET_RANCH_DETAILS,
    SET_HOLDS,
    SET_APPLICATIONS,
    SET_COUNTRY_RESTRICTIONS,
    SET_PUR_RESTRICTIONS,
    SET_BLOCK_LIST,
    SET_RANCH_LEVEL_HOLD_DETAILS,
    CLEAR_HOLD_DETAILS,
    CLEAR_RANCH,
    SET_MRL_BLOCKS,
    CLEAR_MRL_BLOCKS
} from '../constants/constant';

export const setRanch = (data) => ({
    type: RANCH,
    payload: data
});

export const setRanchDetails = (data) => ({
    type: SET_RANCH_DETAILS,
    payload: data
});

export const setHolds = (data) => ({
    type: SET_HOLDS,
    payload: data
});

export const setApplications = (data) => ({
    type: SET_APPLICATIONS,
    payload: data
});

export const setCountryRestrictions = (data) => ({
    type: SET_COUNTRY_RESTRICTIONS,
    payload: data
});

export const setPurRestrictions = (data) => ({
    type: SET_PUR_RESTRICTIONS,
    payload: data
});

export const setBlockList = (data) => ({
    type: SET_BLOCK_LIST,
    payload: data
});

export const setRanchLevelHoldDetails = (data) => ({
    type: SET_RANCH_LEVEL_HOLD_DETAILS,
    payload: data
});

export const clearHoldDetails = () => ({
    type: CLEAR_HOLD_DETAILS
});

export const clearRanch = () => ({
    type: CLEAR_RANCH
});

export const setMrlBlocks = (data) => ({
    type: SET_MRL_BLOCKS,
    payload: data
});

export const clearMrlBlocks = () => ({
    type: CLEAR_MRL_BLOCKS
});
