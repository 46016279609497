import {SET_WRITE_ACCESS} from '../constants/constant';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const UPDATE_MODAL = 'UPDATE_MODAL';
export const SET_ERRORS = 'SET_ERRORS';
export const HIDE_ERROR_DIALOG = 'HIDE_ERROR_DIALOG';

export const SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN';
export const HIDE_LOADING_SCREEN = 'HIDE_LOADING_SCREEN';

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

export const SERVICE_WORKER_UPDATE = 'SERVICE_WORKER_UPDATE';

export const closeModalAction = () => ({
    type: CLOSE_MODAL,
    payload: {}
});

export const openModalAction = (settings) => ({
    type: OPEN_MODAL,
    payload: { settings }
});

export const updateModalAction = (settings) => ({
    type: UPDATE_MODAL,
    payload: { settings }
});

export const setErrorsAction = (title, errors) => ({
    type: SET_ERRORS,
    payload: { title, errors }
});

export const hideErrorDialogAction = () => ({
    type: HIDE_ERROR_DIALOG,
    payload: {}
});

export const showLoadingScreenAction = () => ({
    type: SHOW_LOADING_SCREEN,
    payload: {}
});

export const hideLoadingScreenAction = () => ({
    type: HIDE_LOADING_SCREEN,
    payload: {}
});

export const setPageTitleAction = (pageTitle) => ({
    type: SET_PAGE_TITLE,
    payload: pageTitle
});

export const setWriteAccess = (userGroup) => ({
    type: SET_WRITE_ACCESS,
    payload: userGroup
});

export const OPEN_CLOSE_NOT_FINISHED_MODAL = 'NOT_FINISHED_MODAL';
export const openCloseNotFinishedModal = (isOpen) => ({
    type: OPEN_CLOSE_NOT_FINISHED_MODAL,
    payload: isOpen
});

export const ADD_INFO = 'ADD_INFO';
export const addInfo = (info) => ({
    type: ADD_INFO,
    payload: info
});

export const CLEAR_INFO = 'CLEAR_INFO';
export const clearInfo = (name) => ({
    type: CLEAR_INFO,
    payload: name
});

export const SERVICE_WORKER_UPDATED = 'SERVICE_WORKER_UPDATED';
export const serviceWorkerUpdate = () => ({
    type: SERVICE_WORKER_UPDATED
});
