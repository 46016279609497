import { Middleware } from 'one-ring';

const commodityTypesMapping = [
    Middleware.CreateMapping('chemicalCommodityId', 'ChemicalCommodityId'),
    Middleware.CreateMapping('chemicalCommodity', 'ChemicalCommodity'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('commodityType', 'GET')) {
        Middleware.RegisterMapping('commodityType', 'GET', commodityTypesMapping);
        Middleware.RegisterMapping('commodityType', 'POST', commodityTypesMapping);
    }
}

const commodityTypesGroup = {
    ConfigureMiddleWare
};

export default commodityTypesGroup;
