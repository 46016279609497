import { Middleware } from 'one-ring';

const holdAreaMapping = [
    Middleware.CreateMapping('holdAreaId', 'holdAreaId'),
    Middleware.CreateMapping('holdArea', 'holdArea'),
    Middleware.CreateMapping('isActive', 'isActive'),
    Middleware.CreateArrayMapping('Results')
];

const holdPeriodMapping = [
    Middleware.CreateMapping('chemicalHoldId', 'ChemicalHoldId'),
    Middleware.CreateMapping('commodity', 'Commodity'),
    Middleware.CreateMapping('commodities', 'Commodities'),
    Middleware.CreateMapping('chemicalHolds', 'ChemicalHolds'),
    Middleware.CreateMapping('commodityId', 'CommodityId'),
    Middleware.CreateMapping('commodityDetId', 'CommodityDetId'),
    Middleware.CreateMapping('holdArea', 'HoldArea'),
    Middleware.CreateMapping('holdAreaId', 'HoldAreaId'),
    Middleware.CreateMapping('daysToShip', 'DaysToShip'),
    Middleware.CreateMapping('Comments', 'Comments'),
    Middleware.CreateMapping('mrl', 'Mrl'),
    Middleware.CreateMapping('chemicalId', 'ChemicalId'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('createdBy', 'CreatedBy'),
    Middleware.CreateMapping('createdDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('modifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('modifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('holdArea', 'GET')) {
        Middleware.RegisterMapping('holdArea', 'GET', holdAreaMapping);
        Middleware.RegisterMapping('holdArea', 'POST', holdAreaMapping);
    }
    if (!Middleware.CheckMappingExists('holdPeriods', 'GET')) {
        Middleware.RegisterMapping('holdPeriods', 'GET', holdPeriodMapping);
        Middleware.RegisterMapping('holdPeriods', 'POST', holdPeriodMapping);
        Middleware.RegisterMapping('holdPeriods', 'PUT', holdPeriodMapping);
        Middleware.RegisterMapping('holdPeriods', 'DELETE', holdPeriodMapping);
    }
}

const holdAreaTypesGroup = {
    ConfigureMiddleWare
};

export default holdAreaTypesGroup;
