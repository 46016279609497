import React, { useState, useEffect } from 'react';
import { Middleware } from 'one-ring';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';

import { setChemicalList, setChemical } from '../../actions/chemicalMaintenanceActions';
import { setPageTitleAction } from './../../actions/actions';

import APIEndPoints from '../../services/api';

import MasterDataUtilities from './../../data/MasterDataUtilities';

import { DrcPanel, DrcPageWarning, DrcTabs } from 'driscolls-react-components';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import ChemicalCommodity from '../../components/Maintenance/Chemical/ChemicalCommodity';
import InactivityHistory from '../../components/Maintenance/Chemical/InactivityHistory';
import ChemicalList from '../../components/Maintenance/Chemical/Master/ChemicalList';

const pageTitle = 'AI Maintenance';
const PAGE_TYPE = 'chemicalList';

let styles = (theme) => ({
    panel: {
        border: `0.5px solid ${theme.palette.grey}`,
        borderRadius: 2,
        paddingTop: 0
    },
    tabItem: {
        color: 'gray !important',
        marginTop: theme.spacing(1)
    },
    activeTabItem: {
        color: `#6F5091 !important`,
        borderBottom: `2px solid #6F5091`
    },
    selectionGrid: {
        paddingBottom: `${theme.spacing(1)} !important`
    },
    tabIcon: {
        paddingTop: theme.spacing(1)
    }
});

const adminGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(
    window.config.OKTA_REGULAR_GROUPS || [],
    window.config.OKTA_READ_ONLY_GROUPS || []
);

const Chemical = (props) => {
    const { classes, chemical, setChemicalList, isMasterDataInitialized, setPageTitle } = props;

    const tabs = [
        {
            tabName: (
                <div>
                    <span>
                        <ImportContactsIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span>Chemical Details</span>
                </div>
            ),
            tabIndex: 0,
            component: ChemicalCommodity,
            route: `/ChemicalDetails`,
            isDefault: true,
            groupsAllowed: adminGroups
        },
        {
            tabName: (
                <div>
                    <span>
                        <CallToActionIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span>Inactivity History</span>
                </div>
            ),
            tabIndex: 1,
            component: InactivityHistory,
            route: `/InactivityHistory`,
            groupsAllowed: adminGroups
        }
    ];

    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState('');

    const getData = async () => {
        var token = await props.auth.getAccessToken();
        Middleware.Send(PAGE_TYPE, token, APIEndPoints.CHEMICAL_LIST, 'GET', {})
            .then((data) => {
                if (data.status && data.status !== 200) {
                    showErrorMessage('Unable to fetch chemical list');
                    setOnError(true);
                } else {
                    setChemicalList(data.Results);
                }
            })
            .catch((error) => {
                console.log(error);
                showErrorMessage('Unable to fetch chemical list');
                setOnError(true);
            });
    };

    useEffect(() => {
        setPageTitle(pageTitle);
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
    }, [setPageTitle, isMasterDataInitialized]);

    const fetchData = () => {
        if (!chemical || chemical ? chemical.forceUpdate : []) {
            getData();
        }
    };

    useEffect(fetchData, [chemical]); //executes when chemical changes in props

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const handleChemicalSelect = (chemical) => {};

    return (
        <React.Fragment>
            {/* Page warning */}
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}

            {/* Chemical Master */}
            <ChemicalList handleChemicalSelect={handleChemicalSelect}></ChemicalList>

            {/* Chemical Maintenance Tabs */}
            {chemical.value ? (
                <DrcPanel maxWidth="100%" className={`${classes.panel}`}>
                    <DrcTabs
                        classes={{
                            root: classes.tabItem,
                            tabItem: classes.tabItem,
                            activeTabItem: classes.activeTabItem
                        }}
                        menuItems={tabs}
                        registerRoutes
                    ></DrcTabs>
                </DrcPanel>
            ) : null}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        chemical: state.chemicalMaintenance.chemical,
        chemicalList: state.chemicalMaintenance.chemicalList,
        inactivityHistory: state.chemicalMaintenance.inactivityHistory,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        isAdmin: state.masterReducer.isAdmin
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setChemicalList: (data) => dispatch(setChemicalList(data)),
    setChemical: (data) => dispatch(setChemical(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(Chemical)));
