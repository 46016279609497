import { Middleware } from 'one-ring';
import { DuDateUtilities } from 'driscolls-react-utilities';

const chemicalListMapping = [
    Middleware.CreateMapping('chemicalId', 'ChemicalId'),
    Middleware.CreateMapping('chemicalName', 'ChemicalName'),
    Middleware.CreateMapping('preHarvestIndex', 'PreHarvestInterval'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('hasTransactions', 'HasTransactions'),
    Middleware.CreateMapping('createdBy', 'CreatedBy'),
    Middleware.CreateMapping('createdDateTime', 'CreatedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('lastChangedBy', 'ModifiedBy'),
    Middleware.CreateMapping('lastChangedDateTime', 'ModifiedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateArrayMapping('Results')
];

const frequentlySearchedChemicalsMapping = [
    Middleware.CreateMapping('chemicalId', 'chemicalId'),
    Middleware.CreateMapping('chemicalName', 'chemicalName'),
    Middleware.CreateMapping('isActive', 'isActive'),
    Middleware.CreateArrayMapping('Results')
];

const addChemicalMapping = [
    Middleware.CreateMapping('chemicalName', 'ChemicalName'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('preHarvestIndex', 'PreHarvestInterval'),
    Middleware.CreateMapping('lastChangedBy', 'ModifiedBy'),
    Middleware.CreateMapping('lastChangedDateTime', 'ModifiedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : ''))
];

const editChemicalMapping = [
    Middleware.CreateMapping('chemicalId', 'ChemicalId'),
    Middleware.CreateMapping('chemicalName', 'ChemicalName'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('preHarvestIndex', 'PreHarvestInterval'),
    Middleware.CreateMapping('lastChangedBy', 'ModifiedBy'),
    Middleware.CreateMapping('lastChangedDateTime', 'ModifiedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : ''))
];

const inactivateChemicalMapping = [
    Middleware.CreateMapping('chemicalId', 'ChemicalId'),
    Middleware.CreateMapping('chemicalName', 'ChemicalName'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('reason', 'Comment'),
    Middleware.CreateMapping('preHarvestIndex', 'PreHarvestInterval'),
    Middleware.CreateMapping('lastChangedBy', 'ModifiedBy'),
    Middleware.CreateMapping('lastChangedDateTime', 'ModifiedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : ''))
];

const inactivityHistoryMapping = [
    Middleware.CreateMapping('lastUpdatedDateTime', 'LastUpdatedDateTime', (e) =>
        e ? DuDateUtilities.FormatDateFromIso(new Date(e).toISOString()) : ''
    ),
    Middleware.CreateMapping('lastChangedBy', 'LastChangedBy'),
    Middleware.CreateMapping('inactiveSince', 'InactiveSince', (e) => (e ? DuDateUtilities.FormatDateFromIso(new Date(e).toISOString()) : '')),
    Middleware.CreateMapping('inactiveUntil', 'InactiveUntil', (e) => (e ? DuDateUtilities.FormatDateFromIso(new Date(e).toISOString()) : '')),
    Middleware.CreateMapping('comodityTypeId', 'ComodityTypeId'),
    Middleware.CreateMapping('commodityType', 'CommodityType'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('chemicalList', 'GET')) {
        Middleware.RegisterMapping('chemicalList', 'GET', chemicalListMapping);
        Middleware.RegisterMapping('chemicalList', 'POST', chemicalListMapping);
    }

    if (!Middleware.CheckMappingExists('frequentlySearchedChemicals', 'GET')) {
        Middleware.RegisterMapping('frequentlySearchedChemicals', 'GET', frequentlySearchedChemicalsMapping);
        Middleware.RegisterMapping('frequentlySearchedChemicals', 'POST', frequentlySearchedChemicalsMapping);
    }

    if (!Middleware.CheckMappingExists('addChemical', 'POST')) {
        Middleware.RegisterMapping('addChemical', 'POST', addChemicalMapping);
    }

    if (!Middleware.CheckMappingExists('editChemical', 'POST')) {
        Middleware.RegisterMapping('editChemical', 'POST', editChemicalMapping);
    }

    if (!Middleware.CheckMappingExists('inactivateChemical', 'POST')) {
        Middleware.RegisterMapping('inactivateChemical', 'POST', inactivateChemicalMapping);
    }

    if (!Middleware.CheckMappingExists('inactivityHistory', 'GET')) {
        Middleware.RegisterMapping('inactivityHistory', 'GET', inactivityHistoryMapping);
    }
}

const chemicalListGroup = {
    ConfigureMiddleWare
};

export default chemicalListGroup;
