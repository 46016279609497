import { HOLD_PERIOD } from '../constants/constant';

export const holdPeriodReducer = (safetyHolds = [], action) => {
    switch (action.type) {
        case HOLD_PERIOD:
            return action.payload;
        default:
            return safetyHolds;
    }
};
