import React, { useState, useEffect } from 'react';
import { Middleware } from 'one-ring';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';

import { setChemicalList, setChemical, setChemicalDetails, clearChemicalDetails } from './../../../../actions/chemicalMaintenanceActions';

import APIEndPoints from './../../../../services/api';

import { DrcPanel, DrcButton, DrcSelect, DrcPageWarning } from 'driscolls-react-components';
import { Grid } from '@material-ui/core';
import AddChemical from './AddChemical';
import EditChemical from './EditChemical';

let styles = (theme) => ({
    panel: {
        border: `none`,
        marginTop: `1rem`,
        borderRadius: 2,
        boxShadow: `0 2px 4px 0 #C3C3C3`
    }
});

const ChemicalList = (props) => {
    const { classes, isAdmin, chemicalList, setChemical, setChemicalDetails, chemical } = props;

    const [input, setInput] = useState({
        chemical: '',
        errorMessage: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [onError, setOnError] = useState(false);

    const onLoad = () => {
        if (!(chemical || {}).value || (input.chemical || {}).value) {
            setInput({
                chemical: '',
                errorMessage: ''
            });

            return;
        }
        if (JSON.stringify(input.chemical) === JSON.stringify(chemical)) {
            props.handleChemicalSelect(chemical);
            return;
        }
        props.handleChemicalSelect(chemical);
        getChemicalDetails(chemical);
    };

    useEffect(onLoad, []); //default load

    useEffect(() => {
        setInput({
            chemical: chemical,
            errorMessage: ''
        });
    }, [chemical]); //when selected chemical changes

    const handleAdd = () => {
        setIsAdd(true);
    };

    const handleEdit = () => {
        setIsEdit(true);
    };

    const handleAddClose = async (option) => {
        setIsAdd(false);
        //for newly added chemical scenario
        if (option) {
            await getChemicalDetails(option);

            showErrorMessage(`Added "${option.label}" successfully`);
            setOnError(false);
        }
    };

    const handleEditClose = (option) => {
        setIsEdit(false);

        if (option) {
            showErrorMessage(`Updated "${option.label}" successfully`);
            setOnError(false);
        }
    };

    const handleSelectChanged = async (option) => {
        await getChemicalDetails(option);
        props.handleChemicalSelect(option);
    };

    const getChemicalDetails = (option) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send('chemicalDetails', token, APIEndPoints.GET_CHEMICAL_DETAILS(option.value), 'GET')
                .then((data) => {
                    if (data.status && data.status !== 200) {
                        showErrorMessage('Failed to load the chemical details');
                        setOnError(true);
                    } else {
                        props.clearChemicalDetails();
                        setChemicalDetails(data);
                        setChemical(option);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage('Failed to load the chemical details');
                    setOnError(true);
                });
        });
    };

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    return (
        <div>
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}
            <DrcPanel maxWidth="100%" className={`${classes.panel}`}>
                <Grid container spacing={5} justify="space-between" alignItems="flex-start">
                    <Grid item xs={12} sm={9} md={9} lg={9}>
                        <h1 className={`${classes.title}`}>AI MAINTENANCE</h1>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                        {isAdmin && (
                            <DrcButton isSecondary floatRight onClick={handleAdd} className={`${classes.addButton}`}>
                                Add New
                            </DrcButton>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={5} justify="space-between" alignItems="flex-start" className={`${classes.grid}`}>
                    <Grid item xs={6} sm={4} md={4} lg={4} className={`${classes.selectionGrid}`}>
                        <DrcSelect
                            label={`Chemical Name`}
                            options={chemicalList}
                            isOptionDisabled={(item) => !item.isActive}
                            placeholder={`Select a Chemical`}
                            onChange={(option) => {
                                handleSelectChanged(option);
                            }}
                            value={input.chemical}
                            maxMenuHeight={240}
                        />
                    </Grid>

                    {isAdmin && (input.chemical || {}).value && (
                        <Grid item xs={6} sm={8} md={8} lg={8} style={{ paddingLeft: 0 }}>
                            <DrcButton color="primary" onClick={handleEdit}>
                                Edit
                            </DrcButton>
                        </Grid>
                    )}
                </Grid>
            </DrcPanel>
            {/* Action dialogs */}
            {isAdd && <AddChemical open={isAdd} onClose={handleAddClose} />}
            {isEdit && <EditChemical open={isEdit} onClose={handleEditClose} />}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        chemical: state.chemicalMaintenance.chemical,
        chemicalList: state.chemicalMaintenance.chemicalList,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        isAdmin: state.masterReducer.isAdmin
    };
};

const mapDispatchToProps = (dispatch) => ({
    setChemicalDetails: (data) => dispatch(setChemicalDetails(data)),
    clearChemicalDetails: () => dispatch(clearChemicalDetails()),
    setChemicalList: (data) => dispatch(setChemicalList(data)),
    setChemical: (data) => dispatch(setChemical(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(ChemicalList)));
