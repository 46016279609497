import { Middleware } from 'one-ring';
import { DuDateUtilities } from 'driscolls-react-utilities';

const getChemicalApplicationsMapping = [
    Middleware.CreateMapping('TransactionId', 'TransactionId'),
    Middleware.CreateMapping('ChemicalActivityTypeId', 'ChemicalActivityTypeId'),
    Middleware.CreateMapping('ApplicationType', 'ApplicationType'),
    Middleware.CreateMapping('BlockDesignator', 'BlockDesignator'),
    Middleware.CreateMapping('ActiveIngredient', 'ActiveIngredient'),
    Middleware.CreateMapping('RanchNumber', 'RanchNumber'),
    Middleware.CreateMapping('RanchNbr', 'RanchNbr'),
    Middleware.CreateMapping('MrlBlock', 'MrlBlock'),
    Middleware.CreateMapping('EventStartDate', 'EventStartDate', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('EventEndDate', 'EventEndDate', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('WeekEndDate', 'WeekEndDate', (e) => (e ? DuDateUtilities.FormatDateFromIso(e) : '')),
    Middleware.CreateMapping('DocumentId', 'DocumentId'),
    Middleware.CreateMapping('Comments', 'Comments'),
    Middleware.CreateMapping('IsoEventDateTime', 'IsoEventDateTime'),
    Middleware.CreateMapping('IsoRecordedDateTime', 'IsoRecordedDateTime'),
    Middleware.CreateMapping('IsoStartDateTime', 'IsoStartDateTime'),
    Middleware.CreateMapping('IsoEndDateTime', 'IsoEndDateTime'),
    Middleware.CreateMapping('SourceSystem', 'SourceSystem'),
    Middleware.CreateMapping('SourceUser', 'SourceUser'),
    Middleware.CreateMapping('TransactionDateTime', 'TransactionDateTime'),
    Middleware.CreateMapping('SourceDocumentId', 'SourceDocumentId'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateMapping('ChemicalId', 'ChemicalId'),
    Middleware.CreateMapping('ChemicalName', 'ChemicalName'),
    Middleware.CreateMapping('RatePerArea', 'RatePerArea'),
    Middleware.CreateMapping('Units', 'Units'),
    Middleware.CreateMapping('TreatedArea', 'TreatedArea'),
    Middleware.CreateMapping('AreaTypeId', 'AreaTypeId'),
    Middleware.CreateMapping('IsActive', 'IsActive'),
    Middleware.CreateMapping('AreaTypeId', 'AreaTypeId'),
    Middleware.CreateMapping('AreaType', 'AreaType'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifieddateTime', 'ModifieddateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('Blocks', 'Blocks'),
    Middleware.CreateMapping('Application', 'Application'),
    Middleware.CreateMapping('NoApplication', 'NoApplication'),
    Middleware.CreateMapping('Restrictions', 'Restrictions'),
    Middleware.CreateMapping('TransactionType', 'TransactionType'),
    Middleware.CreateMapping('TransactionLevel', 'TransactionLevel'),
    Middleware.CreateMapping('EventDate', 'EventDate'),
    Middleware.CreateMapping('TransInfo', 'TransInfo'),
    Middleware.CreateMapping('plantedAreaType', 'PlantedAreaUnit'),
    Middleware.CreateMapping('totalPlantedArea', 'TotalPlantedArea'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('getChemicalApplications', 'GET')) {
        Middleware.RegisterMapping('getChemicalApplications', 'GET', getChemicalApplicationsMapping);
        Middleware.RegisterMapping('getChemicalApplications', 'POST', getChemicalApplicationsMapping);
        Middleware.RegisterMapping('getChemicalApplications', 'PUT', getChemicalApplicationsMapping);
        Middleware.RegisterMapping('getChemicalApplications', 'DELETE', getChemicalApplicationsMapping);
    }
}

const applicationDetailsGroup = {
    ConfigureMiddleWare
};

export default applicationDetailsGroup;
