import {
    HOLD_PERIOD,
    ADD_HOLD_PERIOD,
    FOOD_SAFETY_HOLD,
    CHEMICAL_LIST,
    CHEMICAL_DETAILS,
    CLEAR_CHEMICAL_DETAILS,
    ACTIVE_INGREDIENT_LIST,
    EDIT_ACTIVE_INGREDIENT,
    CHEMICAL
} from '../constants/constant';

export const addHoldPeriod = (addData) => ({
    type: ADD_HOLD_PERIOD,
    payload: addData
});

export const holdPeriod = (data) => ({
    type: HOLD_PERIOD,
    payload: data
});

export const foodSafetyHoldData = (data) => ({
    type: FOOD_SAFETY_HOLD,
    payload: data
});

export const setChemicalList = (data) => ({
    type: CHEMICAL_LIST,
    payload: data
});

export const setChemicalDetails = (data) => ({
    type: CHEMICAL_DETAILS,
    payload: data
});

export const clearChemicalDetails = () => ({
    type: CLEAR_CHEMICAL_DETAILS
});

export const setChemical = (data) => ({
    type: CHEMICAL,
    payload: data
});

export const setActiveIngredients = (data) => ({
    type: ACTIVE_INGREDIENT_LIST,
    payload: data
});

export const editActiveIngredients = (index, newVal) => ({
    type: EDIT_ACTIVE_INGREDIENT,
    payload: { index, newVal }
});
