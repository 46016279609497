import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Security, ImplicitCallback } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import { Middleware } from 'one-ring';
import {
    DrcThemeProvider,
    DrcSecureHeader,
    DrcSecureGroupRoute,
    DrcPageNotFound,
    DrcBackdrop,
    DrcDialog,
    DrcButton,
    DrcEnvironmentMarker,
    DrcLoading,
    DrcMain,
    DrcPanel,
    Helmet,
    DrcImage,
    DrcMediaQueries,
    DrcThemeUtilities,
    DrcPageNotAuthorized,
    DrcPageLogin,
    DrcVersionInfo,
    DrcTranslations
} from 'driscolls-react-components';
import { DuAuthenticationUtilities, DuThemeUtilities, DuCommonTranslations } from 'driscolls-react-utilities';
import { hideErrorDialogAction, showLoadingScreenAction, hideLoadingScreenAction, serviceWorkerUpdate } from './actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized } from './actions/masterActions';
import LogOut from './pages/LogOut';
import InitializeApplication from './pages/InitializeApplication';
import ChemicalApplication from './pages/ChemicalApplication/ChemicalApplication';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import { setPageTitleAction } from './actions/actions';
import MasterDataUtilities from './data/MasterDataUtilities';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import './custom.css';

const Releases = lazy(() => import('./pages/Releases'));
const allLinks = [];

let styles = (theme) => ({
    '@global': {
        /* width */
        '::-webkit-scrollbar': {
            width: '10px',
            height: '10px',
            cursor: 'pointer'
        },
        /* Track */
        '::-webkit-scrollbar-track': {
            backghround: 'none',
            borderRadius: '2px',
            cursor: 'pointer'
        },
        /* Handle */
        '::-webkit-scrollbar-thumb': {
            backgroundColor: '#bbb',
            borderRadius: '2px',
            cursor: 'pointer'
        }
    },
    logo: {
        height: theme.spacing(5),
        marginRight: theme.spacing(3),
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        },
        '@media (prefers-color-scheme: dark)': {
            filter: 'none'
        }
    },
    header: {
        '& .toolbar': {
            minHeight: theme.spacing(8),
            height: theme.spacing(8),
            maxHeight: theme.spacing(10),
            backgroundColor: '#FFF',
            textShadow: `none`,
            '@media (prefers-color-scheme: dark)': {
                backgroundColor: 'hsl(0, 0%, 0%)',
                '& .title': {
                    textShadow: 'none',
                    color: 'hsla(341, 57%, 90%, 1)'
                },
                '& .menuButton': {
                    border: '1px solid hsla(341, 57%, 90%, 1)'
                },
                '& .menuButton svg': {
                    color: 'hsla(341, 57%, 90%, 1)'
                }
            }
        },
        '& .title': {
            textShadow: `none`,
            fontSize: 22,
            [theme.breakpoints.down('xs')]: {
                width: '88%'
            }
        },
        '& .appBar': {
            boxShadow: `none`,
            fontSize: theme.spacing(2.5)
        },
        '& .logOut': {
            right: 0,
            position: `absolute`,
            top: theme.spacing(0.8),
            paddingRight: theme.spacing(6),
            '& a:hover': {
                backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 80)
            },
            [theme.breakpoints.down('xs')]: {
                paddingRight: '0!important'
            }
        },
        '& .logOutLink': {
            color: DuThemeUtilities.DefaultColors.primary.green,
            border: 'none',
            [theme.breakpoints.down('xs')]: {
                margin: '11px 0 8px 8px!important'
            }
        },
        '& .logOutTitle': {
            paddingLeft: theme.spacing(1),
            fontSize: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    }
});

const adminGroups = window.config.OKTA_ADMIN_GROUPS || [];

const SITE_NAME = 'FACTS';
const SITE_ACRONYM = 'FACTS - ';

let defaultTheme = DrcThemeUtilities.CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.green);
defaultTheme = {
    ...defaultTheme,
    typography: {
        ...defaultTheme.typography,
        fontFamily: 'Rubik,' + defaultTheme.typography.fontFamily
    }
};

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            theme: defaultTheme
        };

        //Initializing the Internationalization
        this.props.initialize({
            languages: DuCommonTranslations.LANGUAGES,
            translation: { ...DuCommonTranslations.COMMON_TRANSLATIONS, ...DrcTranslations.DRC_PAGE_DATA_MAINTENANCE_TRANSLATIONS },
            options: { renderToStaticMarkup }
        });

        this.closeErrorDialog = this.closeErrorDialog.bind(this);
        MasterDataUtilities.Register(this.props.setInitializeRedirectUrl, this.props.setMasterDataInitialized);
        // LoggingUtilities.Register(this.props.addInfo);
        Middleware.SetProps(this.props.showLoadingScreenAction, this.props.hideLoadingScreenAction, this.props.setErrorsAction);
    }

    closeErrorDialog() {
        this.props.hideErrorDialogAction();
    }

    updateServiceWorker = () => {
        const registrationWaiting = this.props.serviceWorkerRegistration.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

            registrationWaiting.addEventListener('statechange', (e) => {
                if (e.target.state === 'activated') {
                    this.props.serviceWorkerUpdate();
                    window.location.href = '/logOut';
                }
            });
        }
    };

    closeNewVersionDialog = () => {
        this.props.serviceWorkerUpdate();
    };

    render() {
        const { classes } = this.props;
        return (
            <DrcThemeProvider theme={this.state.theme}>
                <DrcImage
                    src={require('./images/Soft_Pastel.png')}
                    webp={require('./images/Soft_Pastel.webp')}
                    style={{
                        position: 'fixed',
                        zIndex: -1,
                        width: '100vw',
                        height: '100vh',
                        objectFit: 'cover'
                    }}
                    alt=""
                />
                <DrcEnvironmentMarker />
                <Helmet>
                    <title>{this.props.pageTitle.length > 0 ? SITE_ACRONYM + this.props.pageTitle : SITE_NAME}</title>
                </Helmet>
                <Router history={Router.browserHistory}>
                    <Security
                        issuer={window.config.OKTA_ISSUER}
                        client_id={window.config.OKTA_CLIENT_ID}
                        redirect_uri={window.location.origin + '/implicit/callback'}
                        onAuthRequired={DuAuthenticationUtilities.OnAuthRequired}
                        auto_renew={true}
                        scope={['openid', 'profile', 'email', 'MulesoftAPIAccess']}
                    >
                        <DrcSecureHeader
                            title={SITE_NAME + (this.props.pageTitle.length > 0 ? ' - ' + this.props.pageTitle : '')}
                            allLinks={allLinks}
                            fullWidth={true}
                            logo={
                                <DrcImage
                                    src={require('./images/Logo_Small_Transparent.png')}
                                    webp={require('./images/Logo_Small_Transparent.webp')}
                                    className={this.props.classes.logo}
                                    alt="Driscoll's Logo"
                                />
                            }
                            className={classes.header}
                        >
                            {this.props.pageTitle ? (
                                <span className="logOut">
                                    <DrcButton className="logOutLink" to="/LogOut/">
                                        <ExitToAppRoundedIcon className="appBar" />
                                        <span className="logOutTitle">Logout</span>
                                    </DrcButton>
                                </span>
                            ) : null}
                        </DrcSecureHeader>
                        <Suspense
                            fallback={
                                <DrcMain transparent>
                                    <DrcPanel>
                                        <DrcLoading />
                                    </DrcPanel>
                                </DrcMain>
                            }
                        >
                            <Switch>
                                <Route
                                    path="/"
                                    exact
                                    render={(props) => (
                                        <DrcPageLogin
                                            {...props}
                                            setPageTitle={this.props.setPageTitleAction}
                                            backgroundPng={require('./images/canvas.png')}
                                            backgroundWebP={require('./images/canvas.png')}
                                        />
                                    )}
                                />
                                <DrcSecureGroupRoute path="/Releases/" exact component={Releases} groupsAllowed={adminGroups} />
                                <DrcSecureGroupRoute path="/App" component={ChemicalApplication} />
                                <DrcSecureGroupRoute path="/InitializeApplication" exact component={InitializeApplication} />
                                <Route path="/implicit/callback" component={ImplicitCallback} />
                                <Route path="/LogOut/" exact component={LogOut} />
                                <Route path="/NotAuthorized/" exact component={DrcPageNotAuthorized} />
                                <Route component={DrcPageNotFound} />
                            </Switch>
                        </Suspense>
                    </Security>
                    <DrcVersionInfo allowClick={this.props.isAdmin} />
                    <DrcBackdrop isLoading show={this.props.showLoadingScreen} />
                    <DrcDialog
                        isError
                        title={this.props.errorDialog.title}
                        open={this.props.errorDialog.show}
                        buttons={
                            <DrcButton isError onClick={this.closeErrorDialog}>
                                OK
                            </DrcButton>
                        }
                    >
                        {this.props.errorDialog.content}
                    </DrcDialog>
                    <DrcDialog
                        title={'New version available'}
                        open={this.props.serviceWorkerUpdated}
                        buttons={
                            <>
                                <DrcButton onClick={this.closeNewVersionDialog}>Close</DrcButton>
                                <DrcButton isPrimary onClick={this.updateServiceWorker}>
                                    Ok
                                </DrcButton>
                            </>
                        }
                    >
                        <div>Refresh to update your app?'</div>
                    </DrcDialog>
                </Router>
            </DrcThemeProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.home.showLoadingScreen,
        errorDialog: state.home.errorDialog,
        pageTitle: state.home.pageTitle,
        isAdmin: state.masterReducer.isAdmin,
        serviceWorkerRegistration: state.root.serviceWorkerRegistration,
        serviceWorkerUpdated: state.root.serviceWorkerUpdated
    };
}

// const mapDispatchToProps = dispatch => ({
//   hideErrorDialog: () => dispatch(hideErrorDialogAction())
// });

export default connect(mapStateToProps, {
    hideErrorDialogAction,
    setMasterDataInitialized,
    setInitializeRedirectUrl,
    showLoadingScreenAction,
    hideLoadingScreenAction,
    setPageTitleAction,
    serviceWorkerUpdate
})(withLocalize(withStyles(styles)(App)));
