import { withStyles } from '@material-ui/core/styles';
import { withAuth } from '@okta/okta-react';
import { DrcButton, DrcDataGrid, DrcDatePicker, DrcDialog, DrcTooltip, DrcPageWarning } from 'driscolls-react-components';
import { DuAuthenticationUtilities, DuDateUtilities } from 'driscolls-react-utilities';
import { Middleware } from 'one-ring';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setPageTitleAction } from '../../actions/actions';
import { setErrorList } from '../../actions/errorListActions';
import DrcPageDataMaintenance from '../../components/drc/DrcPageDataMaintenance';
import APIEndPoints from '../../services/api';
import GridStyles from '../../styles/gridStyles';
import MasterDataUtilities from './../../data/MasterDataUtilities';
const pageTitle = 'Errors Listing';

let styles = (theme) => ({
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 418px)', 'calc(100vh - 300px)'),
    panel: {
        maxWidth: 'calc(100vw - 40px) !important',
        border: `none`,
        marginTop: `1rem`,
        borderRadius: 2,
        boxShadow: `0 2px 4px 0 #C3C3C3`,
        paddingTop: '30x'
    },
    tabItem: {
        marginTop: theme.spacing(1.5)
    },
    tabIcon: {
        paddingTop: theme.spacing(1),
        fontSize: 24,
        transform: 'translateY(4px)'
    },
    filterContainer: {
        background: '#FAFAF9',
        display: 'flex',
        width: '100%',
        [theme.darkTheme]: {
            background: `none !important`
        }
    },
    rightContainer: {
        display: 'flex',
        width: '70%',
        justifyContent: 'flex-end',
        paddingTop: '10px'
    },
    leftContainer: {
        display: 'flex',
        width: '30%',
        [theme.breakpoints.down('xs')]: {
            width: '90%'
        },
    },
    selectedClass: {
        width: '45px !important',
        padding: '0 10px !important'
    }
});

const ErrorsListing = (props) => {
    let saturday = new Date(new Date().setDate(new Date().getDate() + 6 - new Date().getDay()));
    const [weekendDate, setWeekendDate] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [reviewDialog, setReviewDialog] = useState(false);
    const { classes, isMasterDataInitialized, setPageTitle } = props;
    const [refresh, setRefresh] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState(false);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [isWritable, setIsWritable] = useState('');

    const adminGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || []);

    const dateTimeFormatter = (value) => {
        return (
            <DrcTooltip tipText={value ? DuDateUtilities.ToPrettyDateTime(value) : ''}>
                <span>{value ? DuDateUtilities.ToPrettyDateTime(value) : ''}</span>
            </DrcTooltip>
        );
    };

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const cellFormatter = (value) => {
        return (
            <DrcTooltip tipText={value || ''}>
                <div
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    <span>{value || ''}</span>
                </div>
            </DrcTooltip>
        );
    };

    const columns = [
        {
            key: 'ReviewedBy',
            name: 'Reviewed By',
            width: 180,
            isDisabled: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.ReviewedBy)
        },
        {
            key: 'ErrorDate',
            name: 'Error Date',
            sortable: true,
            width: 180,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.ErrorDate)
        },
        {
            key: 'ErrorType',
            name: 'Error Type',
            width: 100,
            isDisabled: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.ErrorType)
        },
        {
            key: 'ErrorMsg',
            name: 'Error Message',
            width: 180,
            isDisabled: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.ErrorMsg)
        },
        {
            key: 'ErrorSource',
            name: 'Error Source',
            width: 100,
            isDisabled: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.ErrorSource)
        },
        {
            key: 'DocID',
            name: 'Doc Id',
            width: 100,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.DocID)
        },
        {
            key: 'CountyName',
            name: 'County Name',
            width: 100,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.CountyName)
        },
        {
            key: 'RanchNumber',
            name: 'Ranch',
            width: 180,
            isDisabled: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.RanchNumber)
        },
        {
            key: 'GrowerName',
            name: 'Grower Name',
            width: 180,
            isDisabled: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.GrowerName)
        },
        {
            key: 'ReviewDate',
            name: 'Review Date',
            width: 180,
            isDisabled: true,
            filter: true,
            columnTemplate: (row) => dateTimeFormatter(row.ReviewDate)
        }
    ];

    const selectedDate = () => {
        //select only upcoming Saturdays
        return new Date(new Date().setDate(new Date().getDate() + 6 - new Date().getDay()));
    };

    useEffect(() => {
        setPageTitle(pageTitle);
        swapClass('col-xs-6', 'col-xs-3');
        swapClass('col-xs-6', 'col-xs-9');
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        async function isUserWritable() {
            let token = await props.auth.getAccessToken();
            setIsWritable(DuAuthenticationUtilities.IsInGroup(token, adminGroups));
        }
        isUserWritable();
        let dt = new Date(selectedDate());
        let localWeekendDate = dt ? new Date(dt.getTime()) : null;
        let startDate = localWeekendDate ? DuDateUtilities.ToIsoDate(getSunday(localWeekendDate)) : null;
        let endDate = localWeekendDate ? DuDateUtilities.ToIsoDate(dt) : null;
        setWeekendDate(endDate);

        getErrorList(startDate, endDate);
    }, [setPageTitle, isMasterDataInitialized]);

    const swapClass = (classToTRemove, classToAdd) => {
        document.getElementsByClassName(classToTRemove)[0].classList.add(classToAdd);
        document.getElementsByClassName(classToTRemove)[0].classList.remove(classToTRemove);
    };

    const getErrorList = async (startDate = null, endDate = null) => {
        let token = await props.auth.getAccessToken();
        let payload =
            startDate !== 'N/A'
                ? {
                      FromErrorDate: startDate,
                      ToErrorDate: endDate
                  }
                : {};
        let response = await Middleware.Send('errorlisting', token, APIEndPoints.ERROR_LIST, 'POST', payload, {
            overrideRequestMapping: true,
            overrideResponseMapping: true
        });
        props.setErrorList(response.errors);
    };

    const handleMultiSelection = (e) => {};

    const shouldDisableDate = (date) => {
        //disable all days except Saturday
        return date.getDay() !== 6;
    };

    const datepickerChange = (date) => {
        setWeekendDate(date);
        // check if date is null
        if (date) setIsValid(true);
        else {
            searchByPoolWeek();
            setIsValid(false);
        }
    };

    const searchByPoolWeek = () => {
        let dt = typeof weekendDate === 'string' ? new Date(weekendDate) : weekendDate;
        let localWeekendDate = dt ? new Date(dt.getTime()) : null;
        let startDate = localWeekendDate ? DuDateUtilities.ToIsoDate(getSunday(localWeekendDate)) : null;
        let endDate = localWeekendDate ? DuDateUtilities.ToIsoDate(dt) : null;
        getErrorList(startDate, endDate);
    };

    const getSunday = (today) => {
        const first = today.getDate() - today.getDay();
        const last = first;
        const sunday = new Date(today.setDate(last));
        return sunday;
    };

    const updateRefresh = () => {};

    const handleDelete = (delObj) => {
        let records = Object.values(delObj);
        props.auth.getAccessToken().then((token) => {
            let payload = [];
            payload = records.map((record) => {
                return { Id: record.Id, IsActive: false };
            });

            Middleware.Send('errorlisting', token, APIEndPoints.ERROR_MODIFICATION, 'POST', payload, {
                overrideResponseMapping: true,
                overrideRequestMapping: true
            })
                .then((data) => {
                    if (data && data.status) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        showErrorMessage('Marked selected items as completed!');
                        setOnError(false);
                        refreshGrid();
                    }
                })
                .catch((error) => {
                    showErrorMessage('Failed to set the selected items to completed status!');
                    setOnError(true);
                });
        });
    };

    const onReviewConfirm = (reviewObj) => {
        let records = Object.values(reviewObj);
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            let payload = [];
            payload = records.map((record) => {
                return { Id: record.Id, ReviewedBy: loggedInUser, ReviewDate: new Date().toISOString(), IsActive: true };
            });

            Middleware.Send('errorlisting', token, APIEndPoints.ERROR_MODIFICATION, 'POST', payload, {
                overrideResponseMapping: true,
                overrideRequestMapping: true
            })
                .then((data) => {
                    if (data && data.status) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        showErrorMessage('Marked selected items as reviewed!');
                        setOnError(false);
                        refreshGrid();
                    }
                })
                .catch((error) => {
                    showErrorMessage('Failed to set the selected items to review status!');
                    setOnError(true);
                });
        });
    };

    const refreshGrid = () => {
        props.setErrorList([]);
        searchByPoolWeek();
    };

    const refreshData = (data) => {
        props.setErrorList([]);
        setTimeout(() => {
            props.setErrorList(data);
        }, 1);
    };

    const gridDataFormatter = (data) => {
        if (data.length === 0) {
            return [];
        }

        const { DaysToShip, HoldAreaId, CommodityId, Mrl, Comments } = data[0];
        let modifiedData;

        let date = new Date().toISOString();
        if (Array.isArray(CommodityId)) {
            modifiedData = (CommodityId || []).map((record) => {
                return {
                    AreaCode: ((props.countryListOptions || []).find((holdArea) => holdArea.value === HoldAreaId) || {}).label,
                    Commodity: MasterDataUtilities.RenderCommodityOptions(record),
                    Mrl: Mrl,
                    Comments: Comments,
                    DaysToShip: DaysToShip,
                    CreatedBy: loggedInUser,
                    CreatedDateTime: date,
                    LastChangeBy: loggedInUser,
                    LastChangedDateTime: date
                };
            });
        } else {
            modifiedData = [
                {
                    AreaCode: ((props.countryListOptions || []).find((holdArea) => holdArea.value === HoldAreaId) || {}).label,
                    Commodity: MasterDataUtilities.RenderCommodityOptions(
                        (props.commodityTypeOptions || []).find((el) => el.value === CommodityId) || {}
                    ),
                    DaysToShip: DaysToShip,
                    Mrl: Mrl,
                    Comments: Comments,
                    CreatedBy: loggedInUser,
                    CreatedDateTime: date,
                    LastChangeBy: loggedInUser,
                    LastChangedDateTime: date
                }
            ];
        }
        return modifiedData;
    };

    return (
        <div>
            <DrcPageDataMaintenance
                hideCount={true}
                className={`${classes.grid} ${classes.dialog}`}
                textOptions={{
                    PageTitle: '',
                    DeleteBtn: 'COMPLETE',
                    ReviewBtn: 'REVIEW',
                    deleteTypeConfirmTitle: 'Mark the selected items as completed?',
                    reviewTypeConfirmTitle: 'Mark the selected items as Reviewed?',
                    SelectAllBtn: 'SELECT ALL',
                    UnselectAllBtn: 'UNSELECT ALL'
                }}
                customButtons={
                    <div className={classes.leftContainer}>
                        <DrcDatePicker
                            label={'For Week Of'}
                            required
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            value={weekendDate}
                            selectedDate={selectedDate}
                            onChange={datepickerChange}
                            shouldDisableDate={shouldDisableDate}
                        />
                        <DrcButton style={{ marginTop: 5 }} isPrimary floatRight disabled={!isValid} onClick={() => searchByPoolWeek()}>
                            SEARCH
                        </DrcButton>
                    </div>
                }
                settings={{
                    ActionColumnSetting: 0,
                    EnableExport: false,
                    ShowCount: false,
                    EnableAdd: false,
                    EnableReview: props.isAdmin || isWritable,
                    EnableEdit: false,
                    EnableDelete: false,
                    SelectAll: true,
                    EnableCheckBoxDelete: {
                        access: props.isAdmin || isWritable,
                        key: 'Id'
                    }
                }}
                fullWidth={true}
                minHeight={300}
                refreshData={refreshData}
                type={pageTitle}
                columns={columns}
                refresh={refresh}
                updateRefresh={updateRefresh}
                data={props.errorList}
                onReviewConfirm={onReviewConfirm}
                onCheckboxDelete={handleDelete}
                gridDataFormatter={gridDataFormatter}
                columnKey={'Id'}
                loading={false}
                pageSize={10}
                paginator
            ></DrcPageDataMaintenance>
            {reviewDialog ? (
                <DrcDialog
                    open={reviewDialog}
                    title={'Mark the selected items as reviewed?'}
                    buttons={
                        <div>
                            <DrcButton isSecondary onClick={() => setReviewDialog(false)} floatRight>
                                Oops, Just Kidding
                            </DrcButton>
                            <DrcButton isPrimary onClick={() => {}} floatRight>
                                REVIEW
                            </DrcButton>
                        </div>
                    }
                >
                    <DrcDataGrid
                        rows={selectedEntities}
                        columns={columns.map((c) => {
                            return { ...c, filter: false, sortable: false };
                        })}
                        hideCount={true}
                    />
                </DrcDialog>
            ) : null}
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        errorList: state.errorListReducer.errorList,
        isAdmin: state.masterReducer.isAdmin,
        isMasterDataInitialized: state.masterReducer.isInitialized
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setErrorList: (list) => dispatch(setErrorList(list))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(ErrorsListing)));
