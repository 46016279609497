import React from 'react';

class DrcIconLabel extends React.Component {
    render() {
        const { className, style, titleStyle, icon, children } = this.props;

        return (
            <span className={className} style={style}>
                {icon}
                <span style={{ marginLeft: '10px', ...titleStyle }}>{children}</span>
            </span>
        );
    }
}

export default DrcIconLabel;
