import chemicalTypesGroup from './chemicalType';
import countryRestrictionTypesGroup from './countryRestriction';
import applicationDetailsGroup from './applicationDetails';
import holdAreaTypesGroup from './holdPeriod';
import chemicalCommodityTypesGroup from './chemicalCommodity';
import activeIngredientDetailsGroup from './activeIngredientDetails';
import chemicalListGroup from './chemicalList';
import chemicalDetailsGroup from './chemicalDetails';
import commodityTypesGroup from './commodityType';
import holdDetails from './holdDetails';

import MasterData from './masterData';

import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import { Middleware } from 'one-ring';
import ranchDetailsTypesGroup from './ranchDetails';
import blocksAndHolds from './blocksAndHolds';

function ConfigureAllMiddleware() {
    chemicalTypesGroup.ConfigureMiddleWare();
    countryRestrictionTypesGroup.ConfigureMiddleWare();
    applicationDetailsGroup.ConfigureMiddleWare();
    holdAreaTypesGroup.ConfigureMiddleWare();
    chemicalCommodityTypesGroup.ConfigureMiddleWare();
    activeIngredientDetailsGroup.ConfigureMiddleWare();
    chemicalListGroup.ConfigureMiddleWare();
    chemicalDetailsGroup.ConfigureMiddleWare();
    commodityTypesGroup.ConfigureMiddleWare();
    MasterData.ConfigureMiddleWare();
    ranchDetailsTypesGroup.ConfigureMiddleWare();
    holdDetails.ConfigureMiddleWare();
    blocksAndHolds.ConfigureMiddleWare();

    Middleware.RegisterDefaultPayloadHandler((token) => {
        return {
            ModifiedBy: DuAuthenticationUtilities.GetUserId(token) || 'Bad Token',
            ModifiedDateTime: new Date().toISOString()
        };
    });
}

const data = {
    ConfigureAllMiddleware
};

export default data;
