import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Middleware } from 'one-ring';
import { withAuth } from '@okta/okta-react';

import { setPageTitleAction } from './../../actions/actions';
import { setActiveIngredients } from './../../actions/chemicalMaintenanceActions';

import APIEndPoints from '../../services/api';

import ActiveIngredientDetails from '../../components/Maintenance/ActiveIngredient/ActiveIngredientDetail';
import MasterDataUtilities from './../../data/MasterDataUtilities';

const pageTitle = 'Active Ingredients Map';
const PAGE_TYPE = 'activeIngredientDetails';

const ActiveIngredient = (props) => {
    const { isMasterDataInitialized, setPageTitle } = props;

    const getData = useCallback(() => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.ACTIVE_INGREDIENTS, 'GET').then((data) => {
                let activeIngredients = (data.Results || []).filter((item) => item.isActive);
                if (!activeIngredients) {
                    activeIngredients = [];
                }
                props.setActiveIngredients(activeIngredients);
            });
        });
    }, [props]);

    useEffect(() => {
        setPageTitle(pageTitle);
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }
    }, [isMasterDataInitialized, setPageTitle]);

    useEffect(getData, []);
    return <ActiveIngredientDetails getData={getData} />;
};

const mapStateToProps = (state) => {
    return {
        isMasterDataInitialized: state.masterReducer.isInitialized,
        activeIngredients: state.chemicalMaintenance.activeIngredients
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setActiveIngredients: (data) => dispatch(setActiveIngredients(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(ActiveIngredient));
