import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withAuth } from '@okta/okta-react';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { DrcSelect, DrcButton, DrcInput, DrcPageWarning, DrcSwitch } from 'driscolls-react-components';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import { setPageTitleAction } from '../../actions/actions';
import { Middleware } from 'one-ring';
import APIEndPoints from '../../services/api';

let styles = (theme) => ({
    container: {
        maxWidth: 800,
        margin: '20px auto 10px'
    },
    override: {
        marginTop: 5
    }
});

const PAGE_TITLE = 'Document Reprocess';

const DocumentReprocess = (props) => {
    const { classes, isMasterDataInitialized } = props;
    const [selectedType, setSelectedType] = useState({ value: 'PUR', label: 'Application' });
    const [message, setMessage] = useState({ isSuccess: true, message: '' });
    const [documentId, setDocumentId] = useState('');
    const [documentIdHelpText, setDocumentIdHelpText] = useState('');
    const [isOverride, setIsOverride] = useState(false);

    useEffect(() => {
        props.setPageTitle(PAGE_TITLE);

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
    }, [props, isMasterDataInitialized]);

    const handleTypeChange = (option) => {
        setSelectedType(option);
        showMsg(true, '');
    };

    const handleDocumentIdChange = (event) => {
        let val = event.target.value.replace('e', '');

        if (val.length <= 0) {
            setDocumentIdHelpText('Please provide a Document ID');
        } else if (!Number.isInteger(Number(val))) {
            setDocumentIdHelpText('Document ID should be a number');
        } else {
            setDocumentIdHelpText('');
        }

        setDocumentId(val);
        showMsg(true, '');
    };

    const handleIsOverride = (event) => {
        if (event.target.checked !== isOverride) {
            setIsOverride(event.target.checked);
        }
    };

    const showMsg = (isSuccessful, msg) => {
        if (msg === message.message) {
            return;
        }

        setMessage({ isSuccess: isSuccessful, message: msg });
    };

    const handleReprocess = () => {
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';

            let payload = {
                DocumentId: documentId,
                DocumentType: selectedType.value,
                Override: isOverride,
                TransInfo: {
                    TransactionDateTime: new Date().toISOString(),
                    SourceSystem: 'FACTS',
                    SourceUser: loggedInUser
                }
            };

            Middleware.SendRaw(token, APIEndPoints.DOCUMENT_REPROCESS, 'POST', payload)
                .then((data) => {
                    if (data && data.Message) {
                        showMsg(true, `Document ID ${documentId} Reprocess Requested Successfully.`);

                        setSelectedType({ value: 'PUR', label: 'Application' });
                        setDocumentId('');
                    }
                })
                .catch((error) => {
                    let message = error.data.response.data.Message;
                    showMsg(false, `Failed to Reprocess Document. ${error && message ? 'ERROR: ' + message : ''}`);
                });
        });
    };

    return (
        <div className={`row ${classes.container}`}>
            <div className="col-xs-12 col-sm-4">
                <DrcSelect
                    label="Document Type"
                    value={selectedType}
                    placeholder="Select Document Type"
                    onChange={handleTypeChange}
                    options={[{ value: 'PUR', label: 'Application' }]}
                    isDisabled={true}
                />
            </div>
            <div className="col-xs-12 col-sm-4">
                <DrcInput
                    type="number"
                    label="Document ID"
                    placeholder="Enter a Document ID"
                    value={documentId}
                    onChange={handleDocumentIdChange}
                    InputLabelProps={{ shrink: true }}
                    helperText={documentIdHelpText}
                />
            </div>
            <div className="col-xs-6 col-sm-2">
                <DrcSwitch className={classes.override} checked={isOverride} onChange={handleIsOverride}>
                    Override?
                </DrcSwitch>
            </div>
            <div className="col-xs-6 col-sm-2">
                <DrcButton
                    fullWidth
                    isPrimary
                    onClick={handleReprocess}
                    disabled={
                        documentIdHelpText.length > 0 ||
                        selectedType === null ||
                        documentId.length <= 0 ||
                        !Number.isInteger(Number(documentId)) ||
                        Number(documentId) <= 0
                    }
                >
                    Reprocess
                </DrcButton>
            </div>
            {message.message.length > 0 ? (
                <div className="col-xs-12">
                    <DrcPageWarning anchorVertical="top" message={message.message} isError={!message.isSuccess} />
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isMasterDataInitialized: state.masterReducer.isInitialized,
        isAdmin: state.masterReducer.isAdmin
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (payload) => dispatch(setPageTitleAction(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(DocumentReprocess)));
