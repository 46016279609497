import { Middleware } from 'one-ring';

const CHEMICAL_DETAILS = 'chemicalDetails';

export const setChemicalDetails = (token, endpoint, chemicalDetailsAction) => {
    chemicalDetailsAction(null);

    Middleware.Send(CHEMICAL_DETAILS, token, endpoint, 'GET')
        .then((data) => {
            chemicalDetailsAction(data);
        })
        .catch((error) => {
            console.log(error);
        });
};

const chemicalMaintenanceUtilities = {
    setChemicalDetails: setChemicalDetails
};

export default chemicalMaintenanceUtilities;
