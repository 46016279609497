class APIEndPoints {
    static BASE = window.config.API_BASE_ADDRESS;

    static FOOD_SAFETY_HOLD = this.BASE + '/foodSafetyHold';

    static COMMODITY_TYPES = this.BASE + '/commodityType';
    static COUNTRY_LIST = this.BASE + '/country';

    //Maintenance country
    static GET_ACTIVE_HOLDS_FROM_COUNTRY = (holdareaid) => {
        return this.BASE + `/countryholdevent?holdareaid=${holdareaid}`;
    };

    static GET_ACTIVE_RESTRICTIONS_FROM_COUNTRY = (holdareaid) => {
        return this.BASE + `/holdarea/restrictions?holdareaid=${holdareaid}`;
    };

    static GET_COUNTRY_LIST = this.BASE + `/listcountry`;

    static DELETE_COUNTRY_HOLD = this.BASE + `/holdarea`;

    //Ranch Overview
    static RANCHES = this.BASE + '/listranch';
    static BLOCK_LIST = (ranchNumber) => {
        return this.BASE + `/blocklist/${ranchNumber}`;
    };

    static APPLICATIONS = (ranchNumber) => {
        return this.BASE + `/transactiondetails?ranchnbr=${ranchNumber}`;
    };
    static ADD_APPLICATION = this.BASE + '/purevent';
    static ADD_NO_APPLICATION = this.BASE + '/noappevent';

    static COUNTRY_RESTRICTIONS = (ranchNumber) => {
        return this.BASE + `/countryholdevent?ranchnbr=${ranchNumber}`;
    };
    static COUNTRY_RESTRICTION = this.BASE + '/countryholdevent';
    static PUR_RESTRICTIONS = (ranchNumber) => {
        return this.BASE + `/ranch/${ranchNumber}/restrictions`;
    };
    static DOCUMENT_REPROCESS = this.BASE + '/document';

    //Holds
    static HOLDS = (ranchNumber) => {
        return this.BASE + `/holdevent?ranchnbr=${ranchNumber}`;
    };
    static ADD_HOLD = this.BASE + '/holdevent';
    static END_OF_SEASON_HOLD = this.BASE + '/producingareas';
    static ADD_END_OF_SEASON_HOLD = this.BASE + '/holdevent/endofseason';
    static PRODUCING_AREA_END_OF_SEASON_HOLD = (producingArea, commodityId) =>
        `${this.BASE}/holdevent/endofseason?producingareacode=${producingArea}&commodityid=${commodityId}`;
    static REMOVE_END_OF_SEASON_HOLD = `${this.BASE}/holdevent/endofseason`;

    //ChemicalMaintenance
    //chemical
    static GET_CHEMICAL_DETAILS = (chemicalId) => {
        return this.BASE + `/chemicaldetails?chemicalid=${chemicalId}`;
    };
    static ADD_CHEMICAL = this.BASE + '/chemicallist';
    static EDIT_CHEMICAL = this.BASE + '/chemicallist';
    static INACTIVATE_CHEMICAL = this.BASE + '/chemicallist';
    static CHEMICAL_LIST = this.BASE + '/chemicallist';
    static CHEMICAL_TYPES = this.BASE + '/chemicaltypeslist';
    static EDIT_CHEMICAL_TYPE = this.BASE + '/chemicaltypedetails';
    static EDIT_CHEMICAL_COMMODITY = this.BASE + '/chemicalcmdtydetails';
    static EDIT_APPLICATION = this.BASE + '/editApplication ';
    static GET_INACTIVITY_HISTORY = (ranchNumber) => {
        return this.BASE + `/inactivityHistory?ranchnbr=${ranchNumber}`;
    };
    static CHEMICAL_HOLD = this.BASE + '/chemicalhold';

    //active ingredients
    static ACTIVE_INGREDIENTS = this.BASE + '/activeingredients';

    //error listing
    static ERROR_LIST = this.BASE + '/error/list';
    static ERROR_MODIFICATION = this.BASE + '/error';
}

export default APIEndPoints;
