import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DrcTooltip, DrcSelect, DrcPageWarning, DrcLoading, DrcCheckbox, DrcDialog, DrcButton } from 'driscolls-react-components';
import { withStyles } from '@material-ui/core/styles';

import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import SearchIcon from '@material-ui/icons/Search';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import TimelineIcon from '@material-ui/icons/Timeline';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ExploreOffIcon from '@material-ui/icons/ExploreOff';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { DuDateUtilities, DuAuthenticationUtilities, DuThemeUtilities } from 'driscolls-react-utilities';
import { withAuth } from '@okta/okta-react';
import { setBlockList, setHolds, clearHoldDetails } from '../../actions/ranchOverviewActions';
import RanchUtilities from '../../data/RanchUtilities';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { Link } from 'react-router-dom';

var styles = (theme) => ({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: DuThemeUtilities.DefaultColors.primary.green + ' !important'
    },
    holdButton: {
        width: 37,
        height: 37,
        border: '1px solid ' + DuThemeUtilities.DefaultColors.primary.green,
        backgroundColor: '',
        marginLeft: `1.5rem`,
        boxShadow: `0 2px 4px 0 #C3C3C3`
    },
    noFormFiled: {
        paddingLeft: theme.spacing(0.5),
        paddingTop: theme.spacing(2),
        display: 'inline-block',
        fontSize: theme.spacing(1.5),
        color: DuThemeUtilities.DefaultColors.primary.red,
        margin: 5,
        width: '50%',
        '& svg': {
            marginTop: -5,
            marginRight: 5,
            transform: 'translateY(4px)'
        }
    },
    holdInfo: {
        padding: 5,
        background: DuThemeUtilities.UpdateHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.red), 28),
        color: DuThemeUtilities.DefaultColors.text.black.main,
        paddingLeft: 6,
        width: 'calc(50% - 12px)',
        minWidth: 350,
        margin: 5,
        borderRadius: 10,
        display: 'inline-block',
        '& svg': {
            marginTop: -5,
            marginRight: 5,
            transform: 'translateY(4px)'
        },
        '@media (prefers-color-scheme: dark)': {
            color: 'hsl(0, 0%, 0%)'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minWidth: 'auto'
        }
    },
    input: {
        marginTop: 0
    },
    deleteBtn: {
        float: 'right',
        cursor: 'pointer'
    },
    holdStatusBtn: {
        width: 37,
        height: 37,
        border: '1px solid ' + DuThemeUtilities.DefaultColors.primary.green,
        boxShadow: '0 2px 4px 0 #C3C3C3',
        borderRadius: '50%',
        display: 'inline-block',
        paddingLeft: 8,
        paddingTop: 2,
        [theme.breakpoints.down('xs')]: {
            '&:first-of-type': {
                marginLeft: 0
            }
        }
    },
    statusBtn: {
        width: 37,
        height: 37,
        border: '1px solid ' + DuThemeUtilities.DefaultColors.primary.green,
        boxShadow: '0 2px 4px 0 #C3C3C3',
        borderRadius: '50%',
        display: 'inline-block',
        padding: 9,
        float: 'right'
    },
    tableTitle: {
        fontWeight: 'bold',
        float: 'right',
        marginTop: '1rem'
    },
    tableDetail: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginTop: '1rem',
        marginLeft: '1rem'
    },
    tableTitleContainer: {
        width: 100,
        float: 'left',
        marginRight: 8
    },
    tableTitleContainerSmall: {
        width: 70
    }
});

const holds = [
    {
        id: 'foodSafetyHold',
        type: 'FoodSafetyHold',
        name: 'Food Safety Hold',
        url: 'FoodSafety',
        component: <VerifiedUserIcon />,
        blockLevel: 'foodSafetyHoldBlockDetails'
    },
    {
        id: 'inspectionHold',
        type: 'InspectionHold',
        name: 'Inspection Hold',
        url: 'Inspection',
        component: <SearchIcon />,
        blockLevel: 'inspectionHoldBlockDetails'
    },
    {
        id: 'transferHold',
        type: 'TransferHold',
        name: 'Transfer Hold',
        url: 'Transfer',
        component: <TimelineIcon />,
        blockLevel: 'transferHoldBlockDetails'
    },
    {
        id: 'quarantineHold',
        type: 'QuarantineHold',
        name: 'Quarantine Hold',
        url: 'Quarantine',
        component: <ExploreOffIcon />,
        blockLevel: 'quarantineHoldBlockDetails'
    }
];

const adminGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || []);
const dcUserGroups = window.config.OKTA_DC_USER_GROUPS || [];
const superUserGroups = window.config.OKTA_ADMIN_GROUPS || [];

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            holds: [],
            foodSafetyHold: false,
            inspectionHold: false,
            quarantineHold: false,
            endOfSeasonHold: false,
            transferHold: false,
            errorMessage: null,
            showHeaderFields: false,
            ranchNumber: '',
            onError: false,
            isValid: true,
            isWritable: '',
            isDcUser: '',
            isSuperUser: '',
            foodSafetyHoldAppliedDate: '',
            inspectionHoldAppliedDate: '',
            quarantineHoldAppliedDate: '',
            transferHoldAppliedDate: '',
            endOfSeasonAppliedDate: '',
            confirm: false,
            foodSafetyHoldBlockDetails: {},
            inspectionHoldBlockDetails: {},
            quarantineHoldBlockDetails: {},
            transferHoldBlockDetails: {}
        };
    }

    componentDidMount() {
        this.isUserWritable();
        this.onLoad();
    }

    componentWillReceiveProps() {
        this.onLoad();
    }

    onLoad = () => {
        const {
            foodSafetyHoldRanchDetails,
            inspectionHoldRanchDetails,
            quarantineHoldRanchDetails,
            transferHoldRanchDetails,
            endOfSeasonHoldDetails,
            foodSafetyHoldBlockDetails,
            inspectionHoldBlockDetails,
            quarantineHoldBlockDetails,
            transferHoldBlockDetails
        } = this.props;

        this.setState({
            foodSafetyHold: foodSafetyHoldRanchDetails && foodSafetyHoldRanchDetails.id,
            foodSafetyHoldAppliedDate: foodSafetyHoldRanchDetails && foodSafetyHoldRanchDetails.createdDateTime,
            inspectionHold: inspectionHoldRanchDetails && inspectionHoldRanchDetails.id,
            inspectionHoldAppliedDate: inspectionHoldRanchDetails && inspectionHoldRanchDetails.createdDateTime,
            quarantineHold: quarantineHoldRanchDetails && quarantineHoldRanchDetails.id,
            quarantineHoldAppliedDate: quarantineHoldRanchDetails && quarantineHoldRanchDetails.createdDateTime,
            transferHold: transferHoldRanchDetails && transferHoldRanchDetails.id,
            transferHoldAppliedDate: transferHoldRanchDetails && transferHoldRanchDetails.createdDateTime,
            endOfSeasonHold: endOfSeasonHoldDetails && endOfSeasonHoldDetails.id,
            endOfSeasonAppliedDate: endOfSeasonHoldDetails && endOfSeasonHoldDetails.createdDateTime,
            foodSafetyHoldBlockDetails,
            inspectionHoldBlockDetails,
            quarantineHoldBlockDetails,
            transferHoldBlockDetails
        });
    };

    isUserWritable = async () => {
        let token = await this.props.auth.getAccessToken();
        this.setState({
            isWritable: DuAuthenticationUtilities.IsInGroup(token, adminGroups),
            isDcUser: DuAuthenticationUtilities.IsInGroup(token, dcUserGroups),
            isSuperUser: DuAuthenticationUtilities.IsInGroup(token, superUserGroups)
        });
    };

    showErrorMessage = (message) => {
        this.setState({ errorMessage: message });

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            this.setState({ errorMessage: '' });
        }, 4000);
    };

    setDefaultHolds = () => {
        this.setState({
            foodSafetyHold: false,
            inspectionHold: false,
            endOfSeasonHold: false,
            transferHold: false
        });
    };

    checkHolds = (value) => {
        return this.props[value] || [];
    };

    getHoldPropType = (hold) => {
        switch (hold.id) {
            case 'foodSafetyHold':
                return 'foodSafetyHoldRanchDetails';
            case 'inspectionHold':
                return 'inspectionHoldRanchDetails';
            case 'quarantineHold':
                return 'quarantineHoldRanchDetails';
            case 'transferHold':
                return 'transferHoldRanchDetails';
            case 'endOfSeasonHold':
                return 'endOfSeasonHoldRanchDetails';
            default:
                return '';
        }
    };

    handleKeyPress = (e) => {
        //added numpad keyCodes
        if (!(e.keyCode <= 57 || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 144)) {
            e.preventDefault();
            e.stopPropagation();
            this.showErrorMessage('Invalid character for a ranch number!');
            this.setState({ onError: true });
        }
    };

    handleChange = (option) => {
        this.props.onChange(option);
        this.setDefaultHolds();
    };

    renderSearch = () => {
        const { classes } = this.props;
        return (
            <div>
                <DrcSelect
                    className={classes.input}
                    label={'Ranch Number'}
                    placeholder={'Type Ranch Number'}
                    value={
                        this.props.ranch && {
                            label: this.props.ranch,
                            value: this.props.ranch
                        }
                    }
                    onKeyDown={this.handleKeyPress}
                    onChange={(option) => this.handleChange(option)}
                    options={RanchUtilities.MapRanchesToOptions(this.props.ranches)}
                    noOptionsMessage={() => 'You have entered an invalid ranch number, please fix and try again'}
                    isClearable={true}
                    helperText={this.props.isInvalidRanchNumber ? 'You have entered an invalid ranch number, please fix and try again' : ''}
                    ignoreAccents={true}
                    isWindowedSelect={true}
                ></DrcSelect>
            </div>
        );
    };

    refreshRanch = () => {
        this.props.setCurrentRanch(this.props.ranch);
    };

    confirmEOSHoldToRemove = () => {
        this.setState({ confirm: true });
    };

    onCancel = () => {
        this.setState({ confirm: false });
    };

    removeEosHold = () => {
        this.props.auth.getAccessToken().then((token) => {
            let payload = {};

            payload.sourceDocumentId = (this.props.endOfSeasonHoldDetails || {}).id;
            payload.startDate = (this.props.endOfSeasonHoldDetails || {}).startDate;
            payload.producingArea = (this.props.ranchDetails || {}).producingArea;
            payload.holdFlagType = 'EndOfSeasonHold';

            RanchUtilities.RemoveEndOfSeasonHold(
                token,
                this.props.ranch,
                payload,
                (data) => {
                    this.showErrorMessage(
                        `End of Season successfully removed for '${this.props.ranch}'. Please allow a minute or two for the results to show below!`
                    );
                    this.setState({ onError: false, confirm: false });
                    this.refreshRanch();
                },
                (error) => {
                    this.showErrorMessage('Failed to remove hold for Ranch.');
                    this.setState({ onError: true });
                    console.log('Error', error);
                }
            );
        });
    };

    renderHoldButton = (holds) => {
        const { classes, ranch } = this.props;

        return (
            <div>
                {!this.props.isInvalidRanchNumber
                    ? (holds || []).map((hold) => {
                          return (
                              <Link
                                  to={`/App/RanchOverview/${ranch}/Holds/${hold.url}`}
                                  className={`${classes.holdButton} ${classes.holdStatusBtn}`}
                                  style={{
                                      backgroundColor:
                                          this.state[hold.id] || (this.state[hold.blockLevel] && this.state[hold.blockLevel].blocks)
                                              ? DuThemeUtilities.DefaultColors.primary.green
                                              : '',
                                      color: this.state.isWritable
                                          ? this.state[hold.id] || (this.state[hold.blockLevel] && this.state[hold.blockLevel].blocks)
                                              ? '#FFF'
                                              : DuThemeUtilities.DefaultColors.primary.green
                                          : DuThemeUtilities.DefaultColors.primary.gray,
                                      fontSize: `2rem`
                                  }}
                                  key={hold.id}
                              >
                                  <DrcTooltip
                                      tipText={
                                          this.state[hold.id]
                                              ? `${hold.name} Applied at Ranch on: ${this.state[hold.id + 'AppliedDate']}`
                                              : this.state[hold.blockLevel] && this.state[hold.blockLevel].blocks
                                              ? `${hold.name} Applied at Block(s) [${this.state[hold.blockLevel].blocks.join(', ')}] ${
                                                    this.state[hold.blockLevel].date ? ' on: ' + this.state[hold.blockLevel].date : null
                                                }`
                                              : `${hold.name} Not Applied`
                                      }
                                  >
                                      <span>{hold.component}</span>
                                  </DrcTooltip>
                              </Link>
                          );
                      })
                    : null}
                {!this.props.isInvalidRanchNumber ? (
                    (this.props.ranchDetails || {}).overallStatus === 'Closed' ? (
                        <span
                            className={`${classes.holdButton} ${classes.statusBtn}`}
                            style={{
                                color: DuThemeUtilities.UpdateHslBrightness(
                                    DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.red),
                                    14
                                ),
                                borderColor: DuThemeUtilities.UpdateHslBrightness(
                                    DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.red),
                                    14
                                )
                            }}
                            disabled
                        >
                            <DrcTooltip tipText={'Ranch Closed'}>
                                <NotInterestedIcon />
                            </DrcTooltip>
                        </span>
                    ) : (
                        <span
                            className={`${classes.holdButton} ${classes.statusBtn}`}
                            style={{
                                color: DuThemeUtilities.DefaultColors.primary.green,
                                borderColor: DuThemeUtilities.DefaultColors.primary.green
                            }}
                            disabled
                        >
                            <DrcTooltip tipText={'Ranch Open'}>
                                <DoneIcon />
                            </DrcTooltip>
                        </span>
                    )
                ) : null}
                {!this.props.isInvalidRanchNumber ? (
                    <span>
                        <span
                            className={`${classes.holdButton} ${classes.statusBtn}`}
                            style={{
                                color: this.state['endOfSeasonHold']
                                    ? DuThemeUtilities.UpdateHslBrightness(
                                          DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.red),
                                          14
                                      )
                                    : DuThemeUtilities.DefaultColors.primary.green,
                                borderColor: this.state['endOfSeasonHold']
                                    ? DuThemeUtilities.UpdateHslBrightness(
                                          DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.red),
                                          14
                                      )
                                    : DuThemeUtilities.DefaultColors.primary.green
                            }}
                            key={'endOfSeasonHold'}
                            disabled
                        >
                            <DrcTooltip tipText={`End of Season Hold - ${this.state[`endOfSeasonHold`] ? `On` : `Off`}`}>
                                <WbSunnyIcon />
                            </DrcTooltip>
                        </span>
                    </span>
                ) : null}
            </div>
        );
    };

    getCommodity = (value) => {
        switch (value) {
            case 'BLACK':
                return { label: 'BLACK', value: 1 };
            case 'BLUE':
                return { label: 'BLUE', value: 2 };
            case 'RASP':
                return { label: 'RASP', value: 3 };
            case 'STRAW':
                return { label: 'STRAW', value: 4 };
            default:
                return;
        }
    };

    getProducingAreaInfo = (producingArea, producingAreaName, country) => {
        return producingArea + (producingAreaName ? ' ' + producingAreaName : '') + (country ? ', ' + country : '');
    };

    render() {
        const { classes, showHeaderFields, ranchDetails } = this.props;
        const producingAreaInfo = this.getProducingAreaInfo(
            (ranchDetails || {}).producingArea,
            (ranchDetails || {}).producingAreaName,
            (ranchDetails || {}).country
        );
        return this.state.isWritable === '' ? (
            <DrcLoading></DrcLoading>
        ) : (
            <div className="pt-24">
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">{this.renderSearch()}</div>
                    {this.props.ranch ? <div className="col-xs-12 col-sm-6 col-md-8 col-lg-8">{this.renderHoldButton(holds)}</div> : null}
                </div>
                {showHeaderFields && (
                    <div className="row" style={{ marginBottom: '12px' }}>
                        <div className="col-xs-12 col-sm-4">
                            <div className={classes.tableTitleContainer}>
                                <div className={classes.tableTitle}>Ranch Name:</div>
                                <div className={classes.tableTitle}>Producing Area:</div>
                            </div>
                            <div>
                                <DrcTooltip tipText={(ranchDetails || {}).ranchName}>
                                    <div className={classes.tableDetail}>{(ranchDetails || {}).ranchName}</div>
                                </DrcTooltip>
                                <DrcTooltip tipText={producingAreaInfo}>
                                    <div className={classes.tableDetail}>{producingAreaInfo}</div>
                                </DrcTooltip>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-5">
                            <div className={classes.tableTitleContainer}>
                                <div className={classes.tableTitle}>Grower Name:</div>
                                <div className={classes.tableTitle}>Grower Number:</div>
                            </div>
                            <div>
                                <DrcTooltip tipText={(ranchDetails || {}).growerName}>
                                    <div className={classes.tableDetail}>{(ranchDetails || {}).growerName}</div>
                                </DrcTooltip>
                                <DrcTooltip tipText={(ranchDetails || {}).growerNbr}>
                                    <div className={classes.tableDetail}>{(ranchDetails || {}).growerNbr}</div>
                                </DrcTooltip>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-3">
                            <div className={`${classes.tableTitleContainer} ${classes.tableTitleContainerSmall}`}>
                                <div className={classes.tableTitle}>Commodity:</div>
                                <div className={classes.tableTitle}>Organic:</div>
                            </div>
                            <div>
                                <DrcTooltip tipText={MasterDataUtilities.MapCommodity((ranchDetails || {}).berryType)}>
                                    <div className={classes.tableDetail}>
                                        {MasterDataUtilities.RenderCommodityOptions(this.getCommodity((ranchDetails || {}).berryType.toUpperCase()))}
                                    </div>
                                </DrcTooltip>
                                <DrcCheckbox
                                    style={{ color: DuThemeUtilities.DefaultColors.primary.green, padding: '6px 0' }}
                                    checked={((ranchDetails || {}).fieldType || '').toLowerCase() === 'organic' ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {this.props.ranch && !this.props.isInvalidRanchNumber ? (
                    <React.Fragment>
                        {this.state.foodSafetyHold && (
                            <div className={classes.holdInfo}>
                                <VerifiedUserIcon />
                                Current Food Safety Hold applied:{' '}
                                {this.state.foodSafetyHoldAppliedDate
                                    ? this.state.foodSafetyHoldAppliedDate
                                    : DuDateUtilities.FormatDateTimeFromIso(new Date().toISOString())}
                            </div>
                        )}
                        {!this.state.foodSafetyHold && this.state.foodSafetyHoldBlockDetails && this.state.foodSafetyHoldBlockDetails.blocks && (
                            <div className={classes.holdInfo}>
                                <VerifiedUserIcon />
                                Current Food Safety Hold applied to blocks:{' ' + this.state.foodSafetyHoldBlockDetails.blocks.join(', ')}
                                {this.state.foodSafetyHoldBlockDetails.date ? ' on ' + this.state.foodSafetyHoldBlockDetails.date : null}
                            </div>
                        )}
                        {this.state.inspectionHold && (
                            <div className={classes.holdInfo}>
                                <SearchIcon />
                                Current Inspection Hold applied:{' '}
                                {this.state.inspectionHoldAppliedDate
                                    ? this.state.inspectionHoldAppliedDate
                                    : DuDateUtilities.FormatDateTimeFromIso(new Date().toISOString())}
                            </div>
                        )}
                        {!this.state.inspectionHold && this.state.inspectionHoldBlockDetails && this.state.inspectionHoldBlockDetails.blocks && (
                            <div className={classes.holdInfo}>
                                <SearchIcon />
                                Current Inspection Hold applied to blocks:{' ' + this.state.inspectionHoldBlockDetails.blocks.join(', ')}
                                {this.state.inspectionHoldBlockDetails.date ? ' on ' + this.state.inspectionHoldBlockDetails.date : null}
                            </div>
                        )}
                        {this.state.transferHold && (
                            <div className={classes.holdInfo}>
                                <TimelineIcon />
                                Current Transfer Hold applied:{' '}
                                {this.state.transferHoldAppliedDate
                                    ? this.state.transferHoldAppliedDate
                                    : DuDateUtilities.FormatDateTimeFromIso(new Date().toISOString())}
                            </div>
                        )}
                        {!this.state.transferHold && this.state.transferHoldBlockDetails && this.state.transferHoldBlockDetails.blocks && (
                            <div className={classes.holdInfo}>
                                <TimelineIcon />
                                Current Transfer Hold applied to blocks:{' ' + this.state.transferHoldBlockDetails.blocks.join(', ')}
                                {this.state.transferHoldBlockDetails.date ? ' on ' + this.state.transferHoldBlockDetails.date : null}
                            </div>
                        )}
                        {this.state.quarantineHold && (
                            <div className={classes.holdInfo}>
                                <ExploreOffIcon />
                                Current Quarantine Hold applied:{' '}
                                {this.state.quarantineHoldAppliedDate
                                    ? this.state.quarantineHoldAppliedDate
                                    : DuDateUtilities.FormatDateTimeFromIso(new Date().toISOString())}
                            </div>
                        )}
                        {!this.state.quarantineHold && this.state.quarantineHoldBlockDetails && this.state.quarantineHoldBlockDetails.blocks && (
                            <div className={classes.holdInfo}>
                                <ExploreOffIcon />
                                Current Quarantine Hold applied to blocks:{' ' + this.state.quarantineHoldBlockDetails.blocks.join(', ')}
                                {this.state.quarantineHoldBlockDetails.date ? ' on ' + this.state.quarantineHoldBlockDetails.date : null}
                            </div>
                        )}
                        {this.state.endOfSeasonHold && (
                            <div className={classes.holdInfo}>
                                <WbSunnyIcon />
                                Current End of Season Hold applied:{' '}
                                {this.state.endOfSeasonAppliedDate
                                    ? this.state.endOfSeasonAppliedDate
                                    : DuDateUtilities.FormatDateTimeFromIso(new Date().toISOString())}
                                {this.state['endOfSeasonHold'] && this.state.isWritable ? (
                                    <span className={classes.deleteBtn} onClick={this.confirmEOSHoldToRemove}>
                                        <DrcTooltip tipText={`Remove End of Season Hold`}>
                                            <DeleteOutlineIcon />
                                        </DrcTooltip>
                                    </span>
                                ) : null}
                            </div>
                        )}
                    </React.Fragment>
                ) : null}
                {this.props.ranch && !this.props.isInvalidRanchNumber && this.props.noFormFiled && (
                    <div className={classes.noFormFiled}>
                        <ErrorOutlineIcon />
                        No Form Filed
                    </div>
                )}
                {this.state.errorMessage ? (
                    <DrcPageWarning anchorVertical="top" message={this.state.errorMessage} isError={this.state.onError} />
                ) : null}
                {this.state.confirm ? (
                    <DrcDialog
                        open={this.state.confirm}
                        title={`Are you sure you want to remove End of Season hold?`}
                        buttons={
                            <div>
                                <DrcButton isSecondary onClick={this.onCancel} floatRight>
                                    No
                                </DrcButton>
                                <DrcButton isPrimary onClick={this.removeEosHold} floatRight>
                                    Yes
                                </DrcButton>
                            </div>
                        }
                    ></DrcDialog>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isMasterDataInitialized: state.masterReducer.isInitialized,
        ranch: state.ranchOverview.ranch,
        ranchDetails: state.ranchOverview.ranchDetails,
        ranches: state.masterReducer.ranches,
        blocks: state.ranchOverview.blocks,
        foodSafetyHold: state.ranchOverview.foodSafetyHold,
        inspectionHold: state.ranchOverview.inspectionHold,
        quarantineHold: state.ranchOverview.quarantineHold,
        transferHold: state.ranchOverview.transferHold,
        foodSafetyHoldRanchDetails: state.ranchOverview.foodSafetyHoldRanchDetails,
        inspectionHoldRanchDetails: state.ranchOverview.inspectionHoldRanchDetails,
        quarantineHoldRanchDetails: state.ranchOverview.quarantineHoldRanchDetails,
        transferHoldRanchDetails: state.ranchOverview.transferHoldRanchDetails,
        endOfSeasonHoldDetails: state.ranchOverview.endOfSeasonHoldDetails,
        noFormFiled: state.ranchOverview.noFormFiled,
        foodSafetyHoldBlockDetails: state.ranchOverview.foodSafetyHoldBlockDetails,
        inspectionHoldBlockDetails: state.ranchOverview.inspectionHoldBlockDetails,
        quarantineHoldBlockDetails: state.ranchOverview.quarantineHoldBlockDetails,
        transferHoldBlockDetails: state.ranchOverview.transferHoldBlockDetails
    };
};

const mapDispatchToProps = (dispatch) => ({
    setHolds: (data) => dispatch(setHolds(data)),
    setBlockList: (data) => dispatch(setBlockList(data)),
    clearHoldDetails: () => dispatch(clearHoldDetails())
});
export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(Header)));
