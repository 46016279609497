import React, { useState } from 'react';
import { DrcDialog, DrcButton, DrcDateTimePicker, DrcInput } from 'driscolls-react-components';
import { withStyles } from '@material-ui/core/styles';
import { DuDateUtilities } from 'driscolls-react-utilities';

let styles = (theme) => ({});

const RanchHoldDialog = (props) => {
    const { onClose, onSubmit, holdName, isOpen } = props;

    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))); // set to start date of the week by default
    const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 6 - new Date().getDay()))); //set to end date of the week by default
    const [comment, setComment] = useState('');
    const [valid, setValid] = useState(true);

    let minDate = new Date(new Date().setDate(new Date().getDate() - 89));
    let maxDate = new Date(new Date().setDate(new Date().getDate() + 89));

    const handleFieldChange = (event, field) => {
        if (field === 'comment') setComment(event.target.value);
        if (field === 'startDate') {
            if (
                event &&
                new Date(DuDateUtilities.FormatDateTimeFromIso(new Date(event).toISOString())).getTime() >=
                    new Date(DuDateUtilities.FormatDateTimeFromIso(new Date(endDate).toISOString())).getTime()
            ) {
                //add extra 1 min to end date
                setEndDate(new Date(event.getTime() + 60000));
            }
            setStartDate(event);
            setValid(event && endDate);
        }
        if (field === 'endDate') {
            setEndDate(event);
            setValid(event && startDate);
        }
    };

    return (
        <DrcDialog
            open={isOpen}
            title={`Are you sure you want to apply '${holdName}'?`}
            buttons={
                <div>
                    <DrcButton
                        isPrimary
                        onClick={() =>
                            onSubmit({
                                startDate,
                                endDate,
                                comment
                            })
                        }
                        disabled={!valid}
                    >
                        Yes, we're good
                    </DrcButton>
                    <DrcButton isSecondary onClick={() => onClose()}>
                        Cancel
                    </DrcButton>
                </div>
            }
        >
            <div>
                <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                    <DrcDateTimePicker
                        label={'Start Date'}
                        required
                        value={startDate}
                        onChange={(e) => handleFieldChange(e, 'startDate')}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        maxDate={maxDate}
                        minDate={minDate}
                    />
                </div>
                <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                    <DrcDateTimePicker
                        label={'End Date'}
                        required
                        value={endDate}
                        onChange={(e) => handleFieldChange(e, 'endDate')}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        maxDate={maxDate}
                        minDate={startDate}
                    />
                </div>
                <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                    <DrcInput
                        label={'Comment'}
                        size="Small"
                        value={comment}
                        placeholder={'Please enter a comment'}
                        onChange={(e) => handleFieldChange(e, 'comment')}
                        inputProps={{ maxLength: 200 }}
                    ></DrcInput>
                </div>
            </div>
        </DrcDialog>
    );
};

export default withStyles(styles)(RanchHoldDialog);
