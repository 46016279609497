import { Middleware } from 'one-ring';

const getRanchMapping = [
    Middleware.CreateMapping('ranchNumber', 'RanchNumber'),
    Middleware.CreateMapping('ranchName', 'RanchName'),
    Middleware.CreateMapping('growerName', 'GrowerName'),
    Middleware.CreateMapping('growerNbr', 'GrowerNbr'),
    Middleware.CreateMapping('fieldType', 'FieldType'),
    Middleware.CreateMapping('producingArea', 'ProducingArea'),
    Middleware.CreateMapping('commodityType', 'CommodityType'),
    Middleware.CreateMapping('overallStatus', 'OverallStatus'),
    Middleware.CreateMapping('blocks', 'Blocks'),
    Middleware.CreateMapping('blockId', 'BlockId'),
    Middleware.CreateMapping('blockName', 'BlockName'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('holds', 'Holds'),
    Middleware.CreateMapping('holdTypeId', 'HoldTypeId'),
    Middleware.CreateMapping('holdType', 'HoldType'),
    Middleware.CreateMapping('isApplied', 'IsApplied'),
    Middleware.CreateMapping('appliedDate', 'AppliedDate'),
    Middleware.CreateArrayMapping('Results')
];

const getBlockListMapping = [
    Middleware.CreateMapping('id', 'Id'),
    Middleware.CreateMapping('ranchNumber', 'RanchNumber'),
    Middleware.CreateMapping('blockDesignator', 'BlockDesignator'),
    Middleware.CreateMapping('mrlBlocks', 'MrlBlocks'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('createdBy', 'CreatedBy'),
    Middleware.CreateMapping('createdDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('modifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('modifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('getRanch', 'GET')) {
        Middleware.RegisterMapping('getRanch', 'GET', getRanchMapping);
    }

    if (!Middleware.CheckMappingExists('getBlockList', 'GET')) {
        Middleware.RegisterMapping('getBlockList', 'GET', getBlockListMapping);
        Middleware.RegisterMapping('getBlockList', 'POST', getBlockListMapping);
    }
}

const ranchDetailsTypesGroup = {
    ConfigureMiddleWare
};

export default ranchDetailsTypesGroup;
